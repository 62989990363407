import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function fetchMarketingCopies(itemId: number) {
  return {
    type: 'FETCH_MARKETING_COPIES',
    payload: axios.get(`${getApiRoot()}/items/${itemId}/marketing_copies`),
  };
}

export function fetchDescriptions(itemId: number) {
  return {
    type: 'FETCH_DESCRIPTIONS',
    payload: axios.get(`${getApiRoot()}/items/${itemId}/descriptions`),
  };
}

export function fetchUsedDescriptions(brandId: number) {
  return {
    type: 'FETCH_USED_DESCRIPTIONS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/description_types`),
    meta: { brandId },
  };
}
