import { createSelector } from 'reselect';

const getCustomFields = state => state.brand.customFields.customFields;

export const customFieldsSortRecordNumber = createSelector([getCustomFields], brandCustomFields =>
  brandCustomFields.map(customField => ({
    ...customField,
    options: customField.options
      ? customField.options.sort((a, b) => a.record_number - b.record_number)
      : [],
  }))
);

const getAnalysisResources = state => state.resources.data.analysis;
const getBrandAnalysisResources = state => state.brand.resources.analysis;
const getAnalysesResourcesFilter = state => state.settings.scorecards.filterAnalysesResources;

export const filterAnalysesResources = createSelector(
  [getAnalysisResources, getBrandAnalysisResources, getAnalysesResourcesFilter],
  (analysisResources, brandAnalysisResources, analysesResourcesFilter) => {
    const { selectedSegmentId, selectedAlertTypeId, selectedScorecardId } = analysesResourcesFilter;
    let analysisTypes = brandAnalysisResources.types;
    if (selectedSegmentId) {
      analysisTypes = analysisTypes.filter(analysis => analysis.segment_id === selectedSegmentId);
    }
    if (selectedAlertTypeId) {
      analysisTypes = analysisTypes.filter(
        analysis => analysis.alert_type_id === selectedAlertTypeId
      );
    }
    if (selectedScorecardId) {
      const selectedScoreCardTypeIds = brandAnalysisResources.scorecards.find(
        scorecard => scorecard.id === selectedScorecardId
      ).type_ids;
      analysisTypes = analysisTypes.filter(analysis =>
        selectedScoreCardTypeIds.includes(analysis.id)
      );
    }
    return {
      ...analysisResources,
      types: analysisTypes,
    };
  }
);

const getValidatedAnalysisTypeIds = state => state.settings.scorecards.validatedAnalysisTypeIds;

export const filterResourcesAndAddValidatingInfo = createSelector(
  [filterAnalysesResources, getBrandAnalysisResources, getValidatedAnalysisTypeIds],
  (analysesResources, brandAnalysisResources, validatedAnalysisTypeIds) => ({
    ...analysesResources,
    types: analysesResources.types.map(type => {
      const typeIsValidating = validatedAnalysisTypeIds.includes(type.id);
      const newType = typeIsValidating
        ? { ...type, isValidating: true }
        : { ...type, isValidating: false };
      return newType;
    }),
    scorecards: brandAnalysisResources.scorecards.map(scorecard => ({
      ...scorecard,
      validating_against_scorecard: scorecard.type_ids.every(typeId =>
        validatedAnalysisTypeIds.includes(typeId)
      ),
    })),
  })
);

const accountLanguages = state => state.parent.languageSettings.accountLanguages;
const segmentTranslationValues = state => state.parent.languageTranslation.translations;

export const translationsInitValues = (languages, translations) => {
  const transInitValues = {};
  languages.forEach(accLanguage => {
    translations.forEach(segTranslation => {
      if (segTranslation.id !== -2) {
        const translationValue = segTranslation.translations?.find(
          trans => trans.language_id === accLanguage.language_id
        );
        if (translationValue) {
          transInitValues[`${segTranslation.id}_${accLanguage.language_id}`] = {
            parentId: segTranslation.id,
            value: translationValue.value,
            count: segTranslation.count,
            languageId: translationValue.language_id,
          };
        } else {
          transInitValues[`${segTranslation.id}_${accLanguage.language_id}`] = {
            parentId: segTranslation.id,
            value: '',
            count: null,
            languageId: accLanguage.language_id,
          };
        }
      }
    });
  });
  return transInitValues;
};

export const mapTranslationsToLanguages = createSelector(
  [accountLanguages, segmentTranslationValues],
  (languages, translations) => {
    const transInitValues = translationsInitValues(languages, translations);
    return transInitValues;
  }
);
