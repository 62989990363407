import React, { useCallback } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Input, Button, Select, Checkbox } from 'antd';
import {
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
  CellMeasurerCache,
  CellMeasurer,
  TableRowProps,
} from 'react-virtualized';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useSelector, useDispatch } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import SettingsCustomFieldEditDrawer from '../../components/body/settings/SettingsCustomFieldEditDrawer';
import { CustomFieldType, BrandCustomField, CustomFieldOption } from '../../../types/custom_fields';
import {
  fetchSettingsCustomFields,
  updateCustomFieldOrder,
} from '../../actions/brand/custom_fields';
import JoditHtmlEditor from './JoditHtmlEditor';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { showUpgradePlanNotification } from '../../actions/app/modal';
import { fetchScripts } from '../../actions/distribution_apisettings';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';

const { Option } = Select;

const SortableTable = SortableContainer(Table as any);
const SortableTableRowRenderer = SortableElement(
  (props: TableRowProps) => defaultTableRowRenderer(props) as any
);
const DragHandle = SortableHandle(() => <span className="cfs-table-drag">::</span>);

function rowRenderer(props: any) {
  return <SortableTableRowRenderer {...props} />;
}

const DRAG_COLUMN_WIDTH = 50;
const EDIT_COLUMN_WIDTH = 120;

const cache: CellMeasurerCache = new CellMeasurerCache({ minHeight: 48, fixedWidth: true });

const SettingsCustomFieldsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { customFields, user, brandCode, scripts } = useSelector((state: ApplicationState) => {
    return {
      customFields: state.brand.customFields.customFields,
      user: state.user.user,
      brandCode: getSelectedBrandCode(state),
      scripts: state.distribution.apiSettings.scripts,
    };
  });

  const [customFieldsData, setCustomFieldsData] = React.useState(customFields || []);
  const [keyword, setKeyword] = React.useState('');
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [selectedCustomFieldId, setSelectedCustomFieldId] = React.useState<number | null>(null);

  const handleResize = useCallback(() => {
    cache.clearAll();
  }, []);

  React.useEffect(() => {
    intercomEvent('viewed-brand-settings', {
      location: 'settings-custom-fields',
      brand_code: brandCode!,
    });
  }, [brandCode]);

  React.useEffect(() => {
    if (user?.receiver_id && hasPermission(user, 'can_manage_custom_fields'))
      dispatch(fetchSettingsCustomFields());
  }, [dispatch, user]);

  React.useEffect(() => {
    dispatch(fetchScripts());
  }, [dispatch, user]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  React.useEffect(() => {
    setCustomFieldsData(customFields);
    handleResize();
  }, [customFields, handleResize]);

  const handleKeywordSearch = (filterKeywords: string) => {
    setKeyword(filterKeywords);
    setCustomFieldsData(
      customFields.filter(({ name }) => name.toLowerCase().includes(filterKeywords.toLowerCase()))
    );
    handleResize();
  };

  const handleShowDrawer = (customFieldId?: number) => {
    if (!canEditSettingsCustomFields) dispatch(showUpgradePlanNotification());
    setShowDrawer(true);
    if (customFieldId) setSelectedCustomFieldId(customFieldId);

    intercomEvent('viewed-brand-settings', {
      location: 'settings-custom-fields',
      action: customFieldId ? 'edit' : 'add_new',
      brand_code: brandCode!,
    });
  };

  const canEditSettingsCustomFields = hasPermission(user, 'can_manage_custom_fields');

  const columnCellRenderer = ({ dataKey, parent, rowIndex }: any) => {
    const rowData = customFieldsData[rowIndex];
    const cutomFieldTypeId = rowData.custom_field_type_id;

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        {cutomFieldTypeId === CustomFieldType.Input && (
          <Input placeholder="Text" className="cfs-preview-type" />
        )}
        {cutomFieldTypeId === CustomFieldType.Number && (
          <Input placeholder="Number" className="cfs-preview-type" />
        )}
        {cutomFieldTypeId === CustomFieldType.Dropdown && (
          <Select placeholder="Dropdown" className="cfs-preview-type">
            {rowData.options &&
              rowData.options.map((option: CustomFieldOption, index: number) => (
                <Option key={`dropdown-prev${index}`} value={option.name} disabled>
                  {option.name}
                </Option>
              ))}
          </Select>
        )}
        {cutomFieldTypeId === CustomFieldType.Checkbox && <Checkbox disabled />}
        {cutomFieldTypeId === CustomFieldType.Text_Editor && <JoditHtmlEditor readonly />}
        {cutomFieldTypeId === CustomFieldType.Link && (
          <Input className="cfs-preview-type" placeholder="https://" />
        )}
        {cutomFieldTypeId === CustomFieldType.Button && (
          <Button disabled>{`${t('common:button')}: ${
            scripts.find(s => s.id === rowData.script_id)?.name
          }`}</Button>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className="page-layout">
      <div className="page-layout__top-bar">
        <div className="page-layout__top-bar__container">
          <Input.Search
            className="page-layout__top-bar__search"
            value={keyword}
            onChange={e => handleKeywordSearch(e.target.value)}
            placeholder={t('settings:customFields.searchCustomFields')}
            allowClear
          />
          <div className="page-layout__top-bar__actions">
            <Button
              type="primary"
              onClick={() => {
                handleShowDrawer();
              }}
            >
              {t('settings:customFields:addNewCustomField')}
            </Button>
          </div>
        </div>
      </div>
      <div className="page-layout__content">
        <AutoSizer>
          {({ width, height }) => (
            <SortableTable
              lockAxis="y"
              helperClass="stylizedHelper"
              useWindowAsScrollContainer
              useDragHandle
              // @ts-ignore
              width={width}
              height={height}
              headerHeight={48}
              rowHeight={cache.rowHeight}
              rowCount={customFieldsData.length}
              rowRenderer={rowRenderer}
              rowGetter={({ index }: { index: any }) => {
                return customFieldsData[index];
              }}
              onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
                // update record_numbers
                const sortedCustomFields = arrayMoveImmutable(
                  customFieldsData,
                  oldIndex,
                  newIndex
                ).map((c, i) => ({
                  ...c,
                  record_number: i + 1,
                }));
                const updateObj = sortedCustomFields.map((c: BrandCustomField, index: number) => ({
                  brand_custom_field_id: c.id,
                  record_number: index + 1,
                }));

                dispatch(updateCustomFieldOrder(updateObj, sortedCustomFields));
                setCustomFieldsData(sortedCustomFields);
                handleResize();
              }}
            >
              <Column
                width={DRAG_COLUMN_WIDTH}
                dataKey="index"
                cellRenderer={() => {
                  return <DragHandle />;
                }}
              />
              <Column label={t('common:name')} dataKey="name" width={width / 4} />
              <Column
                width={width - DRAG_COLUMN_WIDTH - EDIT_COLUMN_WIDTH - width / 4}
                style={{ overflow: 'visible' }}
                label={t('common:type')}
                dataKey="custom_field_type_id"
                cellRenderer={columnCellRenderer}
              />
              <Column
                width={EDIT_COLUMN_WIDTH}
                label={t('common:edit')}
                dataKey="index"
                cellRenderer={({ rowData }) => {
                  return (
                    <Button
                      onClick={() => {
                        handleShowDrawer(rowData.id);
                      }}
                      icon={<EditOutlined />}
                      data-testid="customField-edit"
                    >
                      {t('common:edit')}
                    </Button>
                  );
                }}
              />
            </SortableTable>
          )}
        </AutoSizer>

        <SettingsCustomFieldEditDrawer
          visible={showDrawer}
          canEditSettingsCustomFields={canEditSettingsCustomFields}
          closeDrawer={() => {
            setSelectedCustomFieldId(null);
            setShowDrawer(false);
          }}
          customField={customFieldsData.find(
            (customField: BrandCustomField) => customField.id === selectedCustomFieldId
          )}
          customFields={customFieldsData}
          customFieldsLength={customFields.length}
        />
      </div>
    </div>
  );
};

export default SettingsCustomFieldsContainer;
