import axios from 'axios';
import { Dispatch } from 'redux';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export const deleteEbayOffer = (itemId: number) => {
  return {
    type: 'DELETE_EBAY_OFFER',
    payload: axios.delete(`${getApiRoot()}/ebay_native/offer`, {
      data: { item_ids: [itemId] },
    }),
  };
};

export const deleteEbayOffers = ({
  itemIds,
  filterId,
  withoutItemdIds,
}: {
  itemIds?: number[];
  filterId?: number;
  withoutItemdIds?: number[];
}) => {
  return async (dispatch: Dispatch) => {
    const params = utils.createURL([{ name: 'filter_id', values: filterId }]);

    const result = await axios.delete(`${getApiRoot()}/ebay_native/offer/bulk${params}`, {
      data: {
        item_ids: itemIds,
        without_item_ids: withoutItemdIds,
      },
    });

    const logParams = utils.createURL([
      { name: 'log_ids', values: result.data.channel_log_id },
      { name: 'page', values: 1 },
      { name: 'limit', values: 1 },
    ]);

    const newLogs = await axios.get(`${getApiRoot()}/channels/delivery_logs${logParams}`);

    return dispatch({
      type: '@CHANNEL_PUSHER/PUSH_UPDATE',
      payload: newLogs,
    });
  };
};

export const disconnectEbayAccount = () => {
  return {
    type: 'DISCONNECT_EBAY_ACCOUNT',
    payload: axios.delete(`${getApiRoot()}/ebay_native/auth/unregister`),
  };
};
