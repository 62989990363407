import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

export const fetchExportBuilderAdvancedTemplates = (brandId?: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_EXPORT_BUILDER_ADVANCED_TEMPLATES',
    payload: axios.get(`${getApiRoot()}/full_export_templates${params}`),
  };
};

let columnCancelToken: any;

export const fetchExportBuilderAdvancedColumns = (templateId: number, brandId?: number) => {
  if (columnCancelToken) columnCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_EXPORT_BUILDER_ADVANCED_COLUMS',
    payload: axios.get(`${getApiRoot()}/full_export_templates/${templateId}/columns${params}`, {
      cancelToken: new CancelToken(c => (columnCancelToken = c)),
    }),
    meta: { templateId },
  };
};

export const fetchExportBuilderAdvancedSegments = (brandId?: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENTS',
    payload: axios.get(`${getApiRoot()}/full_export_templates/segments${params}`),
  };
};

const segmentCancelTokens: any[] = [];

export const fetchExportBuilderAdvancedSegment = (brandId: number, segmentId: number) => {
  if (segmentCancelTokens[segmentId]) segmentCancelTokens[segmentId]();

  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'segment_ids', values: segmentId },
  ]);

  return {
    type: 'FETCH_EXPORT_BUILDER_ADVANCED_SEGMENT',
    payload: axios.get(`${getApiRoot()}/full_export_templates/segments${params}`, {
      cancelToken: new CancelToken(c => {
        segmentCancelTokens[segmentId] = c;
      }),
    }),
    meta: { segmentId },
  };
};

let previewCancelToken: any;

export const fetchExportBuilderAdvancedPreview = (templateId: number, brandId?: number) => {
  if (previewCancelToken) previewCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_EXPORT_BUILDER_ADVANCED_PREVIEW',
    payload: axios.get(`${getApiRoot()}/full_export_templates/${templateId}/preview${params}`, {
      cancelToken: new CancelToken(c => (previewCancelToken = c)),
    }),
  };
};
