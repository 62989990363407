import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

export function fetchExtendedItem(itemId: number) {
  const urlParams = [{ name: 'item_id', values: itemId }];

  return {
    type: 'FETCH_EXTENDED_ITEM',
    payload: axios.get(`${getApiRoot()}/items${utils.createURL(urlParams)}`),
  };
}

export function fetchExtendedItemByPartNr(partNr: string, brandId: number) {
  const urlParams = [
    { name: 'part_number', values: partNr },
    { name: 'brand_id', values: brandId },
  ];

  return {
    type: 'FETCH_EXTENDED_ITEM',
    payload: axios.get(`${getApiRoot()}/items${utils.createURL(urlParams)}`),
  };
}

let fetchItemCategoriesCanceltoken: any;
export function fetchItemCategories({
  brandId,
  itemId,
  categoryId,
  subCategoryId,
  keywordsSmartMapping,
  keywordCategory,
  keywordSubCategory,
  keywordPartType,
  page = 1,
  activeLevel,
}: {
  brandId: number;
  itemId?: number;
  categoryId?: number;
  subCategoryId?: number;
  partTypeId?: number;
  keywordsSmartMapping?: string;
  keywordCategory?: string;
  keywordSubCategory?: string;
  keywordPartType?: string;
  page?: number;
  activeLevel?: 'category' | 'subCategory' | 'partType';
}) {
  if (fetchItemCategoriesCanceltoken) fetchItemCategoriesCanceltoken();

  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'item_id', values: itemId },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
    { name: 'category_id', level: '1', values: categoryId },
    { name: 'category_id', level: '2', values: subCategoryId },
    { name: 'keywords', level: '0', values: utils.stringToArray(keywordsSmartMapping || '') },
    { name: 'keywords', level: '1', values: utils.stringToArray(keywordCategory || '') },
    { name: 'keywords', level: '2', values: utils.stringToArray(keywordSubCategory || '') },
    { name: 'keywords', level: '3', values: utils.stringToArray(keywordPartType || '') },
  ]);

  return {
    meta: { page, activeLevel },
    type: 'FETCH_ITEM_CATEGORIES',
    payload: axios.get(`${getApiRoot()}/categories/v2${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchItemCategoriesCanceltoken = c;
      }),
    }),
  };
}

let fetchItemSubBrandsCancelToken: any;
export function fetchSubBrands(brandId: number) {
  if (fetchItemSubBrandsCancelToken) fetchItemSubBrandsCancelToken();

  const params = utils.createURL([{ name: 'external_brand_ids', values: brandId }]);

  return {
    type: 'FETCH_ITEM_SUB_BRANDS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/sub_brands${params}`, {
      cancelToken: new CancelToken(c => {
        fetchItemSubBrandsCancelToken = c;
      }),
    }),
  };
}
