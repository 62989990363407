import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchPriceSheets(brandId?: number) {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_PRICE_SHEETS',
    payload: axios.get(`${getApiRoot()}/parents/price_sheets${params}`),
  };
}

export function fetchBrandPriceSheets(brandId: number) {
  return {
    type: 'FETCH_BRAND_PRICE_SHEETS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/price_sheets`),
  };
}

export function fetchPriceSheetUses(pricSheetId: number) {
  return {
    type: 'FETCH_PRICE_SHEET_USES',
    payload: axios.get(`${getApiRoot()}/parents/price_sheets/${pricSheetId}/uses`),
  };
}
