import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import {
  Receiver,
  ReceiverDetails,
  ReceiverRequest,
  ReceiverWarehouse,
} from '../../../types/receiver';
import { APPROVED, CANCELED, REJECTED } from '../../constants/ReceiverConstants';

export type ReceiverListState = {
  readonly isLoading: boolean;
  readonly isLoadingFullList: boolean;
  readonly fetchingReceiverDetails: boolean;
  readonly receiverList: Receiver[];
  readonly receiverRequestCount: number;
  readonly fullReceiverList: Receiver[];
  readonly receiverDetails?: ReceiverDetails;
  readonly receiverWarehouseList: ReceiverWarehouse[];
  readonly fetchingReceiverWarehouseList: boolean;
};

const initialState = {
  isLoadingFullList: true,
  isLoading: true,
  fetchingReceiverDetails: false,
  receiverList: [],
  receiverRequestCount: 0,
  fullReceiverList: [],
  receiverDetails: undefined,
  receiverWarehouseList: [],
  fetchingReceiverWarehouseList: false,
};

const reducer: Reducer<ReceiverListState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_RECEIVER_PENDING': {
      return { ...state, isLoading: true, isLoadingFullList: false, receiverList: [] };
    }
    case 'FETCH_FULL_RECEIVER_LIST_PENDING': {
      return {
        ...state,
        isLoadingFullList: true,
        isLoading: false,
        receiverList: [],
        fullReceiverList: [],
      };
    }
    case 'FETCH_FULL_RECEIVER_LIST_FULFILLED': {
      const fullReceiverList = action.payload.data;
      return {
        ...state,
        isLoadingFullList: false,
        isLoading: false,
        receiverList: fullReceiverList,
        fullReceiverList,
      };
    }
    case 'FETCH_RECEIVER_FULFILLED': {
      const receivers = action.payload.data;
      return {
        ...state,
        isLoading: false,
        isLoadingFullList: false,
        receiverList: receivers,
      };
    }
    case 'FETCH_RECEIVER_REQUEST_COUNT_FULFILLED': {
      return { ...state, receiverRequestCount: action.payload.data.pending_request_count };
    }
    case 'FETCH_RECEIVER_DETAILS_PENDING': {
      return { ...state, fetchingReceiverDetails: true, receiverDetails: undefined };
    }
    case 'FETCH_RECEIVER_DETAILS_REJECTED': {
      return { ...state, fetchingReceiverDetails: false };
    }
    case 'FETCH_RECEIVER_DETAILS_FULFILLED': {
      return { ...state, fetchingReceiverDetails: false, receiverDetails: action.payload.data };
    }
    case 'FETCH_RECEIVER_WAREHOUSE_LIST_PENDING': {
      return { ...state, fetchingReceiverWarehouseList: true };
    }
    case 'FETCH_RECEIVER_WAREHOUSE_LIST_FULFILLED': {
      return {
        ...state,
        receiverWarehouseList: action.payload.data,
        fetchingReceiverWarehouseList: false,
      };
    }
    case 'CREATE_RECEIVER_REQUESTS_FULFILLED': {
      const requests = action.payload.data;

      const updateRequests = (recReqs: ReceiverRequest[]) => {
        const newRequests = requests.filter(
          (re: ReceiverRequest) => !recReqs.find(recReq => recReq.brand_id === re.brand_id)
        );
        const updateExistingReq = recReqs.map(
          request =>
            requests.find((req: ReceiverRequest) => req.brand_id === request.brand_id) || request
        );

        return [...updateExistingReq, ...newRequests];
      };

      const receiverList = state.receiverList.map(receiver =>
        receiver.receiver_id === action.meta.receiverId
          ? {
              ...receiver,
              requests: updateRequests(receiver.requests),
            }
          : receiver
      );
      return {
        ...state,
        receiverList,
      };
    }
    case 'APPROVE_RECEIVERS_FULFILLED': {
      const { brandIds } = action.meta;
      const receiverRequestCount =
        state.receiverRequestCount - brandIds.length > 0
          ? state.receiverRequestCount - brandIds.length
          : 0;
      return {
        ...state,
        receiverList: state.receiverList.map(receiver =>
          receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                requests: receiver.requests.map(request =>
                  brandIds.includes(request.brand_id)
                    ? {
                        ...request,
                        authorized_by_brand_at: new Date().toString(),
                        declined_by_brand_at: null,
                        price_sheet_ids: action.meta.selectedPriceSheetIds,
                        price_type_ids: action.meta.selectedPriceTypeIds,
                        without_prices: action.meta.withoutPrices,
                        status: APPROVED,
                      }
                    : request
                ),
              }
            : receiver
        ),
        receiverRequestCount,
      };
    }
    case 'EDIT_RECEIVER_PRICES_FULFILLED': {
      return {
        ...state,
        receiverList: state.receiverList.map(receiver =>
          receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                requests: receiver.requests.map(request =>
                  request.brand_id === action.meta.brandId
                    ? {
                        ...request,
                        price_sheet_ids: action.meta.selectedPriceSheetIds,
                        price_type_ids: action.meta.selectedPriceTypeIds,
                        without_prices: action.meta.withoutPrices,
                      }
                    : request
                ),
              }
            : receiver
        ),
      };
    }
    case 'EDIT_RECEIVER_FILTERS_FULFILLED': {
      return {
        ...state,
        receiverList: state.receiverList.map(receiver =>
          receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                requests: receiver.requests.map(request =>
                  request.brand_id === action.meta.brandId
                    ? { ...request, filter_ids: action.meta.filterIds }
                    : request
                ),
              }
            : receiver
        ),
      };
    }

    case 'DECLINE_RECEIVERS_FULFILLED': {
      const { brandIds, reason } = action.meta;
      const receiverRequestCount =
        state.receiverRequestCount - brandIds.length > 0
          ? state.receiverRequestCount - brandIds.length
          : 0;
      return {
        ...state,
        receiverList: state.receiverList.map(receiver =>
          receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                requests: receiver.requests.map(request =>
                  brandIds.includes(request.brand_id)
                    ? {
                        ...request,
                        declined_by_brand_at: new Date().toString(),
                        declined_by_brand_reason: reason,
                        status: REJECTED,
                      }
                    : request
                ),
              }
            : receiver
        ),
        receiverRequestCount,
      };
    }
    case 'CANCEL_REQUESTS_FULFILLED': {
      return {
        ...state,
        receiverList: state.receiverList.map(receiver =>
          receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                requests: receiver.requests.map(request =>
                  action.meta.brandIds.includes(request.brand_id)
                    ? {
                        ...request,
                        authorized_by_brand_at: null,
                        request_canceled_at: new Date().toString(),
                        status: CANCELED,
                      }
                    : request
                ),
              }
            : receiver
        ),
      };
    }
    case 'CREATE_RECEIVER_DETAILS_REQUEST_FULFILLED': {
      return {
        ...state,
        receiverList: state.receiverList.map(receiver => {
          return receiver.receiver_id === action.meta.receiverId
            ? {
                ...receiver,
                receiver_profile_requested_at: 'requested',
              }
            : receiver;
        }),
      };
    }
  }
  return state;
};

export default reducer;
