export type ItemCustomField = {
  id: number;
  created_at: string;
  updated_at: string;
  source_id: number;
  user_id: number;
  changed_by_user_id: number | null;
  item_id: number;
  brand_custom_field_id: number;
  value: string;
};

export type MappedItemCustomFields = {
  id: number;
  custom_field_type_id: number;
  name: string;
  options: CustomFieldOption[];
  value: string;
};

export type BrandCustomField = {
  id: number;
  custom_field_type_id: CustomFieldType;
  record_number: number;
  visible_to_receiver: number;
  ignore_on_sync: number;
  script_id: number;
  name: string;
  item_count: number;
  options: CustomFieldOption[];
};

export type CustomFieldOption = {
  id: number;
  custom_field_id: number;
  record_number: number;
  name: string;
};

export enum CustomFieldType {
  'Number' = 1,
  'Input' = 2,
  'Dropdown' = 3,
  'Checkbox' = 4,
  'Text_Editor' = 5,
  'Link' = 6,
  'Button' = 7,
}
