import axios from 'axios';
import { DefaultValue } from '../../../../types/brand_settings';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchSmallShopPreview(itemId: number) {
  return (dispatch: any, getState: any) => {
    const defaultValues = getState().settings.defaultValues;
    const defLanguage = defaultValues.find((d: DefaultValue) => d.resource_table === 'languages');

    const params = utils.createURL([
      { name: 'preview_item_id', values: itemId },
      { name: 'language_id', values: defLanguage?.value },
    ]);

    return dispatch({
      type: 'FETCH_SMALL_SHOP_PREVIEW',
      payload: axios.get(`${getApiRoot()}/items/small_shop_preview${params}`),
    });
  };
}
