import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchValidatedAnalysisTypes(brandId) {
  return {
    type: 'FETCH_VALIDATED_ANALYSIS_TYPES',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/validated_analysis_types`),
  };
}

export function createAnalysisTypeForBrand(analysisTypeIds, brandId) {
  analysisTypeIds = Array.isArray(analysisTypeIds) ? analysisTypeIds : [analysisTypeIds];
  const params = utils.createURL([
    { name: 'analysis_type_ids', values: analysisTypeIds },
    { name: 'brand_id', values: brandId },
  ]);

  return {
    type: 'CREATE_ANALYSIS_TYPE_FOR_BRAND',
    meta: {
      analysisTypeIds,
    },
    payload: axios.post(`${getApiRoot()}/parents/owners/brands/validated_analysis_types${params}`),
  };
}

export function updateSelectedSegmentId(id) {
  return {
    type: 'UPDATE_SELECTED_SEGMENT_ID',
    payload: id,
  };
}

export function updateSelectedAlertTypeId(id) {
  return {
    type: 'UPDATE_SELECTED_ALERT_TYPE_ID',
    payload: id,
  };
}

export function updateSelectedScorecardId(id) {
  return {
    type: 'UPDATE_SELECTED_SCORECARD_ID',
    payload: id,
  };
}

export function updateAnalysesProgress(brandId) {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'UPDATE_ANALYSES_PROGRESS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/update_scores${params}`),
  };
}

export function deleteAnalysisTypeForBrand(analysisTypeIds, brandId) {
  analysisTypeIds = Array.isArray(analysisTypeIds) ? analysisTypeIds : [analysisTypeIds];
  const params = utils.createURL([
    { name: 'analysis_type_ids', values: analysisTypeIds },
    { name: 'brand_id', values: brandId },
  ]);
  return {
    type: 'DELETE_ANALYSIS_TYPE_FOR_BRAND',
    meta: {
      analysisTypeIds,
    },
    payload: axios.delete(
      `${getApiRoot()}/parents/owners/brands/validated_analysis_types${params}`
    ),
  };
}
