import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;

export function fetchExtendedInfos(itemIds: number[]) {
  if (fetchCancelToken) fetchCancelToken();
  return {
    type: 'FETCH_EXTENDED_INFORMATIONS',
    payload: axios.post(
      `${getApiRoot()}/items/extended_informations`,
      {
        item_ids: itemIds,
      },
      {
        cancelToken: new CancelToken(c => {
          fetchCancelToken = c;
        }),
      }
    ),
  };
}

export function fetchExtendedInfoTypeOptionsOrder(brandId: number) {
  return {
    type: 'FETCH_EXTENDED_INFORMATION_TYPES_OPTIONS',
    payload: axios.get(
      `${getApiRoot()}/parents/owners/brands/${brandId}/extended_information_type_options`
    ),
    meta: { brandId },
  };
}

export function fetchUsedExtendedInfoTypes(brandId: number) {
  return {
    type: 'FETCH_EXTENDED_INFORMATION_USED_TYPES',
    payload: axios.get(
      `${getApiRoot()}/parents/owners/brands/${brandId}/extended_information_types`
    ),
    meta: { brandId },
  };
}
