import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { BundleItem, Qualifier, ApplicationNote, Application } from '../../../../types/application';
import { booleanToInt } from '../../../utils/Utils';

export function selectApplication(applicationId: number | null) {
  return {
    type: 'SELECT_APPLICATION',
    payload: {
      applicationId,
    },
  };
}

export function selectApplicationDigitalAsset(digitalAssetId: number) {
  return {
    type: 'SELECT_APPLICATION_DIGITAL_ASSET',
    payload: digitalAssetId,
  };
}

export function updateApplicationDigitalAsset(
  applicationDigitalAssetId: number,
  key: string,
  value: string
) {
  const params = utils.createURL([{ name: key, values: value }]);
  return {
    type: 'UPDATE_APPLICATION_DIGITAL_ASSET',
    meta: {
      applicationDigitalAssetId,
      key,
      value,
    },
    payload: axios.patch(
      `${getApiRoot()}/items/applications/digital_assets/${applicationDigitalAssetId}/${params}`
    ),
  };
}

export function setRecommendations(getRecommendation: boolean) {
  return {
    type: 'SET_RECOMMENDATIONS',
    payload: getRecommendation,
  };
}

export function showApplicationListView(showListView: boolean) {
  return {
    type: 'SET_APPLICATION_LIST_VIEW',
    payload: { showListView },
  };
}

export function showApplicationGridView(showGridView: boolean) {
  return {
    type: 'SET_APPLICATION_GRID_VIEW',
    payload: { showGridView },
  };
}

export function updateNotes(applicationId: number, notes: ApplicationNote[]) {
  return {
    type: 'UPDATE_APPLICATION_NOTES',
    payload: axios.put(`${getApiRoot()}/items/applications/${applicationId}/notes`, { notes }),
    meta: { applicationId, notes },
  };
}

export function updateQualifiers(applicationId: number, qualifiers: Qualifier[]) {
  return {
    type: 'UPDATE_APPLICATION_QUALIFIERS',
    payload: axios.put(`${getApiRoot()}/items/applications/${applicationId}/qualifiers`, {
      qualifiers,
    }),
    meta: { applicationId, qualifiers },
  };
}

export function updateDefaultQualifiers(itemId: number, qualifiers: { [key: string]: any }[]) {
  return {
    type: 'UPDATE_DEFAULT_QUALIFIERS',
    payload: axios.patch(`${getApiRoot()}/items/${itemId}/qualifiers`, { qualifiers }),
  };
}

export function updateDefaultNotes(itemId: number, notes: { [key: string]: any }[]) {
  const updateObj = { notes };

  return {
    type: 'UPDATE_DEFAULT_NOTES',
    payload: axios.patch(`${getApiRoot()}/items/${itemId}/notes`, updateObj),
  };
}

export function setUsedQualifiers(qualifiers: { [key: string]: any }[], applicationId: number) {
  return {
    type: 'SET_APPLICATION_QUALIFIERS',
    payload: qualifiers,
    meta: { applicationId },
  };
}

export function updateListFilterKeywords(filterId: number, keywords: string) {
  const urlParams = utils.createURL([{ name: 'keywords', values: keywords, allowEmpty: true }]);

  return {
    type: 'UPDATE_LIST_FILTER_KEYWORDS',
    payload: axios.patch(`${getApiRoot()}/filters/${filterId}${urlParams}`),
    meta: { keywords },
  };
}

export const updateApplicationBundle = (itemBases: BundleItem[]) => {
  const params = utils.createURL([
    // { name: 'segment_id', values: 3 },
  ]);
  return {
    type: 'UPDATE_APPLICATION_BUNDLE',
    payload: axios.patch(`${getApiRoot()}/items/bases${params}`, { item_bases: itemBases }),
  };
};

export function selectVcdbs(ids: number[]) {
  return {
    type: 'SELECT_VCDBS',
    payload: { ids },
  };
}

export const updateApplication = (application: Application, itemId: number) => {
  const params = utils.createURL([{ name: 'item_id', values: itemId }]);
  return {
    type: 'UPDATE_APPLICATION',
    payload: axios.post(`${getApiRoot()}/v2/items/applications${params}`, {
      applications: [application],
    }),
    meta: { application },
  };
};

export const updateCustomVehicleConfigs = (
  configs: { [key: string]: { [key: string]: string }[] },
  versionId: number,
  createConfig?: boolean,
  createVehicleConfig?: boolean
) => {
  const params = utils.createURL([
    { name: 'create_config', values: booleanToInt(createConfig) },
    { name: 'create_vehicle_config', values: booleanToInt(createVehicleConfig) },
    { name: 'version_id', values: versionId },
  ]);

  return {
    type: 'UPDATE_CUSTOM_VEHICLE',
    payload: axios.post(`${getApiRoot()}/accounts/custom_vehicles${params}`, {
      config: configs,
    }),
  };
};

export const changeCustomVehicle = (
  configs: { [key: string]: { [key: string]: string }[] },
  versionId: number,
  vehicleConfigId: number,
  configType: string
) => {
  const params = utils.createURL([
    { name: 'vehicle_config_id', values: vehicleConfigId },
    { name: 'vehicle_version_id', values: versionId },
    { name: 'config_type', values: configType },
  ]);

  return {
    type: 'CHANGE_CUSTOM_VEHICLE',
    payload: axios.patch(`${getApiRoot()}/accounts/custom_vehicles/vehicle_configs${params}`, {
      config: configs,
    }),
  };
};

export const addCustomSubConfig = (
  configs: { [key: string]: { [key: string]: string }[] },
  versionId: number
) => {
  const params = utils.createURL([
    { name: 'create_sub_config', values: 1 },
    { name: 'version_id', values: versionId },
  ]);

  return {
    type: 'CREATE_CUSTOM_SUB_CONFIG',
    payload: axios.post(`${getApiRoot()}/accounts/custom_vehicles${params}`, {
      config: configs,
    }),
  };
};
