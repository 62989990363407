import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover, Card, Button, Row, Col, Form, Select, Spin, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ApplicationState } from '../../../reducers';
import { AttributeRankedValue, AttributeTranslation, Unit } from '../../../../types/attributes';
import AttributeTranslationTable from './AttributeTranslationTable';
import { fetchUnits, fetchValues } from '../../../actions/items/attributes/fetch';
import { AsyncDispatch } from '../../../../types/global';
import { generateUUID, typingDone } from '../../../utils/Utils';
import { hasPermission } from '../../../utils/Permissions';
import { fetchTranslations } from '../../../actions/parent/language/fetch';
import { LanguageTranslation } from '../../../../types/language';

type AttributeValuePopoverProps = {
  type?: string;
  valueName?: string;
  attrMetaUomLabel?: string;
  valueId?: number;
  metaUomId?: number | null;
  uniqueId?: string;
  translations?: AttributeTranslation[];
  unitTranslations?: AttributeTranslation[];
  attributeIds?: number[];
  addButtonVisisble?: boolean;
  handleChange: (props: {
    uomlabel: string;
    valueId: number | null;
    uniqueId?: string;
    prevValueId?: number;
    uomId: number | null;
    valueName: string;
  }) => void;
  onClose?: () => void;
};

const AttributeValuePopover: React.FC<AttributeValuePopoverProps> = props => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const navigate = useNavigate();

  const [valueTranslations, setValueTranslations] = React.useState(props.translations);
  const [unitTranslations, setUnitTranslations] = React.useState(props.unitTranslations);

  const { selectedBrandItemIds, units, attributeValues, fetchingValues, fetchingUnits, user } =
    useSelector((state: ApplicationState) => {
      return {
        selectedBrandItemIds: state.catalogue.catalogue.allSelectedItemIds,
        units: state.items.attributes.rankedUnits,
        attributeValues: state.items.attributes.rankedValues,
        fetchingValues: state.items.attributes.fetchingValues,
        fetchingUnits: state.items.attributes.fetchingUnits,
        user: state.user.user,
      };
    });

  const [popoverVisible, setPopoverVisible] = React.useState(false);
  const [keywords, setKeywords] = React.useState('');
  const [valuesList, setValuesList] = React.useState<AttributeRankedValue[]>(attributeValues || []);

  const canManageTranslations = hasPermission(user, 'can_manage_translation');

  const handleFetchAttrValueTranslation = (value: string) => {
    dispatch(fetchTranslations({ translationTypeId: 2, keywords: value })).then(response => {
      const translation = response.value.data.find((data: LanguageTranslation) =>
        data.translations?.find(t => t.language_id === 1 && t.value === value)
      );
      setValueTranslations(translation?.translations || []);
    });
  };

  const handleFetchAttrUnitTranslation = (value: string) => {
    dispatch(fetchTranslations({ translationTypeId: 3, keywords: value })).then(response => {
      const translation = response.value.data.find((data: LanguageTranslation) =>
        data.translations?.find(t => t.language_id === 1 && t.value === value)
      );
      setUnitTranslations(translation?.translations || []);
    });
  };

  const fetchValuesAndUnits = () => {
    dispatch(fetchUnits({ itemIds: selectedBrandItemIds, attributeIds: props.attributeIds }));
    dispatch(
      // @ts-ignore
      fetchValues({ itemIds: selectedBrandItemIds, attributeIds: props.attributeIds })
    ).then(response => {
      setValuesList(response.action.payload.data);
    });
  };

  const getAtrrValues = (keywords: string) => {
    setValuesList([]);
    dispatch(
      // @ts-ignore
      fetchValues({
        itemIds: selectedBrandItemIds,
        attributeIds: props.attributeIds,
        filter: keywords,
      })
    ).then(response => {
      setValuesList(response.action.payload.data);
      const findValue = response.action.payload.data.find(
        (v: AttributeRankedValue) => v.name === keywords
      );
      if (!findValue)
        setValuesList([
          ...(keywords ? [{ name: keywords, id: null, uniqueId: generateUUID() }] : []),
          ...response.action.payload.data,
        ]);
    });
  };

  const getUnits = (unit: string) => {
    dispatch(
      fetchUnits({
        itemIds: selectedBrandItemIds,
        attributeIds: props.attributeIds,
        filter: unit,
      })
    );
  };

  const validationSchema = () => {
    return Yup.object().shape({
      valueId: Yup.number()
        .nullable()
        .when(['valueName'], ([valueName], schema) => {
          return !valueName || valueName.length === 0
            ? schema.required(t('validation:required'))
            : schema;
        }),
    });
  };

  return (
    <Popover
      key={props.valueId || -1}
      open={popoverVisible}
      onOpenChange={visible => {
        if (!visible && props.onClose) props.onClose();
        setPopoverVisible(visible);
        if (visible) fetchValuesAndUnits();
      }}
      overlayClassName="help attribute_popover"
      placement="bottomLeft"
      destroyTooltipOnHide
      content={
        popoverVisible ? (
          <Card className="attribute__form" onClick={e => e.stopPropagation()}>
            <Formik
              initialValues={{
                valueId: props.valueId || undefined,
                uomId: props.metaUomId || null,
                uomlabel: props.attrMetaUomLabel || '',
                valueName: props.valueName || '',
                valueUniqueId: valuesList.find(v => v.name === props.valueName)?.uniqueId,
              }}
              validationSchema={validationSchema()}
              onSubmit={values => {
                props.handleChange({
                  uomlabel: values.uomlabel,
                  valueId: values.valueId!,
                  uomId: values.uomId,
                  valueName: values.valueName,
                  prevValueId: props.valueId,
                  uniqueId: props.uniqueId || values.valueUniqueId,
                });
                if (props.onClose) props.onClose();
                setPopoverVisible(false);
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <React.Fragment>
                  <Form layout="vertical">
                    <Row gutter={20} align="top">
                      <Col span={12}>
                        <Form.Item
                          label={t('attributes:value')}
                          hasFeedback
                          validateStatus={errors.valueId && touched.valueId ? 'error' : undefined}
                          help={(touched.valueId && errors.valueId) || undefined}
                          required
                          data-testid="title"
                        >
                          <Select
                            data-testid="value"
                            showSearch
                            value={
                              valuesList.find(
                                v =>
                                  v.id === values.valueId ||
                                  (values.valueUniqueId && v.uniqueId === values.valueUniqueId)
                              )
                                ? values.valueId || values.valueUniqueId
                                : values.valueName
                            }
                            onClick={e => e.stopPropagation()}
                            placeholder={t('attributes:selectValue')}
                            notFoundContent={fetchingValues ? <Spin size="small" /> : undefined}
                            filterOption={false}
                            onSearch={keywords => {
                              setKeywords(keywords);
                              typingDone(() => getAtrrValues(keywords));
                            }}
                            onChange={valueId => {
                              const value = valuesList.find(
                                v => v.id === valueId || v.uniqueId === valueId
                              );
                              setFieldValue('valueName', value?.name);
                              setFieldValue(
                                'valueId',
                                typeof valueId === 'string' ? null : valueId
                              );
                              setFieldValue('valueUniqueId', value?.uniqueId);
                              if (valueId) handleFetchAttrValueTranslation(value?.name || '');
                              else setValueTranslations([]);
                            }}
                            style={{ width: '100%' }}
                            onDropdownVisibleChange={open => {
                              if (!open && !values.valueName) {
                                const value = valuesList.find(v => v.name === keywords);
                                setFieldValue('valueName', value?.name || keywords);
                                setFieldValue('valueUniqueId', value?.uniqueId);
                              }
                            }}
                          >
                            {!fetchingValues &&
                              values.valueName &&
                              !valuesList.find(v => v.name === values.valueName) && (
                                <Select.Option
                                  key={values.valueId || values.valueUniqueId || values.valueName}
                                  value={values.valueId || values.valueUniqueId || values.valueName}
                                >
                                  {values.valueName}
                                </Select.Option>
                              )}
                            {valuesList.map(u => (
                              <Select.Option
                                key={u.id ? u.id : u.uniqueId}
                                value={u.id ? u.id : u.uniqueId}
                              >
                                {u.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={t('attributes:unit')}>
                          <Select
                            data-testid="unit"
                            showSearch
                            value={values?.uomId || undefined}
                            placeholder={t('attributes:selectUnit')}
                            onClick={e => e.stopPropagation()}
                            notFoundContent={fetchingUnits ? <Spin size="small" /> : undefined}
                            filterOption={false}
                            onSearch={(keywords: string) => {
                              const unit = keywords;
                              typingDone(() => getUnits(unit));
                            }}
                            onChange={(uomId: number) => {
                              const uomLabel = units.find((u: Unit) => u.id === uomId)?.label;
                              setFieldValue('uomId', uomId || null);
                              setFieldValue('uomlabel', uomLabel || '');

                              if (uomId) handleFetchAttrUnitTranslation(uomLabel || '');
                              else setUnitTranslations([]);
                            }}
                            style={{ width: '100%' }}
                            allowClear
                          >
                            {units.map((u: Unit) => (
                              <Select.Option key={u.id} value={u.id} data-testid="unit-option">
                                {u.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {canManageTranslations && (
                      <React.Fragment>
                        <div className="flex justify-between">
                          <div>{t('attributes:translations')}</div>
                          <Popconfirm
                            title={t('attributes:confirmSwitchToTanslations')}
                            okText={t('common:yes')}
                            cancelText={t('common:cancel')}
                            onConfirm={() =>
                              navigate('/settings/translations/translation-settings', {
                                state: { segmentId: 2 },
                              })
                            }
                          >
                            <Button size="small">{t('attributes:editTranslations')}</Button>
                          </Popconfirm>
                        </div>
                        <div className="attribute-translation-table">
                          <AttributeTranslationTable
                            type={props.type}
                            translations={valueTranslations || []}
                            unitTranslations={unitTranslations || []}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </Form>

                  <div className="flex-col items-end mt-2">
                    <div>
                      <Button
                        onClick={e => {
                          if (props.onClose) props.onClose();
                          setPopoverVisible(false);
                          e.stopPropagation();
                        }}
                        className="drawer-submit__bottom-cancel"
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        data-testid="ok"
                        type="primary"
                        onClick={e => {
                          handleSubmit();
                          e.stopPropagation();
                        }}
                      >
                        {t('common:ok')}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Formik>
          </Card>
        ) : null
      }
      trigger="click"
    >
      {props.type === 'addValue' && (
        <div className="attribute__add-value-wrapper">
          <PlusSquareOutlined
            className={classNames('attribute__add-value', {
              visible: popoverVisible || props.addButtonVisisble,
            })}
            onClick={e => {
              setPopoverVisible(true);
              fetchValuesAndUnits();
              e.stopPropagation();
            }}
          />
        </div>
      )}
      {props.type === 'addValueButton' && (
        <Button
          onClick={() => {
            setPopoverVisible(true);
          }}
          type="primary"
          ghost
          size="small"
          data-testid="add-value"
        >
          {t('attributes:addValue')}
        </Button>
      )}
      {props.type === 'editValue' && (
        <EditOutlined
          onClick={e => {
            setPopoverVisible(true);
            fetchValuesAndUnits();
            e.stopPropagation();
          }}
        />
      )}
      {props.type === 'drawerEditValue' && (
        <EditOutlined
          onClick={e => {
            setPopoverVisible(true);
            e.stopPropagation();
          }}
        />
      )}
      {!props.type && props.children}
    </Popover>
  );
};

export default AttributeValuePopover;
