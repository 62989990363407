import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchAnalysesCount(brandId) {
  return {
    type: 'FETCH_ANALYSES_COUNT',
    payload: axios.get(
      `${getApiRoot()}/parents/owners/brands/${brandId}/analysis_types/items_count`
    ),
  };
}
export function fetchAllBrands() {
  return {
    type: 'FETCH_ALL_BRANDS',
    payload: axios.get(`${getApiRoot()}/parents/brand_levels`),
  };
}

export function fetchHistoryAnalysis(brandId) {
  return {
    type: 'FETCH_HISTORY_ANALYSIS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/scores`),
  };
}

export function fetchAverageSegmentRecomAnalysis(brandId) {
  // in the future date will be send as parameter
  return {
    type: 'FETCH_AVERAGE_SEGMENT_RECOM_ANALYSIS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/segment_scores`),
  };
}

export function fetchLatestProductChangesAnalysis(brandId, page = 1) {
  const params = utils.createURL([
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'order_condition', values: 'updated_at' },
    { name: 'descending', values: 1 },
    { name: 'page', values: page },
    { name: 'limit', values: utils.getPageLimit() },
  ]);
  return {
    type: 'FETCH_LATEST_PRODUCT_CHANGES_ANALYSIS',
    payload: axios.post(`${getApiRoot()}/items/search${params}`),
    meta: { page },
  };
}
