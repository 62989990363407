import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchBrands(parentId) {
  const params = utils.createURL([{ name: 'parent_id', values: parentId }]);

  return {
    type: 'FETCH_BRANDS',
    payload: axios.get(`${getApiRoot()}/authorized_brands${params}`),
  };
}
