import axios from 'axios';
import { AsyncAction } from 'redux-promise-middleware';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

const CancelToken = axios.CancelToken;

let fetchCancelToken: any;

export function fetchReceiverBrandsByFilterId(
  filterId: number,
  brandIds?: number[],
  filterSourceId?: number,
  statusIds?: number[],
  orderCondition?: string,
  sortOrder?: number,
  page = 1
): AsyncAction {
  if (fetchCancelToken) {
    fetchCancelToken();
  }
  const params = utils.createURL([
    { name: 'filter_brand_ids', values: brandIds },
    { name: 'external_data_provider_id', values: filterSourceId },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
    { name: 'status_ids', values: statusIds },
    { name: 'order_condition', values: orderCondition },
    { name: 'descending', values: sortOrder },
  ]);
  return {
    type: 'FETCH_RECEIVER_BRANDS_BY_FILTER_ID',
    payload: axios.get(`${getApiRoot()}/filters/${filterId}/brands${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
    meta: { page },
  };
}

export function fetchAllReceiverBrands(): AsyncAction {
  return {
    type: 'FETCH_ALL_RECEIVER_BRANDS',
    payload: axios.get(`${getApiRoot()}/available_brands`),
  };
}

export function fetchReceiverFilters(receiverId: number): AsyncAction {
  const params = utils.createURL([{ name: 'receiver_id', values: receiverId }]);
  return {
    type: 'FETCH_FILTERS',
    payload: axios.get(`${getApiRoot()}/filters${params}`),
  };
}

export function fetchAuthorizedBrands() {
  return {
    type: 'FETCH_AUTHORIZED_BRANDS',
    payload: axios.get(`${getApiRoot()}/authorized_brands`),
  };
}

export function fetchReceiverSubBrands(brandIds: number[]) {
  const params = utils.createURL([{ name: 'external_brand_ids', values: brandIds }]);

  return {
    type: 'FETCH_RECEIVER_SUB_BRANDS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/sub_brands${params}`),
  };
}

export function fetchAvailableChannelsReceivers(brandIds?: number[]) {
  const params = utils.createURL([{ name: 'brand_ids', values: brandIds }]);

  return {
    type: '@RECEIVER/FETCH_AVAILABLE_CHANNELS_RECEIVERS',
    payload: axios.get(`${getApiRoot()}/available_channels${params}`),
  };
}

export function fetchPendingDetailsRequests() {
  return {
    type: 'FETCH_PENDING_DETAILS_REQUESTS',
    payload: axios.get(`${getApiRoot()}/receivers/pending_details_request`),
  };
}
