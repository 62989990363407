import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { fetchItemAnalyses } from './fetch';

export function resetItemAnalyses() {
  return {
    type: 'RESET_ITEM_ANALYSES',
  };
}

export function hideAnalysis(analysisIds: number[], typeId: number, statusId = 3) {
  const params = {
    item_analysis_ids: analysisIds.join(','),
    status_id: statusId,
  };

  return {
    type: 'HIDE_ANALYSIS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/update`, params),
    meta: { typeId },
  };
}

export function hideAnalysisAndFetch(
  itemIds: number[],
  analysisIds: number[],
  typeId: number,
  statusId = 3
) {
  return async (dispatch: any) => {
    await dispatch(hideAnalysis(analysisIds, typeId, statusId));
    // /items/analyses/update is excluded for our session / items update, so we have to tigger it manual
    dispatch(fetchItemAnalyses(itemIds));
  };
}

export function triggerAnalysesUpdate(itemIds: number[]) {
  const urlParams = utils.createURL([{ name: 'item_ids', values: itemIds }]);

  return {
    type: 'TRIGGER_ANALYSES_UPDATE',
    payload: axios.patch(`${getApiRoot()}/items/analyses/individual_analyses${urlParams}`),
  };
}

export function confirmReviews({
  itemAnalysisIds,
  typeId,
  brandId,
  validValueId,
  vehicleBaseIds,
}: {
  itemAnalysisIds: number[];
  typeId: number;
  brandId: number;
  validValueId?: number;
  vehicleBaseIds?: number[];
}) {
  const analysisIds =
    itemAnalysisIds instanceof Array ? itemAnalysisIds.join(',') : itemAnalysisIds;
  return {
    type: 'CONFIRM_REVIEWS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/confirm`, {
      item_analysis_ids: String(analysisIds),
      type_id: String(typeId),
      brand_id: brandId,
      value_id: validValueId,
      vehicle_base_ids: vehicleBaseIds,
    }),
    meta: { itemAnalysisIds, vehicleBaseIds },
  };
}

export function confirmAllReviews(groupKey: string, typeId: number, brandId: number) {
  return {
    type: 'CONFIRM_ALL_REVIEWS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/confirm`, {
      group_key: String(groupKey),
      type_id: String(typeId),
      brand_id: brandId,
    }),
  };
}

export function reopenReviews(itemAnalysisIds: number[]) {
  return {
    type: 'REOPEN_REVIEWS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/update`, {
      item_analysis_ids: String(itemAnalysisIds),
      status_id: String(1), // 1 for reopend
    }),
  };
}

export function declineReviews(itemAnalysisIds: number[]) {
  const analysisIds =
    itemAnalysisIds instanceof Array ? itemAnalysisIds.join(',') : itemAnalysisIds;
  return {
    type: 'DECLINE_REVIEWS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/update`, {
      item_analysis_ids: String(analysisIds),
      status_id: String(3), // 3 for ignored
    }),
  };
}

export function declineAllReviews(groupKey: string, typeId: number, brandId: number) {
  return {
    type: 'DECLINE_ALL_REVIEWS',
    payload: axios.patch(`${getApiRoot()}/items/analyses/update`, {
      group_key: String(groupKey),
      type_id: String(typeId),
      brand_id: brandId,
      status_id: String(3), // 3 for ignored
    }),
  };
}

export function updateReviewCategory(
  groupKey: string,
  referenceId: number,
  categoryId: number,
  typeId: number,
  brandId: number
) {
  return {
    type: 'UPDATE_REVIEW_CATEGORY',
    meta: { referenceId, categoryId },
    payload: axios.patch(`${getApiRoot()}/items/analyses/update`, {
      group_key: groupKey,
      reference_id: String(categoryId),
      type_id: String(typeId),
      brand_id: brandId,
    }),
  };
}
