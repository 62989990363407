import Axios from 'axios';
import { Dispatch } from 'redux';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import { createURL } from '../../utils/Utils';

export function fetchAllChannelPushLogs() {
  const params = createURL([
    { name: 'page', values: 1 },
    { name: 'limit', values: 50 },
  ]);

  return {
    type: '@CHANNEL_PUSHER/FETCH_ALL_CHANNEL_PUSH_LOGS',
    payload: Axios.get(`${getApiRoot()}/channels/delivery_logs${params}`),
  };
}

export function fetchChannelPushLogs(logIds: number[]) {
  const params = createURL([
    { name: 'channel_log_ids', values: logIds },
    { name: 'page', values: 1 },
    { name: 'limit', values: logIds.length },
  ]);

  return {
    type: '@CHANNEL_PUSHER/FETCH_CHANNEL_PUSH_LOGS',
    payload: Axios.get(`${getApiRoot()}/channels/delivery_logs${params}`),
  };
}

export const pushChannelUpdate = (channelPlanId: number) => {
  return async (dispatch: Dispatch) => {
    const params = createURL([
      { name: 'channel_plan_ids', values: channelPlanId },
      { name: 'manual_delivery_type_id', values: 1 },
    ]);

    const result = await Axios.post(`${getApiRoot()}/channels/plans/files${params}`);

    const logParams = createURL([
      { name: 'log_ids', values: result.data.channel_log_ids },
      { name: 'page', values: 1 },
      { name: 'limit', values: result.data.channel_log_ids.length },
    ]);

    const newLogs = await Axios.get(`${getApiRoot()}/channels/delivery_logs${logParams}`);

    return dispatch({
      type: '@CHANNEL_PUSHER/PUSH_UPDATE',
      payload: newLogs,
    });
  };
};

export const channelPusherCloseNotification = () => {
  return {
    type: '@CHANNEL_PUSHER/CLOSE_NOTIFICATION',
  };
};
