import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;

export function fetchCustomFields(itemIds: number[]) {
  if (fetchCancelToken) fetchCancelToken();

  const params = utils.createURL([{ name: 'item_ids', values: itemIds }]);
  return {
    type: 'FETCH_CUSTOM_FIELDS',
    payload: axios.get(`${getApiRoot()}/items/custom_fields/${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
  };
}
