import { Input, Button, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { APIScript } from '../../../../types/distribution';
import { StandardResourceCode } from '../../../../types/resources';
import {
  fetchScriptFile,
  resetCache,
  resetScriptFile,
} from '../../../actions/distribution_apisettings';
import { ApplicationState } from '../../../reducers';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { hasPermission } from '../../../utils/Permissions';
import { dateToString } from '../parentSettings/importLogs/ImportExportUtils';
import ApiAutomationDrawer from './ApiAutomationDrawer';
import ApiAutomationTable from './ApiAutomationTable';

const ApiAutomationPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterKeywords, setFilterKeywords] = React.useState<string>('');
  const [displayScriptDrawer, setDisplayScriptDrawer] = React.useState<boolean>(false);
  const [editScriptData, setEditScriptData] = React.useState<APIScript | undefined>();

  const { scripts, fetchingScripts, scriptText, fetchingScriptFile, user } = useSelector(
    (state: ApplicationState) => {
      const getScripts = state.distribution.apiSettings.scripts;
      const cacheTTlTypes = (state.resources.data.script.cache_ttl_types ||
        []) as StandardResourceCode[];
      const getScriptsValuesWithNames = () =>
        getScripts.map(script => {
          return {
            ...script,
            activeValue: script.active === 1 ? t('common:yes') : t('common:no'),
            cacheTTlName: script.cache_ttl_type_id
              ? cacheTTlTypes.find(ttl => ttl.id === script.cache_ttl_type_id)?.name
              : t('api:noCache'),
            lastExecution: script.started_at ? dateToString(script.started_at) : '',
          };
        });

      return {
        scripts: getScriptsValuesWithNames(),
        fetchingScripts: state.distribution.apiSettings.fetchingScripts,
        scriptText: state.distribution.apiSettings.scriptText,
        fetchingScriptFile: state.distribution.apiSettings.fetchingScriptFile,
        user: state.user.user,
      };
    }
  );

  React.useEffect(() => {
    intercomEvent('viewed-api', { location: 'automation' });
  }, []);

  const showCreateDrawer = () => {
    setDisplayScriptDrawer(true);
    intercomEvent('viewed-api', {
      location: 'automation',
      action: 'automation-new',
    });
  };

  const handleAction = (key: string, rowData: APIScript) => {
    switch (key) {
      case 'resetCache': {
        dispatch(resetCache(rowData.id));
        message.success(t('api:cacheTtlSuccess', { automationName: rowData.name }));
        break;
      }
      case 'editScript': {
        dispatch(fetchScriptFile(rowData.id));
        setEditScriptData(rowData);
        setDisplayScriptDrawer(true);
        intercomEvent('viewed-api', {
          location: 'automation',
          action: 'automation-edit',
        });
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-layout overflow-hidden">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <Input.Search
              className="page-layout__top-bar__search"
              value={filterKeywords}
              onChange={e => setFilterKeywords(e.target.value.toLowerCase())}
              placeholder={t('api:searchAutomation')}
              allowClear
            />
            <div className="page-layout__top-bar__actions">
              <Button
                type="primary"
                onClick={() => showCreateDrawer()}
                data-testid="new-automation"
              >
                {t('api:newAutomation')}
              </Button>
            </div>
          </div>
        </div>
        <div className="page-layout__content">
          <ApiAutomationTable
            scripts={scripts.filter(automation =>
              automation.name.toLowerCase().includes(filterKeywords)
            )}
            handleAction={handleAction}
            fetching={fetchingScripts}
          />
        </div>
      </div>
      {displayScriptDrawer && !fetchingScriptFile && (
        <ApiAutomationDrawer
          onClose={() => {
            setEditScriptData(undefined);
            setDisplayScriptDrawer(false);
            dispatch(resetScriptFile());
          }}
          scriptData={editScriptData}
          scriptText={scriptText}
          canManageScript={hasPermission(user, 'can_manage_scripts')}
        />
      )}
    </React.Fragment>
  );
};

export default ApiAutomationPage;
