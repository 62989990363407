import { message } from 'antd';
import axios from 'axios';

import { selectItem } from './update';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { fetchItemsByFilterId, fetchAllItemInfos } from './fetch';
import { AsyncDispatch } from '../../../../types/global';
import { fetchExtendedItem } from '../../items/item/fetch';
import { Order } from '../../../../types/catalogue';

export function createItem({
  partNumber,
  brandId,
  subBrandId,
  currentFilterId,
  selectedReceiverId,
  selectedChannelId,
  languageId,
  order = null,
}: {
  partNumber: string;
  brandId: number;
  subBrandId: number;
  currentFilterId: number;
  selectedReceiverId?: number | null;
  selectedChannelId?: number | null;
  languageId?: string | null;
  order?: Order | null;
}) {
  const params = utils.createURL([
    { name: 'part_number', values: partNumber },
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'parent_owner_brand_sub_brand_id', values: subBrandId },
  ]);

  return (dispatch: AsyncDispatch) =>
    dispatch({
      type: 'CREATE_ITEM',
      payload: axios.post(`${getApiRoot()}/items${params}`),
    }).then(({ value }) => {
      const item = value.data;
      dispatch(
        fetchItemsByFilterId({
          filterId: currentFilterId,
          selectedReceiverId,
          selectedChannelId,
          order,
          languageId,
        })
      ).then(() => {
        // @ts-ignore
        dispatch(fetchAllItemInfos(item.id));
      });
      message.success(`New Product ${item.part_number} created`);
    });
}

export function cloneItem({
  itemId,
  partNumber,
  brandId,
  subBrandId,
  segments,
  currentFilterId,
  selectedReceiverId,
  selectedChannelId,
  languageId,
  order = null,
}: {
  itemId: number;
  partNumber: string;
  brandId: number;
  subBrandId?: number;
  segments: string[];
  currentFilterId: number;
  selectedReceiverId?: number | null;
  selectedChannelId?: number | null;
  languageId?: string | null;
  order?: Order | null;
}) {
  const selectedSegments = segments.reduce((acc, x) => ({ ...acc, [x]: '1' }), {});

  const params = {
    ...selectedSegments,
    item_id: itemId,
    part_number: partNumber,
    parent_owner_brand_id: brandId,
    parent_owner_brand_sub_brand_id: subBrandId,
  };

  return (dispatch: AsyncDispatch) =>
    dispatch({
      type: 'CLONE_ITEM',
      payload: axios.post(`${getApiRoot()}/items/clone`, params),
    }).then(({ value }) => {
      const item = value.data;
      dispatch(
        fetchItemsByFilterId({
          filterId: currentFilterId,
          selectedReceiverId,
          selectedChannelId,
          languageId,
          order,
        })
      );
      dispatch(selectItem(item.id));
      // @ts-ignore
      dispatch(fetchAllItemInfos(item.id));

      // Cloning the item can change its score, e.g. if both items now have the same primary asset.
      // This is why we fetch the cloned item here.
      dispatch(fetchExtendedItem(itemId));
      message.success(`New Product ${item.part_number} created`);
    });
}

export function createTagForItems({
  itemIds = [],
  filterId,
  tagName,
  brandId,
  receiverId,
  withoutItemIds = [],
  filterBrandIds,
}: {
  itemIds?: number[];
  withoutItemIds?: number[];
  filterId?: number;
  tagName?: string;
  brandId?: number;
  receiverId?: number;
  filterBrandIds?: number[];
}) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'tag_name', values: tagName },
    { name: 'brand_id', values: brandId },
    { name: 'receiver_id', values: receiverId },
    { name: 'filter_brand_ids', values: filterBrandIds },
  ]);

  let itemIdsBody = {};
  const includeItemBody = itemIds && !filterId;
  if (includeItemBody && brandId) itemIdsBody = { item_ids: itemIds };
  if (includeItemBody && receiverId) itemIdsBody = { receiver_item_ids: itemIds };

  const withoutItemIdsBody = withoutItemIds ? { without_item_ids: withoutItemIds } : {};

  return {
    type: 'CREATE_TAG_FOR_ITEMS',
    meta: { itemIds, tagName, withoutItemIds },
    payload: axios.post(`${getApiRoot()}/items/tags${urlParams}`, {
      ...itemIdsBody,
      ...withoutItemIdsBody,
    }),
  };
}

export function createTagForReceiverItems({
  itemIds = [],
  filterId,
  tagName,
  receiverId,
}: {
  itemIds?: number[];
  filterId?: number;
  tagName: string;
  receiverId: number;
}) {
  const itemIdsString = itemIds.join(',');
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'tag_name', values: tagName },
    { name: 'receiver_id', values: receiverId },
  ]);

  return {
    type: 'CREATE_TAG_FOR_ITEMS',
    meta: { itemIds, tagName },
    payload: axios.post(
      `${getApiRoot()}/items/tags${urlParams}`,
      itemIdsString && { receiver_item_ids: itemIdsString }
    ),
  };
}

export function createMarkItem(itemId: number) {
  const urlParams = utils.createURL([{ name: 'item_ids', values: itemId }]);

  return {
    type: 'CREATE_MARK_ITEM',
    meta: { itemId },
    payload: axios.post(`${getApiRoot()}/items/marks${urlParams}`),
  };
}

export function createMarkItems(itemIds: number[]) {
  const urlParams = utils.createURL([{ name: 'item_ids', values: itemIds }]);

  return {
    type: 'CREATE_MARK_ITEMS',
    meta: { itemIds },
    payload: axios.post(`${getApiRoot()}/items/marks${urlParams}`),
  };
}

export function createMarkAllItems(filterId: number) {
  const urlParams = utils.createURL([{ name: 'filter_id', values: filterId }]);

  return {
    type: 'CREATE_MARK_ALL_ITEMS',
    payload: axios.post(`${getApiRoot()}/items/marks${urlParams}`),
  };
}
