import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot, navigationBarData } from '../../../constants/ParamountReactConstants';
import { isManufacturer } from '../../../utils/UserUtils';

const CancelToken = axios.CancelToken;
let fetchItemAnalysesCancelToken: any;

// rethink how to handle categories
export function fetchReviewCategories(brandId: number, keywords?: string, page = 1) {
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'keywords[0]', values: keywords },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_REVIEW_CATEGORIES',
    meta: { page },
    payload: axios.get(`${getApiRoot()}/categories${params}`),
  };
}

export function fetchItemAnalyses(
  itemIds: number | number[],
  initial?: boolean,
  segmentIds?: number[]
) {
  if (fetchItemAnalysesCancelToken) fetchItemAnalysesCancelToken();

  const itemIdsMapped = Array.isArray(itemIds) ? itemIds : [itemIds];
  const params = utils.createURL([{ name: 'segment_ids', values: segmentIds }]);

  return {
    type: 'FETCH_ITEM_ANALYSES',
    payload: axios.post(
      `${getApiRoot()}/items/analyses${params}`,
      { item_ids: itemIdsMapped },
      {
        cancelToken: new CancelToken(c => {
          fetchItemAnalysesCancelToken = c;
        }),
      }
    ),
    meta: { initial },
  };
}

export function fetchPartTypeAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '43,97' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_PART_TYPE_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchNewYearAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '91' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_NEW_YEAR_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchEngineBaseAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '210' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ENGINE_BASE_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchSubConfigAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '209' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_SUB_CONFIG_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchNewEngineAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '249' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 10 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_NEW_ENGINE_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchNewTransmissionAnalyses(brandId: number, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group', values: '1' },
    { name: 'type_ids', values: '250' },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 10 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_NEW_TRANSMISSION_ANALYSES',
    meta: { page },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

export function fetchAnalysesGroupItems(brandId: number, groupKey: string, page = 1) {
  const urlParams = utils.createURL([
    { name: 'group_key', values: groupKey },
    { name: 'group', values: 1 },
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ANALYSES_GROUP_ITEMS',
    meta: { page, groupKey },
    payload: axios.post(`${getApiRoot()}/items/analyses${urlParams}`),
  };
}

const ITEM_SEGMENT_ID = 1;
const APPLICATION_SEGMENT_ID = 3;
const PACKAGE_SEGMENT_ID = 5;

export function fetchItemAnalysesBySegment(itemIds: number[], activeArea: string) {
  return (dispatch: any, getState: any) => {
    const user = getState().user.user;
    const permissionToFetch = isManufacturer(user);
    if (!permissionToFetch) return;
    const activeAreaSegment = navigationBarData.find(navBar => navBar.key === activeArea);
    if (!activeAreaSegment) return;
    const activeAreaSegmentId = activeAreaSegment.id;
    if (!activeAreaSegmentId) return;
    const segmentIds = [activeAreaSegmentId];
    if (
      activeAreaSegmentId === APPLICATION_SEGMENT_ID ||
      activeAreaSegmentId === PACKAGE_SEGMENT_ID
    ) {
      segmentIds.push(ITEM_SEGMENT_ID);
    }
    return dispatch(fetchItemAnalyses(itemIds, false, segmentIds));
  };
}
