import axios from 'axios';
import { resetRedux } from '../app/global';
import { AsyncDispatch } from '../../../types/global';
import { getApiRoot, goOnlySystemApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export function removeTokenAndUser() {
  localStorage.removeItem('paramount-token');
  localStorage.removeItem('goOnlyRedirected');
  return {
    type: 'REMOVE_USER',
  };
}

export function fetchUsers(userIds?: number[]) {
  const params = utils.createURL([{ name: 'user_ids', values: userIds }]);
  return {
    type: 'FETCH_USERS',
    payload: axios.get(`${getApiRoot()}/accounts/users${params}`),
    meta: { userIds },
  };
}

export function fetchUserNames() {
  return {
    type: 'FETCH_USER_NAMES',
    payload: axios.get(`${getApiRoot()}/accounts/user_names`),
  };
}

export function login(username: string, password: string, apiRedirect: boolean) {
  return (dispatch: AsyncDispatch) => {
    dispatch(resetRedux());
    return dispatch({
      type: 'LOGIN',
      payload: axios.post(`${getApiRoot()}/users/login/v2`, {
        email: username,
        password,
      }),
      meta: { apiRedirect },
    }).then(res => {
      const token = res.value.data.token;
      localStorage.setItem('paramount-token', JSON.stringify(token));
      if (getApiRoot() === goOnlySystemApiRoot && apiRedirect)
        localStorage.setItem('goOnlyRedirected', 'true');
      return res;
    });
  };
}

export function createUser({
  emailNotification,
  firstName,
  lastName,
  email,
  password,
  admin,
}: {
  emailNotification: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  admin: number;
}) {
  return {
    type: 'CREATE_USER',
    payload: axios.post(`${getApiRoot()}/users`, {
      email_notification: emailNotification,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      admin,
    }),
  };
}

export function updateUser({
  id,
  firstName,
  lastName,
  email,
  admin,
}: {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  admin?: number;
}) {
  const params = utils.createURL([{ name: 'user_id', values: id }]);

  return {
    type: 'UPDATE_USER',
    payload: axios.patch(`${getApiRoot()}/users${params}`, {
      first_name: firstName,
      last_name: lastName,
      email,
      admin,
    }),
  };
}

export function register({
  firstName,
  lastName,
  email,
  otherCompany,
  password,
  parentName,
  parentId,
}: {
  firstName: string;
  lastName: string;
  email: string;
  otherCompany: string;
  password: string;
  parentName: string;
  parentId: number;
}) {
  return {
    type: 'REGISTER',
    payload: axios.post(`${getApiRoot()}/users/register`, {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      parent_id: parentId || '',
      parent_name: parentName,
      ...(otherCompany ? { other_company: otherCompany } : {}),
    }),
  };
}

export function registerReceiver({
  firstName,
  lastName,
  email,
  password,
  receiverName,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  receiverName: string;
}) {
  return {
    type: 'REGISTER',
    payload: axios.post(`${getApiRoot()}/users/receiver_register`, {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      receiver_name: receiverName,
    }),
  };
}

export function logout() {
  localStorage.removeItem('paramount-token');
  localStorage.removeItem('goOnlyRedirected');
  if ((window as any).Intercom) (window as any).Intercom('shutdown');

  return {
    type: 'LOGOUT',
    payload: axios.post(`${getApiRoot()}/users/logout`),
  };
}

export function loadUserByToken(apiRedirect?: boolean) {
  const token = localStorage.getItem('paramount-token')
    ? JSON.parse(localStorage.getItem('paramount-token')!)
    : '';
  const config = {
    headers: { 'Paramount-Access-Token': token },
  };

  return (dispatch: AsyncDispatch) => {
    dispatch(resetRedux());
    return dispatch({
      type: 'LOAD_USER_BY_TOKEN',
      payload: axios.get(`${getApiRoot()}/users`, config),
      meta: { apiRedirect },
    });
  };
}

export function loadUserByOktaToken(token: string) {
  const params = utils.createURL([{ name: 'sso_token', values: token }]);

  return (dispatch: AsyncDispatch) => {
    dispatch(resetRedux());
    dispatch({
      type: 'LOAD_USER_BY_TOKEN',
      payload: axios.get(`${getApiRoot()}/sso/authenticate${params}`),
    }).then(res => {
      const token = res.value.data.token;
      localStorage.setItem('paramount-token', JSON.stringify(token));
    });
  };
}

export function updateAccountInformations(
  userId: number,
  emailNotification: number,
  firstName: string,
  lastName: string,
  email?: string
) {
  const params = utils.createURL([{ name: 'user_id', values: userId }]);

  const body = {
    email_notification: emailNotification,
    first_name: firstName,
    last_name: lastName,
    email,
  };

  return {
    type: 'UPDATE_ACCOUNT_INFORMATIONS',
    payload: axios.patch(`${getApiRoot()}/users${params}`, body),
  };
}

export function resetUserPassword(email: string) {
  return {
    type: 'UPDATE_USER_STATE',
    payload: axios.post(`${getApiRoot()}/users/reset_password`, { email }),
  };
}

export function forgotPassword(email: string) {
  return {
    type: 'FORGOT_PASSWORD',
    payload: axios.post(`${getApiRoot()}/users/reset_password`, { email }),
  };
}

export function resetPassword(resetToken: string, newPassword: string) {
  return {
    type: 'RESET_PASSWORD',
    payload: axios.patch(`${getApiRoot()}/users/reset_password`, {
      reset_token: resetToken,
      new_password: newPassword,
    }),
  };
}

export function updatePassword(currentPw: string, newPw: string) {
  const body = {
    current_password: currentPw,
    new_password: newPw,
  };

  return {
    type: 'UPDATE_PASSWORD',
    payload: axios.patch(`${getApiRoot()}/users/password`, body),
  };
}

export function updateUserState(userId: number, active: number) {
  const params = utils.createURL([{ name: 'user_id', values: userId }]);

  return {
    type: 'UPDATE_USER_STATE',
    payload: axios.patch(`${getApiRoot()}/users${params}`, { active }),
  };
}

export function fetchPermissions() {
  return {
    type: 'FETCH_PERMISSIONS',
    payload: axios.get(`${getApiRoot()}/accounts/permissions`),
  };
}

export function fetchRoles() {
  return {
    type: 'FETCH_ROLES',
    payload: axios.get(`${getApiRoot()}/user_roles`),
  };
}

export function fetchMappedUserRoles(userId: number) {
  const params = utils.createURL([{ name: 'user_id', values: userId }]);

  return {
    type: 'FETCH_MAPPED_USER_ROLES',
    payload: axios.get(`${getApiRoot()}/users${params}`),
  };
}

export const deleteUserRole = (id: number) => ({
  type: 'DELETE_USER_ROLE',
  payload: axios.delete(`${getApiRoot()}/user_roles/${id}`),
  meta: { roleId: id },
});

export function updateUserRole(roles: any) {
  const body = { user_roles: [roles] };

  return {
    type: 'UPDATE_USER_ROLE',
    payload: axios.patch(`${getApiRoot()}/user_roles`, body),
  };
}

export function updateMappedUserPermissions(user: any) {
  const body = {
    users: [user],
  };

  return {
    type: 'UPDATE_MAPPED_USER_PERMISSIONS',
    payload: axios.patch(`${getApiRoot()}/accounts/users`, body),
  };
}
