import {
  FormOutlined,
  GlobalOutlined,
  InteractionOutlined,
  LogoutOutlined,
  SettingOutlined,
  TrademarkOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Badge, Tooltip, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import queryString from 'query-string';

import * as actions from '../../actions/app/global';
import { hasPermission } from '../../utils/Permissions';
import { isAllFilter } from '../../utils/FilterUtils';
import { isManufacturer, isReceiver, isAdmin } from '../../utils/UserUtils';
import { createBrandTitle } from '../../utils/String';
import {
  HOME_AREA,
  ACCOUNT_AREA,
  COMPANY_AREA,
  AVAILABLE_BRAND_AREA,
  BRAND_AREA,
  CHANNEL_AREA,
} from '../../constants/InAppAreaConst';
import { devApiRoot, qaApiRoot } from '../../constants/ApiRoots.json';
import { activeBrands } from '../../selectors/brand/brandSelector';
import { AsyncDispatch } from '../../../types/global';
import { ApplicationState } from '../../reducers';
import { AnalysisTypeCount } from '../../../types/analyses';
import { typingDone } from '../../utils/Utils';
import AntTooltip from '../global/AntTooltip';

const { Sider } = Layout;
const { SubMenu } = Menu;

type SidebarLeftProps = {
  logout: () => void;
};

const SideBarLeft: React.FC<SidebarLeftProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    mainMenuCollapsed,
    brands,
    selectedBrandId,
    outOfSyncItemsCount,
    receiverRequestCount,
    brandRequestCount,
    analysesCount,
    user,
    selectedFilterGo,
  } = useSelector((state: ApplicationState) => {
    return {
      mainMenuCollapsed: state.app.global.mainMenuCollapsed,
      brands: activeBrands(state),
      selectedBrandId: state.parent.brands.selectedBrandId,
      outOfSyncItemsCount: state.brand.synchronisation.outOfSyncItemsCount,
      receiverRequestCount: state.receiver.receivers.receiverRequestCount,
      brandRequestCount: state.receiverDataStream.brandRequestCount,
      analysesCount: state.brand.analyses.analysesCount as AnalysisTypeCount[],
      user: state.user.user,
      selectedFilterGo: state.catalogue.filter.filterGo,
    };
  });

  const [selectedKey, setSelectedKey] = React.useState<string[]>([]);
  const [openKey, setOpenKey] = React.useState<string[]>([]);
  const [keywords, setKeywords] = React.useState<string>('');
  const [filteredBrands, setFilteredBrands] = React.useState(
    brands.length > 100 ? brands.slice(0, 100) : brands
  );

  const manufacturer = useMemo(() => isManufacturer(user!), [user]);
  const receiver = useMemo(() => isReceiver(user!), [user]);
  const admin = useMemo(() => isAdmin(user!), [user]);
  const hasAvailableBrandAccess = useMemo(
    () => hasPermission(user, 'has_available_brand_access'),
    [user]
  );
  const hasBrandAccess = useMemo(() => hasPermission(user, 'has_brand_access'), [user]);
  const hasAllApplicationsAccess = useMemo(
    () => hasPermission(user, 'has_all_applications_access'),
    [user]
  );
  const hasCompanyAccess = useMemo(() => hasPermission(user, 'has_company_access'), [user]);
  const hasReceiverAccess = useMemo(() => hasPermission(user, 'has_receiver_access'), [user]);
  const canAccessUsers = useMemo(
    () => hasPermission(user, 'can_access_users') || hasPermission(user, 'can_manage_users'),
    [user]
  );
  const canAccessPublishProducts = useMemo(
    () => hasPermission(user, 'can_access_publish_products'),
    [user]
  );
  const hasApiAccess = useMemo(() => hasPermission(user, 'has_api_access'), [user]);
  const canAccessTranslations = useMemo(
    () => hasPermission(user, 'can_access_translation'),
    [user]
  );
  const canAccessCustomVcdb = useMemo(() => hasPermission(user, 'can_access_custom_vcdb'), [user]);
  const canManageExternalResources = useMemo(
    () => hasPermission(user, 'can_manage_external_sources'),
    [user]
  );
  const canAccessAttributeSettings = useMemo(
    () => hasPermission(user, 'can_access_category_part_attribute_relations'),
    [user]
  );
  const canManageCustomFields = useMemo(
    () => hasPermission(user, 'can_manage_custom_fields'),
    [user]
  );

  React.useEffect(() => {
    setSideBarKeys();
  }, []);

  React.useEffect(() => {
    setInAppArea();
  }, []);

  React.useEffect(() => {
    setFilteredBrands(brands.length > 100 ? brands.slice(0, 100) : brands);
  }, [brands]);

  const getInAppArea = useCallback(() => {
    const { url } = queryString.parseUrl(location.pathname);
    if (url === '/') {
      return HOME_AREA;
    }
    if (url.includes('/account')) {
      return ACCOUNT_AREA;
    }
    if (url.includes('/company')) {
      return COMPANY_AREA;
    }
    if (url.includes('/available-brands')) {
      return AVAILABLE_BRAND_AREA;
    }
    if (url.includes('/brand')) {
      return BRAND_AREA;
    }
    if (url.includes('/channel')) {
      return CHANNEL_AREA;
    }
  }, [location.pathname]);

  const setInAppArea = useCallback(() => {
    const area = getInAppArea();
    dispatch(actions.setInAppArea(area));
  }, [dispatch, getInAppArea]);

  const setSideBarKeys = useCallback(() => {
    const values = queryString.parse(location.search);
    const { brandId } = values;
    const splittedRoute = location.pathname.split('/');
    if (brandId && !splittedRoute.includes('available-brands')) {
      const indexBrand = splittedRoute.findIndex(el => el === 'brand');
      const subMenu = splittedRoute[indexBrand + 1];
      setOpenKey([`/brand?brandId=${brandId}`]);
      setSelectedKey([`/brand/${subMenu}/${brandId}`]);
    } else if (splittedRoute.includes('company')) {
      setOpenKey(['/company']);
      setSelectedKey([location.pathname]);
    } else if (splittedRoute.includes('available-brands')) {
      const indexBrand = splittedRoute.findIndex(el => el === 'available-brands');
      const subMenu = splittedRoute[indexBrand + 1];
      setOpenKey(['/available-brands']);
      setSelectedKey([`/available-brands/${subMenu}`]);
    } else if (splittedRoute.includes('distribution')) {
      setOpenKey(['/distribution']);
      setSelectedKey([location.pathname]);
    } else if (splittedRoute.includes('settings')) {
      setOpenKey(['/settings']);
      setSelectedKey([location.pathname]);
    } else {
      setOpenKey([location.pathname]);
      setSelectedKey([location.pathname]);
    }
  }, [location.pathname, location.search]);

  React.useEffect(() => {
    // check for routing update
    if (!mainMenuCollapsed) {
      setSideBarKeys();
    }
  }, [location, mainMenuCollapsed, setSideBarKeys]);

  React.useEffect(() => {
    setInAppArea();
  }, [location.pathname, setInAppArea]);

  const handleKeywordChange = (keywords: string) => {
    setKeywords(keywords);
    if (keywords)
      typingDone(() =>
        setFilteredBrands(
          brands.filter(b => b.name.toLowerCase().includes(keywords.toLocaleLowerCase()))
        )
      );
    else if (brands.length > 100) typingDone(() => setFilteredBrands(brands.slice(0, 100)), 50);
    else setFilteredBrands(brands);
  };

  const onCollapse = () => {
    const isCollapsed = !mainMenuCollapsed;

    dispatch(actions.setMenuState(isCollapsed));
    if (!isCollapsed) setSideBarKeys();
    setOpenKey(isCollapsed ? [] : openKey);
  };

  const handleOnSelect = (e: any) => {
    setSelectedKey(e.keyPath);
  };

  const handleOnOpenChange = (newOpenKey: any[]) => {
    const newSelection = newOpenKey.filter(key => !openKey.includes(key));
    if (mainMenuCollapsed) setOpenKey(newSelection);
    else if (newSelection.length) {
      const {
        url: main,
        query: { brandId },
      } = queryString.parseUrl(newSelection[0]);
      if (main === '/brand') {
        const brand = brands.find(b => b.id === Number(brandId));
        navigate({
          pathname: !brand?.exclude_from_validation ? '/brand/home' : '/brand/products/item',
          search: `?brandId=${brandId}`,
        });
      } else if (main === '/company') {
        navigate('/company/marketing-copy');
      } else if (main === '/available-brands') {
        navigate('/available-brands/all-brands');
      } else if (main === '/distribution') {
        navigate('/distribution/history');
      } else if (main === '/settings') {
        navigate('/settings/account');
      }
    }
  };

  const environmentMessage = (root: string) => {
    const dev = root === devApiRoot;
    const qa = root === qaApiRoot;

    return (
      <div className={classNames('env-hint', { qa, dev })}>
        {dev && t('header:dev')}
        {qa && t('header:qa')}
      </div>
    );
  };

  const getQuickFixCount = () => {
    const QUICK_FIX_ANALYSES_ID = [43, 91, 97, 210];
    const count = analysesCount
      .filter(obj => QUICK_FIX_ANALYSES_ID.includes(obj.type_id))
      .reduce((prev, key) => prev + key.item_count, 0);
    return count;
  };

  const userDetailsTitle = useMemo(() => {
    if (manufacturer)
      return `${user!.account_name} | ${t('sideBarLeft:parentId')} ${user!.parent_id} | ${t(
        'sideBarLeft:accountId'
      )} ${user!.account_id}`;
    if (receiver)
      return `${user!.account_name} | ${t('sideBarLeft:receiverId')} ${user!.receiver_id} | ${t(
        'sideBarLeft:accountId'
      )} ${user!.account_id}`;
  }, [manufacturer, receiver, t, user]);

  const root = process.env.REACT_APP_API_ROOT;
  const envVisible = root === devApiRoot || root === qaApiRoot;
  const beta = root?.includes('beta');
  const quickFixCount = useMemo<number>(getQuickFixCount, [analysesCount]);

  const brandMenu = useMemo(
    () =>
      !mainMenuCollapsed
        ? filteredBrands.map(brand => (
            <SubMenu
              key={`/brand?brandId=${brand.id}`}
              title={
                <Tooltip
                  title={createBrandTitle(
                    brand.name,
                    brand.code,
                    brand.default_whitelabel_brand_code,
                    receiver
                  )}
                  placement="right"
                  mouseEnterDelay={0.7}
                >
                  <span className="inline-block w-full">
                    <TrademarkOutlined />
                    <span>{brand.name}</span>
                  </span>
                </Tooltip>
              }
            >
              {!brand.exclude_from_validation && (
                <Menu.Item key={`/brand/home/${brand.id}`}>
                  <Link to={{ pathname: '/brand/home', search: `?brandId=${brand.id}` }}>
                    {t('sideBarLeft:home')}
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key={`/brand/products/${brand.id}`}>
                <Link
                  to={{
                    pathname: '/brand/products/item',
                    // To restore the selected filter in catalogue and show the correct url
                    // we need to pass here the filter id if the selected filter is not the allProductsFilter
                    search: `?brandId=${brand.id}${
                      selectedFilterGo?.id && !isAllFilter(selectedFilterGo)
                        ? `&filterId=${selectedFilterGo.id}`
                        : ''
                    }`,
                  }}
                >
                  {t('sideBarLeft:all_products')}
                </Link>
              </Menu.Item>
              {hasAllApplicationsAccess && (
                <Menu.Item key={`/brand/applications/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/applications',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    {t('sideBarLeft:all_applications')}
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key={`/brand/validation/${brand.id}`}>
                <Link
                  to={{
                    pathname: '/brand/validation/part-type',
                    search: `?brandId=${brand.id}`,
                  }}
                >
                  <div className="sidebar-menu__with-badge">
                    <span>{t('sideBarLeft:quick_fixes')}</span>
                    {brand.id === selectedBrandId && (
                      <Badge count={quickFixCount} className="badge-small" overflowCount={10000} />
                    )}
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key={`/brand/import-export/${brand.id}`}>
                <Link
                  to={{
                    pathname: '/brand/import-export/import',
                    search: `?brandId=${brand.id}`,
                  }}
                >
                  {t('sideBarLeft:import_export')}
                </Link>
              </Menu.Item>
              {canAccessPublishProducts && (
                <Menu.Item key={`/brand/publish-data/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/publish-data',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    <div className="sidebar-menu__with-badge">
                      <span>{t('sideBarLeft:sync')}</span>
                      {brand.id === selectedBrandId && (
                        <Badge
                          count={outOfSyncItemsCount}
                          className="badge-small"
                          overflowCount={10000}
                        />
                      )}
                    </div>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key={`/brand/settings/${brand.id}`}>
                <Link
                  to={{
                    pathname: '/brand/settings/custom-fields',
                    search: `?brandId=${brand.id}`,
                  }}
                >
                  {t('sideBarLeft:brand_settings')}
                </Link>
              </Menu.Item>
              <Menu.Item key={`/brand/retool/${brand.id}`}>
                <Link
                  to={{
                    pathname: '/brand/retool',
                    search: `?brandId=${brand.id}`,
                  }}
                >
                  {t('sideBarLeft:retool')}
                </Link>
              </Menu.Item>
            </SubMenu>
          ))
        : filteredBrands.map(brand => (
            <SubMenu
              key={`/brand?brandId=${brand.id}`}
              title={
                <span>
                  <TrademarkOutlined />
                  <span>{brand.name}</span>
                </span>
              }
            >
              <Menu.ItemGroup title={brand.name}>
                {!brand.exclude_from_validation && (
                  <Menu.Item key={`/brand/home/${brand.id}`}>
                    <Link to={{ pathname: '/brand/home', search: `?brandId=${brand.id}` }}>
                      {t('sideBarLeft:home')}
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key={`/brand/products/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/products/item',
                      // To restore the selected filter in catalogue and show the correct url
                      // we need to pass here the filter id if the selected filter is not the allProductsFilter
                      search: `?brandId=${brand.id}${
                        selectedFilterGo?.id && !isAllFilter(selectedFilterGo)
                          ? `&filterId=${selectedFilterGo.id}`
                          : ''
                      }`,
                    }}
                  >
                    <span>{t('sideBarLeft:all_products')}</span>
                  </Link>
                </Menu.Item>
                {hasAllApplicationsAccess && (
                  <Menu.Item key={`/brand/applications/${brand.id}`}>
                    <Link
                      to={{
                        pathname: '/brand/applications',
                        search: `?brandId=${brand.id}`,
                      }}
                    >
                      {t('sideBarLeft:all_applications')}
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key={`/brand/validation/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/validation/part-type',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    <div className="sidebar-menu__with-badge">
                      <span>{t('sideBarLeft:quick_fixes')}</span>
                      {brand.id === selectedBrandId && (
                        <Badge
                          count={quickFixCount}
                          className="badge-small"
                          overflowCount={10000}
                        />
                      )}
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/brand/import-export/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/import-export/import',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    {t('sideBarLeft:import_export')}
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/brand/publish-data/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/publish-data',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    <div className="sidebar-menu__with-badge">
                      <span>{t('sideBarLeft:sync')}</span>
                      {brand.id === selectedBrandId && (
                        <Badge
                          count={outOfSyncItemsCount}
                          className="badge-small"
                          overflowCount={10000}
                        />
                      )}
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/brand/settings/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/settings/custom-fields',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    {t('sideBarLeft:brand_settings')}
                  </Link>
                </Menu.Item>
                <Menu.Item key={`/brand/retool/${brand.id}`}>
                  <Link
                    to={{
                      pathname: '/brand/retool',
                      search: `?brandId=${brand.id}`,
                    }}
                  >
                    {t('sideBarLeft:retool')}
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          )),
    [
      canAccessPublishProducts,
      filteredBrands,
      hasAllApplicationsAccess,
      mainMenuCollapsed,
      outOfSyncItemsCount,
      quickFixCount,
      receiver,
      selectedBrandId,
      selectedFilterGo,
      t,
    ]
  );

  return (
    <React.Fragment>
      <Sider
        className={classNames('main-navigation', 'sidebar-left', {
          'main-navigation-collapsed': mainMenuCollapsed,
        })}
        collapsible
        collapsed={mainMenuCollapsed}
        trigger={
          <div className="main-navigation__bottom" onClick={e => e.stopPropagation()}>
            {/* <div className={classNames('help-icon-container', { collapsed: mainMenuCollapsed })}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.pdmautomotive.com/en"
                >
                  <Tooltip title={t('sideBarLeft:support')}>
                    <img src={helpIcon} className="help-icon" alt="support" />
                  </Tooltip>
                </a>
              </div> */}
          </div>
        }
      >
        <div className={classNames('main-navigation__header', { small: mainMenuCollapsed })}>
          {!mainMenuCollapsed && !envVisible && (
            <div className={classNames(beta ? 'logo-beta' : 'logo')} />
          )}
          {!mainMenuCollapsed && envVisible && root && environmentMessage(root)}
          {mainMenuCollapsed ? (
            <MenuUnfoldOutlined
              className={classNames('ant-menu-dark trigger', {
                qa: root === qaApiRoot,
                dev: root === devApiRoot,
              })}
              onClick={onCollapse}
            />
          ) : (
            <MenuFoldOutlined
              className={classNames('ant-menu-dark trigger', {
                qa: root === qaApiRoot,
                dev: root === devApiRoot,
              })}
              onClick={onCollapse}
            />
          )}
        </div>
        <Menu
          theme="dark"
          selectedKeys={selectedKey}
          openKeys={openKey}
          mode="inline"
          onSelect={handleOnSelect}
          onOpenChange={handleOnOpenChange}
        >
          {/* <Menu.Item key="/">
            <Link to="/">
              <Icon type="dashboard" />
              <span>Dashboard</span>
            </Link>
          </Menu.Item> */}
          {hasAvailableBrandAccess && (
            <SubMenu
              title={
                <span>
                  <FormOutlined />
                  <span>{t('sideBarLeft:available_brands')}</span>
                </span>
              }
              key="/available-brands"
            >
              <Menu.Item key="/available-brands/all-brands">
                <Link to={{ pathname: '/available-brands/all-brands' }}>
                  <div className="sidebar-menu__with-badge">
                    <span>{t('sideBarLeft:all_brands')}</span>
                    {receiver && (
                      <Badge count={brandRequestCount} className="badge-small" overflowCount={99} />
                    )}
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item key="/available-brands/all-products">
                <Link to={{ pathname: '/available-brands/all-products/item' }}>
                  {t('sideBarLeft:all_products')}
                </Link>
              </Menu.Item>
              {canManageExternalResources && (
                <Menu.Item key="/available-brands/external-sources">
                  <Link to={{ pathname: '/available-brands/external-sources/sdc' }}>
                    {t('sideBarLeft:external_sources')}
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="/available-brands/export">
                <Link to={{ pathname: '/available-brands/export/export-data' }}>
                  {receiver && canManageCustomFields
                    ? t('sideBarLeft:import_export')
                    : t('sideBarLeft:export')}
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {brands.length > 20 && (
            <div className="sidebar__brand-search-wrapper">
              <AntTooltip
                title={t('sideBarLeft:brandLimitInfo')}
                mouseEnterDelay={0.7}
                placement="right"
              >
                <Input
                  className="sidebar__brand-search"
                  value={keywords}
                  onChange={e => handleKeywordChange(e.target.value)}
                  placeholder={t('sideBarLeft:brandSearch')}
                  suffix={<SearchOutlined />}
                  allowClear
                />
              </AntTooltip>
            </div>
          )}
          {hasBrandAccess && brands.length > 0 && brandMenu}
          {hasCompanyAccess && (
            <SubMenu
              key="/company"
              title={
                <span>
                  <GlobalOutlined />
                  <span>{t('sideBarLeft:company')}</span>
                </span>
              }
            >
              <Menu.Item key="/company/marketing-copy">
                <Link to="/company/marketing-copy">{t('sideBarLeft:marketing_copy')}</Link>
              </Menu.Item>
              <Menu.Item key="/company/digital-assets">
                <Link to="/company/digital-assets">{t('sideBarLeft:digital_assets')}</Link>
              </Menu.Item>
              <Menu.Item key="/company/insights">
                <Link to="/company/insights">{t('sideBarLeft:insights')}</Link>
              </Menu.Item>
              <Menu.Item key="/company/retool">
                <Link to="/company/retool">{t('sideBarLeft:retool')}</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu
            key="/distribution"
            title={
              <div className="sidebar-menu__with-badge">
                <span>
                  <InteractionOutlined />
                  <span>{t('sideBarLeft:distribution')}</span>
                </span>
                {manufacturer && (
                  <Badge count={receiverRequestCount} className="badge-small" overflowCount={99} />
                )}
              </div>
            }
          >
            <Menu.Item key="/distribution/history">
              <Link to="/distribution/history">{t('sideBarLeft:distribution_history')}</Link>
            </Menu.Item>
            <Menu.Item key="/distribution/channels">
              <Link to="/distribution/channels">{t('sideBarLeft:channels')}</Link>
            </Menu.Item>
            {hasReceiverAccess && (
              <Menu.Item key="/distribution/receiver">
                <Link to="/distribution/receiver">
                  <div className="sidebar-menu__with-badge">
                    <span>{t('sideBarLeft:receiver')}</span>
                    <Badge
                      count={receiverRequestCount}
                      className="badge-small"
                      overflowCount={10000}
                    />
                  </div>
                </Link>
              </Menu.Item>
            )}
            {hasApiAccess && (
              <Menu.Item
                key={(location.pathname.includes('/distribution/api') && location.pathname) || ''}
              >
                <Link to="/distribution/api/api-settings">{t('sideBarLeft:api')}</Link>
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu
            key="/settings"
            title={
              <span>
                <SettingOutlined />
                <Tooltip title={userDetailsTitle} placement="right">
                  <span>{t('sideBarLeft:settings')}</span>
                </Tooltip>
              </span>
            }
          >
            <Menu.Item key="/settings/account">
              <Link to="/settings/account">
                <span>{t('sideBarLeft:account')}</span>
              </Link>
            </Menu.Item>
            {manufacturer && (
              <Menu.Item key="/settings/brands">
                <Link to="/settings/brands">{t('sideBarLeft:brands')}</Link>
              </Menu.Item>
            )}
            {receiver && (
              <Menu.Item key="/settings/company-profile">
                <Link to="/settings/company-profile">{t('sideBarLeft:companyProfile')}</Link>
              </Menu.Item>
            )}
            {admin && canAccessUsers && (
              <Menu.Item key="/settings/user">
                <Link to="/settings/user">{t('sideBarLeft:user')}</Link>
              </Menu.Item>
            )}
            {canAccessAttributeSettings && (
              <Menu.Item
                key={
                  (location.pathname.includes('/settings/attributes') && location.pathname) || ''
                }
              >
                <Link to="/settings/attributes">{t('sideBarLeft:attributes')}</Link>
              </Menu.Item>
            )}
            {canAccessTranslations && (
              <Menu.Item
                key={
                  (location.pathname.includes('/settings/translations') && location.pathname) ||
                  '/settings/translations/language-settings'
                }
              >
                <Link to="/settings/translations/language-settings">
                  {t('sideBarLeft:translations')}
                </Link>
              </Menu.Item>
            )}
            {canAccessCustomVcdb && (
              <Menu.Item
                key={
                  (location.pathname.includes('/settings/custom-vcdb') && location.pathname) ||
                  '/settings/custom-vcdb/custom-vcdb-table'
                }
              >
                <Link to="/settings/custom-vcdb/custom-vcdb-table">
                  {t('sideBarLeft:customVehicleTables')}
                </Link>
              </Menu.Item>
            )}
            {receiver && canManageCustomFields && (
              <Menu.Item key="/settings/custom-fields">
                <Link to="/settings/custom-fields">{t('sideBarLeft:custom_fields')}</Link>
              </Menu.Item>
            )}
            {receiver && (
              <Menu.Item key="/settings/retool">
                <Link to="/settings/retool">{t('sideBarLeft:retool')}</Link>
              </Menu.Item>
            )}
          </SubMenu>
          <Menu.Item key="/logout">
            <Link to="/" onClick={props.logout}>
              <LogoutOutlined />
              <span>{t('sideBarLeft:logout')}</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </React.Fragment>
  );
};

export default SideBarLeft;
