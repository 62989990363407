import { Form, message, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Formik, FormikHelpers } from 'formik';
import { isEqual as _isEqual, get as _get } from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Account, AccountInfo } from '../../../../types/account';
import SimpleDivider from '../../global/SimpleDivider';
import FormButtonGroup from '../../global/Forms/FormButtonGroup';
import FormInput from '../../global/Forms/FormInput';
import FormSwitch from '../../global/Forms/FormSwitch';
import { booleanToInt } from '../../../utils/Utils';

type PasswordUpdate = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
};

type SettingsProps = {
  account: Account;
  updateAccountInformation: (acc: Account) => Promise<void>;
  updatePassword: (currentPw: string, oldPw: string) => Promise<any>;
  accountInfo: AccountInfo;
} & WithTranslation;

class Settings extends React.Component<SettingsProps> {
  accountSchema = () => {
    const { t } = this.props;
    return Yup.object({
      firstName: Yup.string().matches(/^[^0-9]+$/, t('validation:validateNoNumbers')),
      lastName: Yup.string().matches(/^[^0-9]+$/, t('validation:validateNoNumbers')),
      email: Yup.string().required(t('validation:required')).email(t('validation:invalidEmail')),
    });
  };

  passwordSchema = () => {
    const { t } = this.props;

    return Yup.object({
      currentPassword: Yup.string().required(t('validation:required')),
      password: Yup.string()
        .min(6, t('validation:passwordLength'))
        .required(t('validation:required')),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password')], t('validation:passwordsDontMatch'))
        .required(t('validation:required')),
    });
  };

  updateAccountInformations = (values: Account, formikActions: FormikHelpers<Account>) => {
    const { resetForm, setSubmitting } = formikActions;
    const { t } = this.props;
    return this.props
      .updateAccountInformation(values)
      .then(() => {
        message.success(t('accountSettings:messages.settings_changed'));
        resetForm({ values });
      })
      .catch(error => {
        message.error(_get(error, 'response.data.message') || t('validation:unknownApiError'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  updatePassword = (values: PasswordUpdate, formikActions: FormikHelpers<PasswordUpdate>) => {
    const { currentPassword, password, passwordConfirm } = values;
    const { t } = this.props;
    const { resetForm, setSubmitting } = formikActions;
    if (password === passwordConfirm) {
      return this.props
        .updatePassword(currentPassword, password)
        .then(() => message.success(t('accountSettings:messages.password_changed')))
        .catch(error => {
          message.error(_get(error, 'response.data.message') || t('validation:unknownApiError'));
        })
        .finally(() => {
          resetForm();
          setSubmitting(false);
        });
    }
  };

  accountDetails = (name: string, value: string | number | null) => (
    <div className="flex">
      <div className="text-sm ant-text-black w-32">{name}</div>
      <div className="flex-1">{value}</div>
    </div>
  );

  render() {
    const { account, t, accountInfo } = this.props;
    return (
      <div className="account h-full overflow-y-auto">
        <SimpleDivider title={t('accountSettings:form.updateAccount')} />
        <Formik
          initialValues={{
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            emailNotification: account.emailNotification || 0,
          }}
          validationSchema={this.accountSchema}
          onSubmit={(values, actions) => this.updateAccountInformations(values, actions)}
        >
          {props => {
            const { handleSubmit, resetForm, setFieldValue, isSubmitting, dirty } = props;
            return (
              <Form className="account-form" layout="vertical">
                <FormInput name="firstName" label={t('accountSettings:form.firstName')} />
                <FormInput name="lastName" label={t('accountSettings:form.lastName')} />
                <FormInput
                  name="email"
                  type="email"
                  label={t('accountSettings:form.email')}
                  required
                />

                <div className="inline-block">
                  <span className="mr-1">{t('accountSettings:form.sendEmailNotification')}</span>
                  <Tooltip title={t('accountSettings:form.emailNotificationInfoText')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <div className="inline-block ml-4">
                  <FormSwitch
                    name="emailNotification"
                    handleChange={(checked: boolean) => {
                      setFieldValue('emailNotification', booleanToInt(checked));
                    }}
                    checkedChildren={t('common:yes')}
                    unCheckedChildren={t('common:no')}
                  />
                </div>

                <FormButtonGroup
                  disabled={!dirty}
                  onSave={handleSubmit}
                  onCancel={resetForm}
                  loading={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
        <SimpleDivider title={t('accountSettings:form.updateSecurity')} />
        <Formik
          initialValues={{ currentPassword: '', password: '', passwordConfirm: '' }}
          validationSchema={this.passwordSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            return this.updatePassword(values, actions);
          }}
        >
          {props => {
            const { handleSubmit, isSubmitting, values, resetForm, initialValues } = props;
            return (
              <Form className="account-form" layout="vertical">
                <FormInput
                  name="currentPassword"
                  type="password"
                  label={t('accountSettings:form.currentPassword')}
                />
                <FormInput
                  name="password"
                  type="password"
                  label={t('accountSettings:form.password')}
                />
                <FormInput
                  name="passwordConfirm"
                  type="password"
                  label={t('accountSettings:form.confirmPassword')}
                  required
                />
                <FormButtonGroup
                  disabled={_isEqual(values, initialValues)}
                  onSave={handleSubmit}
                  onCancel={resetForm}
                  loading={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
        <SimpleDivider title={t('accountSettings:accountDetails')} />
        {this.accountDetails(t('accountSettings:accountName'), accountInfo.accountName)}
        {this.accountDetails(
          accountInfo.isManufacturer ? t('sideBarLeft:parentId') : t('sideBarLeft:receiverId'),
          accountInfo.userId
        )}
        {this.accountDetails(t('sideBarLeft:accountId'), accountInfo.accountId)}
      </div>
    );
  }
}

export default withTranslation()(Settings);
