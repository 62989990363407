import { Button, Input, Modal } from 'antd';
import { FieldArray } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Brand } from '../../../../../types/brand';
import { AsyncDispatch } from '../../../../../types/global';
import { updateBrands, resetBrand } from '../../../../actions/parent/brands/update';
import { ApplicationState } from '../../../../reducers';
import { intercomEvent } from '../../../../utils/IntercomUtils';
import { hasPermission } from '../../../../utils/Permissions';
import PageFormik from '../../../global/page/PageFormik';
import BrandManagingTable from './BrandManagingTable';
import BrandResetModal from './BrandResetModal';

const BrandManagingPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [searchKeywords, setSearchKeywords] = React.useState<string>('');
  const [selectedResetBrandCode, setSelectedResetBrandCode] = React.useState<string>('');

  const { brands, user } = useSelector((state: ApplicationState) => {
    return {
      brands: state.parent.brands.brands,
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-company-brand-settings');
  }, []);

  const canManageBrandSettings = hasPermission(user, 'can_manage_brand_settings');

  const handleBrandReset = (brandId: number) => {
    const brand = brands.find(b => b.id === brandId);

    setSelectedResetBrandCode(brand!.code);

    intercomEvent('viewed-company-brand-settings', { action: 'reset' });
  };

  const resetSelectedBrand = () => {
    const brand = brands.find(b => b.code === selectedResetBrandCode);

    return dispatch(resetBrand(brand!.id)).then(() => setSelectedResetBrandCode(''));
  };

  const validationSchema = Yup.object().shape({
    brands: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('validation:required')),
        default_whitelabel_brand_code: Yup.string()
          .nullable()
          .max(5, t('validation:maxLength', { length: 5 })),
      })
    ),
  });

  return (
    <PageFormik
      initialValues={{ brands }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitError, setSubmitting, setStatus, resetForm }) => {
        setSubmitting(true);
        intercomEvent('viewed-company-brand-settings', { action: 'saved' });

        dispatch(updateBrands(values.brands))
          .then(result => {
            const brandList: Brand[] = result.value.data;
            resetForm({
              values: {
                brands: values.brands.map((b: Brand) =>
                  brandList.find(brand => (b.id ? brand.id === b.id : brand.name === b.name))
                ),
              },
            });
            setStatus('SUCCESS');
            setTimeout(() => setStatus('EDIT'), 2500);
          })
          .catch(() => setSubmitError());
      }}
      validateOnChange={false}
      contentNoScroll
      contentNoSpacing
    >
      {() => {
        return (
          <FieldArray name="brands">
            {({ unshift }) => {
              const addBrand = () => {
                intercomEvent('viewed-company-brand-settings', { action: 'add_new' });

                Modal.confirm({
                  title: t('brandManaging:addNewBrand'),
                  content: t('brandManaging:addBrandDesc'),
                  okText: t('brandManaging:addBrand'),
                  onOk() {
                    unshift({
                      name: '',
                      oe_brand: 0,
                      default_whitelabel_brand_code: null,
                      view_product_option_id: 4,
                      active: 1,
                      original_brand: 0,
                      new: true,
                    });

                    setTimeout(() => {
                      const inputs = document.getElementsByClassName('brand-managing__name-input');
                      if (inputs.length) {
                        const input = inputs
                          .item(0)
                          ?.getElementsByTagName('input')
                          .item(0) as HTMLElement;
                        setTimeout(() => {
                          if (input) input.focus();
                        }, 10);
                      }
                    }, 500);
                  },
                });
              };

              return (
                <div className="page-layout">
                  <div className="page-layout__top-bar">
                    <div className="page-layout__top-bar__container">
                      <Input
                        className="max-w-xl mr-auto"
                        value={searchKeywords}
                        onChange={e => setSearchKeywords(e.target.value)}
                        placeholder={t('brandManaging:searchBrand')}
                        allowClear
                        size="small"
                      />
                      {canManageBrandSettings && (
                        <Button onClick={addBrand} type="primary" size="small">
                          {t('brandManaging:addNewBrand')}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="page-layout__content flex">
                    <BrandManagingTable
                      keywords={searchKeywords}
                      canManageBrandSettings={canManageBrandSettings}
                      handleBrandReset={handleBrandReset}
                    />
                  </div>

                  <BrandResetModal
                    showModal={!!selectedResetBrandCode}
                    internalBrandCode={selectedResetBrandCode}
                    onCancel={() => setSelectedResetBrandCode('')}
                    resetBrand={resetSelectedBrand}
                  />
                </div>
              );
            }}
          </FieldArray>
        );
      }}
    </PageFormik>
  );
};

export default BrandManagingPage;
