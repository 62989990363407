import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchReceiverImportLogs(limit = 200) {
  const params = utils.createURL([{ name: 'limit', values: limit }]);
  return {
    type: 'FETCH_RECEIVER_IMPORT_LOGS',
    payload: axios.get(`${getApiRoot()}/imports/logs${params}`),
  };
}
