const initialState = {
  validatedAnalysisTypeIds: [],
  filterAnalysesResources: {
    selectedSegmentId: undefined,
    selectedAlertTypeId: undefined,
    selectedScorecardId: undefined,
  },
  updateAnalysesProgressIsRunning: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_VALIDATED_ANALYSIS_TYPES_FULFILLED':
      return { ...state, validatedAnalysisTypeIds: action.payload.data };
    case 'CREATE_ANALYSIS_TYPE_FOR_BRAND_PENDING': {
      return {
        ...state,
        validatedAnalysisTypeIds: [
          ...state.validatedAnalysisTypeIds,
          ...action.meta.analysisTypeIds,
        ],
      };
    }
    case 'CREATE_ANALYSIS_TYPE_FOR_BRAND_REJECTED': {
      return {
        ...state,
        validatedAnalysisTypeIds: state.validatedAnalysisTypeIds.filter(
          typeId => !action.meta.analysisTypeIds.includes(typeId)
        ),
      };
    }
    case 'UPDATE_SELECTED_SEGMENT_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedSegmentId:
            state.filterAnalysesResources.selectedSegmentId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'UPDATE_SELECTED_ALERT_TYPE_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedAlertTypeId:
            state.filterAnalysesResources.selectedAlertTypeId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'UPDATE_SELECTED_SCORECARD_ID': {
      return {
        ...state,
        filterAnalysesResources: {
          ...state.filterAnalysesResources,
          selectedScorecardId:
            state.filterAnalysesResources.selectedScorecardId === action.payload
              ? null
              : action.payload,
        },
      };
    }
    case 'UPDATE_ANALYSES_PROGRESS_PENDING': {
      return {
        ...state,
        updateAnalysesProgressIsRunning: true,
      };
    }
    case 'UPDATE_ANALYSES_PROGRESS_REJECTED': {
      return {
        ...state,
        updateAnalysesProgressIsRunning: false,
      };
    }
    case 'UPDATE_ANALYSES_PROGRESS_FULFILLED': {
      return {
        ...state,
        updateAnalysesProgressIsRunning: false,
      };
    }
    case 'DELETE_ANALYSIS_TYPE_FOR_BRAND_PENDING': {
      return {
        ...state,
        validatedAnalysisTypeIds: state.validatedAnalysisTypeIds.filter(
          typeId => !action.meta.analysisTypeIds.includes(typeId)
        ),
      };
    }
    case 'DELETE_ANALYSIS_TYPE_FOR_BRAND_REJECTED': {
      return {
        ...state,
        validatedAnalysisTypeIds: [
          ...state.validatedAnalysisTypeIds,
          ...action.meta.analysisTypeIds,
        ],
      };
    }
  }
  return state;
};

export default reducer;
