import axios from 'axios';
import * as utils from '../../utils/Utils';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import { convertConfigNameToIds } from '../../utils/ApplicationUtils';

const CancelToken = axios.CancelToken;
let fetchCancelToken: any;
let fetchCancelNPWToken: any;
let fetchCancelWalmartToken: any;
let fetchCancelShopifyToken: any;
let fetchCancelRankedApplicationToken: any;

export function fetchChannelStatus(userName: string) {
  const urlParams = utils.createURL([{ name: 'username', values: userName }]);
  return {
    type: 'FETCH_CHANNEL_STATUS',
    payload: axios.get(`${getApiRoot()}/channels/shopify_shops/status${urlParams}`),
  };
}

export function fetchShopifyRankedApplicationsByFilter(
  applicationConfigs: any,
  versionIds: number[],
  keywords?: string
) {
  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  if (fetchCancelRankedApplicationToken) fetchCancelRankedApplicationToken();

  const keys = Object.keys(applicationConfigs);
  const subConfigs = {};
  keys.forEach(key => {
    const configObj = applicationConfigs[key];
    const configIds = Object.keys(configObj).map(key => configObj[key].resource_id);

    // @ts-ignore
    subConfigs[convertConfigNameToIds(key)] = configIds;
  });

  return {
    type: 'FETCH_SHOPIFY_RANKED_APPLICATIONS_BY_FILTER',
    payload: axios.post(
      `${getApiRoot()}/v2/applications/ranks${urlParams}`,
      {
        sub_configs: subConfigs,
      },
      {
        cancelToken: new CancelToken(c => {
          fetchCancelRankedApplicationToken = c;
        }),
      }
    ),
  };
}

export const fetchShopifyChannelFilters = (channelId: number) => {
  const params = utils.createURL([{ name: 'channel_id', values: channelId }]);
  return {
    type: 'FETCH_SHOPIFY_VEHICLE_FILTERS',
    payload: axios.get(`${getApiRoot()}/channels/vehicle_filters${params}`),
  };
};

export const fetchAllShopifyChannels = () => {
  return {
    type: 'FETCH_ALL_SHOPIFY_CHANNELS',
    payload: axios.get(`${getApiRoot()}/channels/shopify`),
  };
};

export const fetchShopifyChannelBrands = (keywords?: string, channelId?: number) => {
  const params = utils.createURL([
    { name: 'keyword', values: keywords },
    { name: 'channel_id', values: channelId },
  ]);
  if (fetchCancelShopifyToken) fetchCancelShopifyToken();

  return {
    type: 'FETCH_CHANNEL_SHOPIFY',
    meta: { channelId },
    payload: axios.get(`${getApiRoot()}/channels/shopify${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelShopifyToken = c;
      }),
    }),
  };
};

export const fetchWalmartBrands = (keywords?: string) => {
  const params = utils.createURL([{ name: 'keyword', values: keywords }]);
  if (fetchCancelWalmartToken) fetchCancelWalmartToken();

  return {
    type: 'FETCH_CHANNEL_WALMART',
    payload: axios.get(`${getApiRoot()}/channels/walmart${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelWalmartToken = c;
      }),
    }),
  };
};

export const fetchChannelShopifyTemplate = (brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_CHANNEL_SHOPIFY_TEMPLATE',
    payload: axios.get(`${getApiRoot()}/channels/shopify/template${params}`),
  };
};

export const fetchChannelNPWTemplate = (brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_CHANNEL_NPW_TEMPLATE',
    payload: axios.get(`${getApiRoot()}/channels/npws/template${params}`),
  };
};

export const fetchNPWBrands = (keywords?: string) => {
  const params = utils.createURL([{ name: 'keyword', values: keywords }]);
  if (fetchCancelNPWToken) fetchCancelNPWToken();

  return {
    type: 'FETCH_CHANNEL_NPW',
    payload: axios.get(`${getApiRoot()}/channels/npws${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelNPWToken = c;
      }),
    }),
  };
};

export const fetchChannelAdvisorTemplate = (brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_CHANNEL_ADVISOR_TEMPLATE',
    payload: axios.get(`${getApiRoot()}/channels/channel_advisors/template${params}`),
  };
};

export const fetchChannelAdvisor = (keywords?: string) => {
  const params = utils.createURL([{ name: 'keyword', values: keywords }]);
  if (fetchCancelToken) fetchCancelToken();

  return {
    type: 'FETCH_CHANNEL_ADVISOR',
    payload: axios.get(`${getApiRoot()}/channels/channel_advisors${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
  };
};

export const fetchChannelOverview = () => ({
  type: 'FETCH_CHANNEL_OVERVIEW',
  payload: axios.get(`${getApiRoot()}/channels`),
});

export const fetchChannelItemsCount = () => {
  return {
    type: 'FETCH_CHANNEL_ITEMS_COUNT',
    payload: axios.get(`${getApiRoot()}/channels/items_count`),
  };
};

export const fetchAutorizedBrands = () => {
  return {
    type: 'FETCH_AUTHORIZED_BRANDS',
    payload: axios.get(`${getApiRoot()}/receivers/authorized_brands`),
  };
};

export const fetchEbayResources = () => {
  return {
    type: 'FETCH_EBAY_RESOURCES',
    payload: axios.get(`${getApiRoot()}/channels/ebay/resources`),
  };
};

export const fetchEbaySettings = (params?: { noPendingState?: boolean }) => {
  return {
    type: 'FETCH_EBAY_SETTINGS',
    meta: { fetchingConnectionState: params?.noPendingState },
    payload: axios.get(`${getApiRoot()}/channels/ebay_native/settings`),
  };
};

export const fetchEbayPolicies = () => {
  return {
    type: 'FETCH_EBAY_POLICIES',
    payload: axios.get(`${getApiRoot()}/ebay_native/policies`),
  };
};

export const fetchNamingConventions = () => {
  return {
    type: 'FETCH_NAMING_CONVENTIONS',
    payload: axios.get(`${getApiRoot()}/file_name_templates`),
  };
};

export default {
  fetchChannelOverview,
  fetchChannelItemsCount,
  fetchAutorizedBrands,
  fetchEbayResources,
  fetchEbaySettings,
  fetchEbayPolicies,
  fetchNamingConventions,
  fetchChannelAdvisor,
};
