import axios from 'axios';
import { AsyncAction } from 'redux-promise-middleware';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export const createReceiverRequest = ({
  receiverId,
  integrationTypeId,
  brandIds,
  selectedPriceSheetIds,
  selectedPriceTypeIds,
  withoutPrices,
  filterIds,
}: {
  receiverId: number;
  integrationTypeId: number | null;
  brandIds: number[];
  selectedPriceSheetIds: number[];
  selectedPriceTypeIds: number[];
  withoutPrices: boolean;
  filterIds: number[];
}): AsyncAction => {
  const urlParams = utils.createURL([
    { name: 'requesting_receiver_id', values: receiverId },
    { name: 'integration_type_id', values: integrationTypeId },
    { name: 'brand_ids', values: brandIds },
    { name: 'price_sheet_ids', values: selectedPriceSheetIds },
    { name: 'price_type_ids', values: selectedPriceTypeIds },
    { name: 'without_prices', values: withoutPrices ? 1 : 0 },
    { name: 'filter_ids', values: filterIds },
  ]);

  return {
    type: 'CREATE_RECEIVER_REQUESTS',
    meta: { receiverId },
    payload: axios.post(
      `${getApiRoot()}/parents/receivers/authorized_brands/request_receiver${urlParams}`
    ),
  };
};
