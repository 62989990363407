import axios from 'axios';
import { Dispatch } from 'redux';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { downloadFileFromUrl } from '../../shared/fetch';
import { triggerUpdateCounter } from '../../catalogue/updateManager/update';
import { fetchApplicationResources } from '../../items/application/fetch';
import { ApplicationState } from '../../../reducers';
import { fetchPartTypeAttributes } from '../attribute/fetch';

export function setFileTypeId(fileTypeId: number) {
  return {
    type: 'SELECT_LOGS_FILE_TYPE',
    meta: { fileTypeId },
  };
}

export function fetchParentImportLogs(fileTypeId: number) {
  const params = utils.createURL([
    { name: 'limit', values: 200 },
    { name: 'file_type_ids', values: fileTypeId },
  ]);
  return {
    type: 'FETCH_PARENT_IMPORT_LOGS',
    payload: axios.get(`${getApiRoot()}/imports/account_logs${params}`),
  };
}

export function fetchParentLogDetailsJSON(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'json' }]);
  return {
    type: 'FETCH_PARENT_IMPORT_LOG_DETAILS_JSON',
    payload: axios.get(`${getApiRoot()}/imports/logs/${logId}/errors${params}`),
  };
}

export function fetchParentImportLogsDetailsExcel(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'excel' }]);
  return {
    type: 'FETCH_PARENT_IMPORT_LOGS_DETAILS_EXCEL',
    payload: axios({
      method: 'get',
      url: `${getApiRoot()}/imports/logs/${logId}/errors${params}`,
      responseType: 'arraybuffer',
    }),
  };
}

export function fetchLatestParentImportLogs(userId: number) {
  const params = utils.createURL([
    { name: 'limit', values: 50 },
    { name: 'user_id', values: userId },
  ]);

  return {
    type: '@IMPORTER/FETCH_LATEST_PARENT_IMPORT_LOGS',
    payload: axios.get(`${getApiRoot()}/imports/account_logs${params}`),
  };
}

const refetchSettingsData = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    const categoryId = state.parent.attributeManagement.selectedCategory?.id;
    if (categoryId) dispatch(fetchPartTypeAttributes(categoryId));
  };
};

export function fetchRunningParentImportLogs(
  logIds: (number | null)[],
  vcdbImportLogId: number | null
) {
  return async (dispatch: any) => {
    const params = utils.createURL([{ name: 'import_log_ids', values: logIds.join(',') }]);

    const response = await axios.get(`${getApiRoot()}/imports/account_logs${params}`);
    const importCompleted = response.data.find((log: any) => log.completed_at);

    if (importCompleted) {
      if (vcdbImportLogId && importCompleted.id === vcdbImportLogId)
        dispatch(fetchApplicationResources());

      dispatch(triggerUpdateCounter());
      dispatch(refetchSettingsData());
    }
    dispatch({
      type: '@IMPORTER/FETCH_RUNNING_PARENT_IMPORT_LOGS_FULFILLED',
      payload: response,
    });
  };
}

export function fetchParentExportLogs(fileTypeId: number) {
  const params = utils.createURL([
    { name: 'limit', values: 200 },
    { name: 'file_type_ids', values: fileTypeId },
  ]);
  return {
    type: 'FETCH_PARENT_EXPORT_LOGS',
    payload: axios.get(`${getApiRoot()}/exports/account_logs${params}`),
  };
}

export function fetchParentExportLogDetailsExcel(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'excel' }]);
  return {
    type: 'FETCH_PARENT_EXPORT_LOG_DETAILS_EXCEL',
    payload: axios({
      method: 'get',
      url: `${getApiRoot()}/exports/logs/${logId}/errors${params}`,
      responseType: 'arraybuffer',
    }),
  };
}

export function fetchParentExportLogDetailsJson(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'json' }]);
  return {
    type: 'FETCH_PARENT_EXPORT_LOGS_DETAILS_JSON',
    payload: axios.get(`${getApiRoot()}/exports/logs/${logId}/errors${params}`),
    meta: { logId },
  };
}

export function fetchParentExport(logId: number) {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_PARENT_EXPORT',
      meta: { logId },
      payload: axios
        .get(`${getApiRoot()}/exports?export_log_id=${logId}`)
        .then(({ data: { file_link: fileUrl } }) => {
          dispatch(downloadFileFromUrl(fileUrl));
        }),
    });
  };
}

export function fetchParentImport(logId: number) {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_PARENT_IMPORT',
      meta: { logId },
      payload: axios
        .get(`${getApiRoot()}/imports?import_log_id=${logId}`)
        .then(({ data: { file_link: fileUrl } }) => {
          dispatch(downloadFileFromUrl(fileUrl));
        }),
    });
  };
}
