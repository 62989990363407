import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { ExpiNewContent } from '../../../../types/extendedInfo';

export function updateExtendedInfo(
  extended_informations: { item_id: number; types: { id: number; contents: ExpiNewContent[] }[] }[]
) {
  return {
    type: 'UPDATE_EXTENDED_INFORMATION',
    payload: axios.patch(`${getApiRoot()}/items/extended_informations`, {
      extended_informations,
    }),
  };
}
