import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { Modal, Button, Table, Checkbox } from 'antd';
import Column from 'antd/lib/table/Column';
import classNames from 'classnames';
import { SelectValue } from 'antd/lib/select';
import { DeleteOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { FormikValues, FieldArray, FormikProps } from 'formik';
import { ApplicationState } from '../../../../reducers';
import FormSelect from '../../../global/Forms/FormSelect';
import PageFormik, { CustomFormikActions } from '../../../global/page/PageFormik';
import {
  LanguageSetting,
  InitLanguageSetting,
  UpdateLanguageSetting,
} from '../../../../../types/language';
import { AsyncDispatch } from '../../../../../types/global';
import { updateAccountLanguages } from '../../../../actions/parent/language/update';
import { deleteAccountsLanguage } from '../../../../actions/parent/language/delete';
import { ExtendedResources } from '../../../../../types/resources';
import { hasPermission } from '../../../../utils/Permissions';
import { intercomEvent } from '../../../../utils/IntercomUtils';

const confirm = Modal.confirm;

const LanguageSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const { languages, userAccountLanguages, user } = useSelector((state: ApplicationState) => {
    return {
      languages: state.resources.data.global.languages,
      userAccountLanguages: state.parent.languageSettings.accountLanguages,
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    intercomEvent('viewed-company-translation-settings', { location: 'language' });
  }, []);

  const canManageTranslation = hasPermission(user, 'can_manage_translation');

  const getLanguage = (languages: ExtendedResources[], languageId: number) =>
    languages.find((language: ExtendedResources) => language.id === languageId);

  const getValidationSchema = () =>
    Yup.object().shape({
      accountLanguages: Yup.array().of(
        Yup.object().shape({
          languageId: Yup.number().nullable().required(t('validation:required')),
        })
      ),
    });

  const accountLanguages = userAccountLanguages.map(
    (existingLanguage: LanguageSetting, index: number) => {
      return {
        id: existingLanguage.id,
        languageId: existingLanguage.language_id || null,
        default: existingLanguage.default,
        codeId: getLanguage(languages, existingLanguage.language_id!)?.code,
        uniqueId: index,
      };
    }
  );

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError } = formikActions;

    intercomEvent('viewed-company-translation-settings', { location: 'language', action: 'save' });

    const accountLanguages: UpdateLanguageSetting[] = [];

    values.accountLanguages.forEach((value: InitLanguageSetting) => {
      if (value.countId) {
        accountLanguages.push({
          language_id: value.languageId,
          default: value.default,
        });
      } else {
        accountLanguages.push({
          id: value.id,
          language_id: value.languageId,
          default: value.default,
        });
      }
    });

    setSubmitPending();

    dispatch(updateAccountLanguages(accountLanguages))
      .then(() => setSubmitSuccess())
      .catch(() => setSubmitError());
  };

  return (
    <PageFormik
      enableReinitialize
      initialValues={{ accountLanguages }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      contentNoSpacing
      validationSchema={getValidationSchema()}
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canManageTranslation && formik.dirty
      }
    >
      {({ values, setFieldValue, resetForm, setValues }) => (
        <FieldArray
          name="accountLanguages"
          render={arrayHelpers => {
            return (
              <div className="page-layout">
                <div className="page-layout__top-bar">
                  <div className="page-layout__top-bar__container">
                    <div />
                    <div className="page-layout__top-bar__actions">
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          intercomEvent('viewed-company-translation-settings', {
                            location: 'language',
                            action: 'add',
                          });

                          const newRowData = {
                            uniqueId: values.accountLanguages.length,
                            countId: values.accountLanguages.length + 1,
                            languageId: null,
                            default: 0,
                          };
                          arrayHelpers.push(newRowData);
                        }}
                        data-testid="add-new-language"
                      >
                        {t('language:settings.addNewLanguage')}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="page-layout__content">
                  <AutoSizer>
                    {({ height, width }) => (
                      <div style={{ height, width }} className="language-settings-page">
                        <Table
                          size="small"
                          dataSource={values.accountLanguages}
                          pagination={false}
                          rowKey="uniqueId"
                          scroll={{ y: height - 40 }}
                        >
                          <Column
                            title={t('common:name')}
                            key={1}
                            render={(record: InitLanguageSetting) => {
                              const index = values.accountLanguages.indexOf(record);
                              return (
                                <FormSelect
                                  className={classNames('language-settings-page__select', {
                                    'border-invisible': !!values.accountLanguages[index].languageId,
                                  })}
                                  name={`accountLanguages[${index}].languageId`}
                                  values={languages}
                                  onChange={(value: SelectValue) => {
                                    setFieldValue(
                                      `accountLanguages[${index}].languageId`,
                                      value || null
                                    );
                                    setFieldValue(
                                      `accountLanguages[${index}].codeId`,
                                      getLanguage(languages, Number(value))?.code
                                    );
                                    if (values.accountLanguages.length === 1)
                                      setFieldValue(`accountLanguages[${index}].default`, 1);
                                  }}
                                  size="small"
                                  allowClear
                                  showSearch
                                  clearContainer
                                  testId="language"
                                />
                              );
                            }}
                            width={180}
                          />
                          <Column
                            title={t('language:settings.code')}
                            width={150}
                            render={(record: InitLanguageSetting) => {
                              const index = values.accountLanguages.indexOf(record);
                              return values.accountLanguages[index].codeId;
                            }}
                          />
                          <Column
                            title={t('language:settings.defaultLanguage')}
                            ellipsis
                            render={(record: InitLanguageSetting) => {
                              const index = values.accountLanguages.indexOf(record);
                              return (
                                <Checkbox
                                  data-testid="default"
                                  onChange={() => {
                                    values.accountLanguages.forEach(
                                      (value: InitLanguageSetting) => {
                                        const index = values.accountLanguages.indexOf(value);
                                        if (value.countId) {
                                          if (value.countId === record.countId)
                                            setFieldValue(`accountLanguages[${index}].default`, 1);
                                          else
                                            setFieldValue(`accountLanguages[${index}].default`, 0);
                                        } else if (value.id === record.id) {
                                          setFieldValue(`accountLanguages[${index}].default`, 1);
                                        } else
                                          setFieldValue(`accountLanguages[${index}].default`, 0);
                                      }
                                    );
                                  }}
                                  checked={values.accountLanguages[index].default === 1}
                                />
                              );
                            }}
                          />
                          <Column
                            render={(record: InitLanguageSetting) => {
                              const handleDeleteLanguage = () => {
                                intercomEvent('viewed-company-translation-settings', {
                                  location: 'language',
                                  action: 'delete',
                                });

                                confirm({
                                  title: t('language:settings.deleteLanguageInfo'),
                                  okText: t('common:delete'),
                                  okButtonProps: { danger: true },
                                  onOk() {
                                    if (record.id) {
                                      dispatch(deleteAccountsLanguage(record.id!)).then(() => {
                                        resetForm({
                                          values: {
                                            accountLanguages: accountLanguages.filter(
                                              a => a.id !== record.id
                                            ),
                                          },
                                        });
                                        setValues({
                                          accountLanguages: values.accountLanguages.filter(
                                            (a: LanguageSetting) => a.id !== record.id
                                          ),
                                        });
                                      });
                                    }
                                    if (record.countId) {
                                      const index = values.accountLanguages.indexOf(record);
                                      arrayHelpers.remove(index);
                                    }
                                  },
                                });
                              };
                              return (
                                <Button
                                  size="small"
                                  type="ghost"
                                  icon={<DeleteOutlined />}
                                  disabled={!canManageTranslation}
                                  danger
                                  onClick={handleDeleteLanguage}
                                  data-testid="delete"
                                >
                                  {t('common:delete')}
                                </Button>
                              );
                            }}
                            width={100}
                          />
                        </Table>
                      </div>
                    )}
                  </AutoSizer>
                </div>
              </div>
            );
          }}
        />
      )}
    </PageFormik>
  );
};

export default LanguageSettingsPage;
