import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormikProps, FormikValues } from 'formik';
import { Input, Select, Modal, message, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../../../reducers';
import PageFormik from '../../../global/page/PageFormik';
import { AsyncDispatch } from '../../../../../types/global';
import { LanguageTranslation } from '../../../../../types/language';
import { typingDone } from '../../../../utils/Utils';
import { fetchTranslations } from '../../../../actions/parent/language/fetch';
import { updateTranlations } from '../../../../actions/parent/language/update';
import { showUpgradePlanNotification } from '../../../../actions/app/modal';
import TranslationImportModal from './TranslationImportModal';
import TranslationTable from './TranslationTable';
import {
  mapTranslationsToLanguages,
  translationsInitValues,
} from '../../../../selectors/settings/settingsSelector';
import { notUndefined } from '../../../../utils/FilterUtils';
import { hasPermission } from '../../../../utils/Permissions';
import { intercomEvent } from '../../../../utils/IntercomUtils';

type TranslationsPageProps = {
  segmentId: number;
};

const TranslationsPage: React.FC<TranslationsPageProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const [searchKey, setSearchKey] = React.useState<string>('');
  const [segmentId, setSegmentId] = React.useState<number>(props.segmentId);
  const [showImportModal, setShowImportModal] = React.useState<boolean>(false);
  const [selectedTransRowIds, setSelectedTransRowIds] = React.useState<number[]>([]);
  const [selectedLanguageIds, setSelectedLanguageIds] = React.useState<number[]>([]);
  const [dirtyTranslations, setDirtyTranslations] = React.useState<LanguageTranslation[]>([]);
  const [editedTranslationIds, setEditedTranslationIds] = React.useState<number[]>([]);

  const {
    accountLanguages,
    segmentTranslationValues,
    translationsRowIds,
    translationTypes,
    initialTransValues,
    user,
  } = useSelector((state: ApplicationState) => {
    return {
      accountLanguages: state.parent.languageSettings.accountLanguages,
      segmentTranslationValues: state.parent.languageTranslation.translations,
      translationsRowIds: state.parent.languageTranslation.translationsRowIds,
      translationTypes: state.resources.data.global.translation_types,
      initialTransValues: mapTranslationsToLanguages(state),
      user: state.user.user,
    };
  });

  React.useEffect(() => {
    setSearchKey('');
    setDirtyTranslations([]);
  }, [segmentId]);

  React.useEffect(() => {
    intercomEvent('viewed-company-translation-settings', { location: 'translation' });
  }, []);

  const canManageTranslation = hasPermission(user, 'can_manage_translation');

  const handleShowImportModal = () => {
    if (canManageTranslation) {
      setShowImportModal(true);
    } else {
      dispatch(showUpgradePlanNotification());
    }
  };

  const handleSubmit = (values: FormikValues, formikActions: any) => {
    intercomEvent('viewed-company-translation-settings', {
      location: 'translation',
      action: 'save',
    });

    const updateValues: any[] = [];
    const { setSubmitting, setSubmitError, resetForm, setStatus } = formikActions;
    setSubmitting(true);

    translationsRowIds.forEach((id: number) => {
      const translations: any[] = [];
      if (id !== -2) {
        Object.values(values).forEach((value: any) => {
          if (value.parentId === id) {
            translations.push({ language_id: value.languageId, value: value.value });
          }
        });
        updateValues.push({ id, translations });
      }
    });

    dispatch(updateTranlations({ translationTypeId: segmentId, translations: updateValues }))
      .then(() => {
        resetForm();
        setStatus('SUCCESS');
        setTimeout(() => setStatus('EDIT'), 2500);
      })
      .catch(error => {
        message.error(
          <span>
            {error.response.data.message}
            <div className="text-left">
              {error.response.data.values.map((v: string) => (
                <div className="pl-6">{v}</div>
              ))}
            </div>
          </span>,
          10
        );
        setSubmitError();
      });
  };

  const showModal = (id: number) => {
    Modal.confirm({
      title: t('common:closeWarningTitle'),
      onOk() {
        setSegmentId(id);
        setSelectedTransRowIds([]);
        setSelectedLanguageIds([]);
      },
    });
  };

  return (
    <PageFormik
      enableReinitialize
      initialValues={initialTransValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      onCancel={() => setDirtyTranslations([])}
      handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
        canManageTranslation && formik.dirty
      }
      contentNoSpacing
    >
      {({ values, dirty, resetForm, setValues }) => {
        const handleDirtyResetForm = (
          editedTransIds: number[],
          fetchedTranslationValue: LanguageTranslation[]
        ) => {
          const editedTranslationsIDs = [...new Set(editedTransIds)];
          const onlyDirtyTranslations: LanguageTranslation[] = editedTranslationsIDs
            .map((id: number) => {
              const existingDirtyTrans = dirtyTranslations.find(
                (d: LanguageTranslation) => d.id === id
              );
              if (existingDirtyTrans) return existingDirtyTrans;
              const newDirtyTrans = segmentTranslationValues.find(
                (s: LanguageTranslation) => s.id === id
              );
              if (newDirtyTrans) return newDirtyTrans;
            })
            .filter(notUndefined);

          const newFetchFilter = fetchedTranslationValue.filter(
            (f: LanguageTranslation) => !editedTransIds.includes(f.id)
          );
          const newFetchedInitValues = translationsInitValues(accountLanguages, newFetchFilter);
          setDirtyTranslations(onlyDirtyTranslations);
          resetForm();
          const transOnlyInitValues: any = {};
          const onlyEditedInitValues = Object.values(values).filter((v: any) =>
            editedTransIds.includes(v.parentId)
          );
          onlyEditedInitValues.forEach((o: any) => {
            transOnlyInitValues[`${o.parentId}_${o.languageId}`] = o;
          });
          setValues({ ...transOnlyInitValues, ...newFetchedInitValues });
        };
        return (
          <React.Fragment>
            <TranslationImportModal
              showImportModal={showImportModal}
              handleOnCancel={() => setShowImportModal(false)}
              segmentId={segmentId}
            />
            <div className="page-layout">
              <div className="page-layout__top-bar">
                <div className="page-layout__top-bar__container">
                  <div className="flex items-center" style={{ height: '24px' }}>
                    <Input.Search
                      value={searchKey}
                      placeholder={t('common:search')}
                      disabled={!segmentId}
                      onChange={e => {
                        const value = e.target.value;
                        setSearchKey(value);
                        typingDone(() =>
                          dispatch(
                            fetchTranslations({
                              translationTypeId: segmentId,
                              keywords: value || '',
                            })
                          )
                            .then(response => {
                              if (dirty) {
                                handleDirtyResetForm(
                                  editedTranslationIds,
                                  response.action.payload.data
                                );
                              }
                            })
                            .catch(() => {
                              if (dirty) {
                                handleDirtyResetForm(editedTranslationIds, []);
                              }
                            })
                        );
                      }}
                      size="small"
                      allowClear
                    />
                    <div className="flex leading-10 pl-2">
                      <span className="text-gray-800">
                        {t('language:translationsSegment.segment')}
                      </span>
                      <span className="pl-2">
                        <Select
                          size="small"
                          showSearch
                          value={segmentId || undefined}
                          placeholder={t('common:select')}
                          optionFilterProp="children"
                          onChange={(id: number) => {
                            if (dirty) showModal(id);
                            else {
                              setSegmentId(id);
                              setSelectedTransRowIds([]);
                              setSelectedLanguageIds([]);
                            }
                          }}
                          filterOption={(input: string, option: any) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '200px' }}
                        >
                          {translationTypes.map(transType => (
                            <Select.Option key={transType.id} value={transType.id}>
                              {transType.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </span>
                    </div>
                  </div>
                  <div className="page-layout__top-bar__actions ">
                    <Button
                      data-testid="import"
                      size="small"
                      onClick={() => handleShowImportModal()}
                    >
                      {t('common:import')}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="page-layout__content">
                <TranslationTable
                  segmentTranslationValues={[
                    ...dirtyTranslations,
                    ...segmentTranslationValues.filter(
                      (s: any) => !dirtyTranslations.find((d: any) => d.id === s.id)
                    ),
                  ]}
                  segmentId={segmentId}
                  searchKey={searchKey}
                  values={values}
                  dirty={dirty}
                  disableActions={!canManageTranslation}
                  handleDeleteOnDirtyForm={rowId => {
                    resetForm({
                      values: {
                        segmentTranslationValues: segmentTranslationValues.filter(
                          (s: LanguageTranslation) => s.id !== rowId
                        ),
                      },
                    });
                    setValues({ ...values });
                  }}
                  selectedTransRowIds={selectedTransRowIds}
                  selectedLanguageIds={selectedLanguageIds}
                  handleResetForm={fetchedTranslationValue => {
                    if (dirty) {
                      const newFetchedInitValues = translationsInitValues(
                        accountLanguages,
                        fetchedTranslationValue
                      );
                      resetForm();
                      setValues({ ...values, ...newFetchedInitValues });
                    }
                  }}
                  handleEditedTransIds={(editedTransIds: number[]) =>
                    setEditedTranslationIds(editedTransIds)
                  }
                  handleDirtyResetForm={(editedTransIds, fetchedTranslationValue) => {
                    if (dirty) handleDirtyResetForm(editedTransIds, fetchedTranslationValue);
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </PageFormik>
  );
};

export default TranslationsPage;
