import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

export function fetchAttributeTagList() {
  return {
    type: 'FETCH_ATTRIBUTE_TAGS',
    payload: axios.get(`${getApiRoot()}/accounts/category_part_attributes/tags`),
  };
}

let fetchCategoriesCancelToken: any;

export function fetchAttributePartTypes(keywords?: string, page = 1) {
  if (fetchCategoriesCancelToken) fetchCategoriesCancelToken();

  const params = utils.createURL([
    { name: 'keywords[0]', values: keywords },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ATTRIBUTE_PART_TYPE_CATEGORIES',
    meta: { page },
    payload: axios.get(`${getApiRoot()}/categories${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCategoriesCancelToken = c;
      }),
    }),
  };
}

export function fetchPartTypeAttributes(categoryId: number) {
  const params = utils.createURL([{ name: 'category_id', values: categoryId }]);

  return {
    type: 'FETCH_PART_TYPE_ATTRIBUTES',
    payload: axios.get(`${getApiRoot()}/accounts/category_part_attributes${params}`),
  };
}

let fetchAttributesByKeywordCancelToken: any;

export function fetchAttributesByKeyword(params: {
  page: number;
  limit: number;
  value_limit: number;
  category_id: number;
  filters?: string;
}) {
  if (fetchAttributesByKeywordCancelToken) fetchAttributesByKeywordCancelToken();

  return {
    meta: { page: params.page },
    type: 'FETCH_ATTRIBUTES_BY_KEYWORDS',
    payload: axios.post(`${getApiRoot()}/part_attributes/ranks`, params, {
      cancelToken: new CancelToken(c => {
        fetchAttributesByKeywordCancelToken = c;
      }),
    }),
  };
}
