import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function deleteApplication({
  applicationId,
  applicationIds,
  itemId,
}: {
  applicationId?: number;
  applicationIds?: number[];
  itemId?: number;
}) {
  const params = utils.createURL([
    { name: 'item_application_id', values: applicationId },
    { name: 'item_application_ids', values: applicationIds },
    { name: 'item_id', values: itemId },
  ]);

  return {
    type: 'DELETE_APPLICATION',
    payload: axios.delete(`${getApiRoot()}/items/applications${params}`),
    meta: { id: applicationId, ids: applicationIds, itemId },
  };
}

export function deleteApplicationQualifiers(applicationId: number, qualifierIds: number[]) {
  const urlParams = utils.createURL([{ name: 'qualifier_ids', values: qualifierIds }]);

  return {
    type: 'DELETE_QUALIFIER',
    payload: axios.delete(
      `${getApiRoot()}/items/applications/${applicationId}/qualifiers${urlParams}`
    ),
  };
}

export function deleteDefaultQualifiers(itemId: number, qualifierIds: number[]) {
  const urlParams = utils.createURL([{ name: 'qualifier_ids', values: qualifierIds }]);

  return {
    type: 'DELETE_DEFAULT_QUALIFIERS',
    payload: axios.delete(`${getApiRoot()}/items/${itemId}/qualifiers${urlParams}`),
    meta: { qualifierIds },
  };
}

export function deleteApplicationDigitalAsset(assetId: number) {
  return {
    type: 'DELETE_APPLICATION_DIGITAL_ASSETS',
    meta: { assetId },
    payload: axios.delete(`${getApiRoot()}/items/applications/digital_assets/${assetId}`),
  };
}

export const removeApplicationLinks = (baseItemIds: number[], itemBaseIds: number[]) => {
  const params = utils.createURL([{ name: 'item_base_ids', values: itemBaseIds }]);

  return {
    type: 'REMOVE_APPLICATION_LINKS',
    payload: axios.delete(`${getApiRoot()}/items/bases${params}`),
    meta: { baseItemIds },
  };
};

export const removeBundleItem = (itemBaseIds: number[]) => {
  const params = utils.createURL([{ name: 'item_base_ids', values: itemBaseIds }]);

  return {
    type: 'REMOVE_APPLICATION_BUNDLE',
    payload: axios.delete(`${getApiRoot()}/items/bases${params}`),
    meta: { itemBaseIds },
  };
};

export const deleteVcdb = (id: number) => {
  return {
    type: 'DELETE_VCDB',
    payload: axios.delete(`${getApiRoot()}/vehicles/versions/${id}`),
    meta: { id },
  };
};

export const deleteCustomSubConfig = (
  vcdbVersion: number,
  subConfigKey: string,
  subConfigId: number
) => {
  const params = utils.createURL([
    { name: 'vehicle_version_id', values: vcdbVersion },
    { name: 'sub_config_key', values: subConfigKey },
    { name: 'sub_config_id', values: subConfigId },
  ]);

  return {
    type: 'DELETE_CUSTOM_SUB_CONFIG',
    payload: axios.delete(`${getApiRoot()}/accounts/custom_vehicles/sub_configs${params}`),
    meta: { subConfigKey, subConfigId },
  };
};

export const deleteCustomConfig = (
  vehicleVersionId: number,
  configType: string,
  vehicleConfigId: number
) => {
  const params = utils.createURL([
    { name: 'vehicle_version_id', values: vehicleVersionId },
    { name: 'config_type', values: configType },
    { name: 'vehicle_config_id', values: vehicleConfigId },
  ]);

  return {
    type: 'DELETE_CUSTOM_CONFIG',
    payload: axios.delete(`${getApiRoot()}/accounts/custom_vehicles/vehicle_configs${params}`),
  };
};
