import { Col, Row, Form, Transfer, Alert } from 'antd';
import React from 'react';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';

import { PriceSheetType } from '../../../../types/price_sheet';
import { ExtendedResources, DescriptionTypeElement, Ebay } from '../../../../types/resources';
import { EbayPolicies } from '../../../../types/ebay';
import { ebay } from '../../../constants/ChannelTranslation.json';
import { ebayPolicyUrl } from '../../../constants/ParamountReactConstants';
import FormSelect from '../../global/Forms/FormSelect';
import PriceSheetSelect from '../../global/PriceSheetSelect';

const EbaySettingsFormPart = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  ebayResources,
  descriptionTypes,
  ebayPolicies,
  priceSheets,
  priceTypes,
  disabled,
  isReceiverUser,
}: {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  setFieldValue: (name: string, value: any) => void;
  setFieldTouched: (name: string) => void;
  ebayResources: Ebay;
  descriptionTypes: DescriptionTypeElement[];
  ebayPolicies: EbayPolicies;
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  disabled: boolean;
  isReceiverUser: boolean;
}) => (
  <React.Fragment>
    <Row gutter={20}>
      <Col span={8}>
        <FormSelect
          name="paymentProfileId"
          values={ebayPolicies.payment}
          label={ebay.payment}
          placeholder={ebay.selectPlaceholder}
          disabled={disabled}
        />
      </Col>
      <Col span={8}>
        <FormSelect
          name="returnProfileId"
          values={ebayPolicies.return}
          label={ebay.return}
          placeholder={ebay.selectPlaceholder}
          disabled={disabled}
        />
      </Col>
      <Col span={8}>
        <FormSelect
          name="shippingProfileId"
          values={ebayPolicies.fulfillment}
          label={ebay.shipping}
          placeholder={ebay.selectPlaceholder}
          disabled={disabled}
        />
      </Col>
    </Row>

    <Row gutter={20} className="channel__ebay-profile-message">
      <Col span={24}>
        <span>{ebay.ebayPolicyMessage1}</span>
        <a
          className="channel__ebay-profile-link"
          href={ebayPolicyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {ebay.clickHere}
        </a>
        <span>{ebay.ebayPolicyMessage2}</span>
      </Col>
    </Row>

    <Row gutter={20}>
      <Col span={12}>
        <FormSelect
          name="titleDescriptionTypeId"
          values={descriptionTypes.filter(desc => desc.max_length <= 80)}
          label={ebay.title}
          placeholder={ebay.selectPlaceholder}
          disabled={disabled}
          required
        />
      </Col>
    </Row>

    <Row gutter={20} className="channel__form-headline">
      <Col span={24}>
        <span>{ebay.description}</span>
      </Col>
    </Row>

    <Row gutter={20}>
      <Col span={24}>
        <Transfer
          className="channel__ebay-description-transfer"
          showSearch
          dataSource={descriptionTypes.map((type: DescriptionTypeElement) => ({
            ...type,
            key: type.id.toString(),
            title: type.name,
          }))}
          filterOption={(inputValue, option) =>
            option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          }
          targetKeys={values.descriptionTypeIds.map((id: number) => id.toString())}
          onChange={(targetKeys, direction, moveKeys) => {
            if (direction === 'right')
              setFieldValue('descriptionTypeIds', [...values.descriptionTypeIds, ...moveKeys]);
            else if (direction === 'left')
              setFieldValue(
                'descriptionTypeIds',
                values.descriptionTypeIds.filter(
                  (key: string) => !moveKeys.includes(key.toString())
                )
              );
          }}
          render={item => item.title || ''}
          listStyle={() => ({ width: 'calc(50% - 25px)', height: 350 })}
          disabled={disabled}
        />
      </Col>
    </Row>

    {isReceiverUser && (
      <Row gutter={20} className="channel__form-headline">
        <Col span={6}>
          <span>{ebay.price}</span>
        </Col>
      </Row>
    )}

    {isReceiverUser && (
      <Alert message={ebay.pricePlanSelect} type="info" style={{ marginBottom: '15px' }} showIcon />
    )}

    {!isReceiverUser && (
      <Row gutter={20}>
        <Col span={10}>
          <Form.Item
            hasFeedback
            validateStatus={errors.priceSheetId && touched.priceSheetId ? 'error' : ''}
            help={(touched.priceSheetId && errors.priceSheetId) || undefined}
            className="channel__ebay__price-select"
            label={ebay.price}
            required
          >
            <PriceSheetSelect
              priceSheets={priceSheets}
              priceTypes={priceTypes}
              updateSelection={({
                selectedPriceSheetIds,
                selectedPriceTypeIds,
                withoutPrices,
              }: {
                selectedPriceSheetIds: number[];
                selectedPriceTypeIds: number[];
                withoutPrices: number;
              }) => {
                if (selectedPriceSheetIds) {
                  setFieldValue('priceSheetId', selectedPriceSheetIds[0]);
                }
                if (selectedPriceTypeIds) setFieldValue('priceTypeId', selectedPriceTypeIds[0]);
                if (withoutPrices) setFieldValue('withoutPrices', 1);
                else setFieldValue('withoutPrices', 0);
              }}
              defaultSelectedPriceSheetIds={[values.priceSheetId] || []}
              defaultSelectedPriceTypeIds={[values.priceTypeId]}
              defaultWithoutPrices={values.withoutPrices}
              onBlur={() => setFieldTouched('priceSheetId')}
              checkboxLabel={ebay.noPriceManaging}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
    )}

    <Row gutter={20} className="channel__form-headline">
      <Col span={24}>
        <span>{ebay.sellingDetails}</span>
      </Col>
    </Row>

    <Row gutter={20}>
      <Col span={12}>
        <FormSelect
          name="ebayListtype"
          values={ebayResources.listtype}
          label={ebay.format}
          placeholder={ebay.selectPlaceholder}
          useCode
          disabled={disabled}
        />
      </Col>
      <Col span={12}>
        <FormSelect
          name="ebayListtime"
          values={ebayResources.listtime}
          label={ebay.duration}
          placeholder={ebay.selectPlaceholder}
          useCode
          disabled={disabled}
        />
      </Col>
    </Row>
  </React.Fragment>
);

export default EbaySettingsFormPart;
