import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { Note } from '../../../../types/interchange';

export function createInterchangeItem(argument: any, notes: Note) {
  const paramObject = Object.keys(argument)
    .filter(key => !(key === 'notes' || key === 'internal_notes'))
    .map(key => ({ name: key, values: argument[key] }));
  const params = utils.createURL(paramObject);

  return {
    type: 'CREATE_INTERCHANGE_ITEM',
    payload: axios.post(`${getApiRoot()}/items/part_interchange_informations${params}`, notes),
  };
}
