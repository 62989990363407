import common from './common.json';
import validation from './validation.json';
import glossary from './glossary.json';
import segments from './segments.json';
import settings from './settings.json';
import item from './item.json';
import partType from './partType.json';
import exportBuilderAdvanced from './exportBuilderAdvanced.json';
import digitalAssets from './digitalAssets.json';
import catalogue from './catalogue.json';
import application from './application.json';
import applicationValidation from './applicationValidation.json';
import kit from './kit.json';
import interchange from './interchange.json';
import importer from './importer.json';
import exporter from './exporter.json';
import channelPusher from './channelPusher.json';
import publishData from './publishData.json';
import quickfix from './quickfix.json';
import applicationConfigs from './applicationConfigs.json';
import description from './description.json';
import extendedInfo from './extendedInfo.json';
import accountSettings from './accountSettings.json';
import user from './user.json';
import channel from './channel.json';
import header from './header.json';
import sideBarLeft from './sideBarLeft.json';
import api from './api.json';
import pricing from './pricing.json';
import attributes from './attributes.json';
import language from './language.json';
import login from './login.json';
import vcdb from './vcdb.json';
import receiver from './receiver.json';
import packageSeg from './packageSeg.json';
import externalSources from './externalSources.json';
import deliveryLog from './deliveryLog.json';
import errorLogModal from './errorLogModal.json';
import brandManaging from './brandManaging.json';
import registerPage from './registerPage.json';
import filter from './filter.json';
import homeContainer from './homeContainer.json';
import welcome from './welcome.json';
import gapCoverageReport from './gapCoverageReport.json';
import companyProfile from './companyProfile.json';
import mainApp from './mainApp.json';
import ebay from './ebay.json';
import insights from './insights.json';
import retool from './retool.json';
import marketing from './marketing.json';

export default {
  common,
  glossary,
  validation,
  segments,
  settings,
  item,
  partType,
  exportBuilderAdvanced,
  digitalAssets,
  catalogue,
  application,
  applicationValidation,
  kit,
  interchange,
  importer,
  exporter,
  channelPusher,
  publishData,
  quickfix,
  applicationConfigs,
  description,
  extendedInfo,
  accountSettings,
  user,
  channel,
  header,
  sideBarLeft,
  api,
  pricing,
  attributes,
  language,
  login,
  vcdb,
  receiver,
  packageSeg,
  externalSources,
  deliveryLog,
  errorLogModal,
  brandManaging,
  registerPage,
  filter,
  homeContainer,
  welcome,
  gapCoverageReport,
  companyProfile,
  mainApp,
  ebay,
  insights,
  retool,
  marketing,
};
