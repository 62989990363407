import { connect } from 'react-redux';
import React from 'react';
import { Button, Input } from 'antd';
import { t } from 'i18next';
import { ReceiverColumns, REJECTED, APPROVED, PENDING } from '../../constants/ReceiverConstants';
import { ApplicationState } from '../../reducers/index';
import { AsyncDispatch } from '../../../types/global';
import { ExtendedResources, StandardResourceCodeDescription } from '../../../types/resources';
import { PriceSheetType } from '../../../types/price_sheet';
import { Receiver, ReceiverDetails, ReceiverRequest } from '../../../types/receiver';
import { UserType } from '../../../types/user';
import { mapResourcesToReceivers } from '../../selectors/receiver/receiverSelector';
import { withContainerWrapper } from '../ContainerWrapper';
import ReceiverTable from '../../components/body/receiver/ReceiverTable';
import ReceiverDetailsModal from '../../components/body/receiver/ReceiverDetailsModal';
import actions from '../../actions/receiver';
import { fetchPriceSheets } from '../../actions/parent/price_sheets/fetch';
import { isManufacturer } from '../../utils/UserUtils';
import translations from '../../constants/ReceiverTranslation.json';
import { intercomEvent } from '../../utils/IntercomUtils';
import { fetchReceiver } from '../../actions/receiver/fetch';
import { getOrderStringToInt } from '../../utils/Utils';
import { hasPermission } from '../../utils/Permissions';
import { receiverBulkInvite, receiverBulkInviteByFile } from '../../actions/receiver/update';
import { fetchAllAccountFilters } from '../../actions/catalogue/filter/fetch';
import { Brand } from '../../../types/brand';
import { activeBrandsWithoutOe } from '../../selectors/brand/brandSelector';
import ReceiverBulkInviteModal from '../../components/body/receiver/ReceiverBulkInviteModal';

type ReceiverContainerProps = {
  user: UserType;
  isLoading: boolean;
  isLoadingFullList: boolean;
  fetchingReceiverDetails: boolean;
  receivers: Receiver[];
  fullReceiverList: Receiver[];
  receiverDetails?: ReceiverDetails;
  reqStatuses: StandardResourceCodeDescription[];
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  dispatch: AsyncDispatch;
  activeBrands: Brand[];
  canManageResellerInBulk: boolean;
};

class ReceiverContainer extends React.Component<ReceiverContainerProps> {
  state = {
    filterKeywords: '',
    showReceiverDetailModal: false,
    showReceiverBulkInviteModal: false,
  };

  componentDidMount() {
    this.props.dispatch(actions.fetchFullReceiverList());
    this.props.dispatch(fetchAllAccountFilters());
    if (isManufacturer(this.props.user)) {
      this.props.dispatch(actions.fetchReceiverRequestCount());
    }
    this.props.dispatch(fetchPriceSheets());
    intercomEvent('viewed-receiver');
  }

  getReceiverStatus = () => {
    const { fullReceiverList, receiverDetails } = this.props;
    const receiver = fullReceiverList.find(r => r.receiver_id === receiverDetails?.id);
    const approved = !!receiver?.requests.find(req => this.getRequestStatus(req) === APPROVED);
    const pending = !!receiver?.requests.find(req => this.getRequestStatus(req) === PENDING);
    const rejected = !!receiver?.requests.find(req => this.getRequestStatus(req) === REJECTED);
    const authorizedByReceiver = !!receiver?.requests.find(
      req => !!req.authorized_by_receiver_at && this.getRequestStatus(req) === PENDING
    );
    return {
      approved,
      pending,
      rejected,
      authorizedByReceiver,
    };
  };

  getRequestStatus = (request: ReceiverRequest) => {
    return request.status || '';
  };

  handleShowReceiverDetails = (receiverId: number) => {
    const receiver = this.props.receivers.find(r => r.receiver_id === receiverId);

    this.props.dispatch(actions.fetchReceiverDetails(receiverId));
    this.props.dispatch(actions.fetchReceiverWarehouseList());
    this.setState({ showReceiverDetailModal: true });

    intercomEvent('viewed-receiver', {
      action: 'viewed-profile',
      receiver: receiver?.name || '',
    });
  };

  requestReceiverAccess = ({
    receiverId,
    brandIds,
    integrationTypeId,
    selectedPriceSheetIds,
    selectedPriceTypeIds,
    withoutPrices,
    filterIds,
  }: {
    receiverId: number;
    brandIds: number[];
    integrationTypeId: number | null;
    selectedPriceSheetIds: number[];
    selectedPriceTypeIds: number[];
    withoutPrices: boolean;
    filterIds: number[];
  }) => {
    const { receivers, activeBrands } = this.props;

    this.props.dispatch(
      actions.createReceiverRequest({
        receiverId,
        brandIds,
        integrationTypeId,
        selectedPriceSheetIds,
        selectedPriceTypeIds,
        withoutPrices,
        filterIds,
      })
    );

    intercomEvent('viewed-receiver', {
      action: 'requested-receiver',
      receiver: receivers.find(r => r.receiver_id === receiverId)?.name || '',
      brand_names: brandIds.map(id => activeBrands.find(b => b.id === id)?.name).join(', '),
    });
  };

  editReceiverPrices = ({
    receiverId,
    brandId,
    withoutPrices,
    selectedPriceSheetIds,
    selectedPriceTypeIds,
  }: {
    receiverId: number;
    brandId: number;
    selectedPriceSheetIds: number[];
    selectedPriceTypeIds: number[];
    withoutPrices: boolean;
  }) => {
    return this.props.dispatch(
      actions.editReceiverPrices({
        receiverId,
        brandId,
        withoutPrices,
        selectedPriceSheetIds,
        selectedPriceTypeIds,
      })
    );
  };

  editReceiverFilters = ({
    receiverId,
    brandId,
    filterIds,
  }: {
    receiverId: number;
    brandId: number;
    filterIds: number[];
  }) => {
    return this.props.dispatch(
      actions.editReceiverFilters({
        receiverId,
        brandId,
        filterIds,
      })
    );
  };

  approveReceivers = ({
    receiverId,
    brandIds,
    selectedPriceSheetIds,
    selectedPriceTypeIds,
    withoutPrices,
  }: {
    receiverId: number;
    brandIds: number[];
    selectedPriceSheetIds: number[];
    selectedPriceTypeIds: number[];
    withoutPrices: boolean;
  }) => {
    const { receivers, activeBrands } = this.props;
    const receiver = receivers.find(r => r.receiver_id === receiverId);

    this.props.dispatch(
      actions.approveReceivers({
        receiverId,
        brandIds,
        selectedPriceSheetIds,
        selectedPriceTypeIds,
        withoutPrices,
      })
    );
    intercomEvent('viewed-receiver', {
      action: 'approved-receiver',
      receiver: receiver?.name || '',
      brand_names: brandIds.map(id => activeBrands.find(b => b.id === id)?.name).join(', '),
    });
  };

  declineReceivers = (receiverId: number, brandIds: number[], reason?: string) => {
    const { receivers, activeBrands } = this.props;
    const receiver = receivers.find(r => r.receiver_id === receiverId);

    this.props.dispatch(actions.declineReceivers({ receiverId, brandIds, reason }));
    intercomEvent('viewed-receiver', {
      action: 'rejected-receiver',
      receiver: receiver?.name || '',
      brand_names: brandIds.map(id => activeBrands.find(b => b.id === id)?.name).join(', '),
    });
  };

  cancelRequests = (receiverId: number, brandIds: number[]) => {
    this.props.dispatch(actions.cancelRequests({ receiverId, brandIds }));
  };

  handleReceiverFilter = (filterReceiverIds: number[], statusIds: number[], sortOrder: string) => {
    this.props.dispatch(
      fetchReceiver({
        orderCondition: sortOrder ? ReceiverColumns.COMPANY : undefined,
        filterReceiverIds,
        statusIds,
        descending: getOrderStringToInt(sortOrder),
      })
    );
  };

  handleBulkInvite = async ({ values, file }: { values?: any[]; file?: any }) => {
    const { dispatch, user } = this.props;

    let bulkResponse;

    if (values) bulkResponse = await dispatch(receiverBulkInvite(values));
    else bulkResponse = await dispatch(receiverBulkInviteByFile(file));

    dispatch(actions.fetchFullReceiverList());

    intercomEvent('reseller-list-uploaded', {
      resellerCount: values?.length.toString() || '',
      user: `${user.first_name} ${user.last_name}`,
      companyName: user.account_name,
    });

    return bulkResponse;
  };

  render() {
    const {
      isLoadingFullList,
      isLoading,
      fetchingReceiverDetails,
      receivers,
      fullReceiverList,
      reqStatuses,
      receiverDetails,
      user,
      activeBrands,
      canManageResellerInBulk,
    } = this.props;
    const { showReceiverDetailModal, showReceiverBulkInviteModal, filterKeywords } = this.state;
    const canAccessReceiverProfile = hasPermission(user, 'can_access_receiver_profile');

    return (
      <div className="page-layout receiver-container">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <Input.Search
              className="page-layout__top-bar__search"
              value={this.state.filterKeywords}
              onChange={e => this.setState({ filterKeywords: e.target.value || '' })}
              placeholder={translations.searchPlaceholder}
              allowClear
            />
            {canManageResellerInBulk && (
              <Button onClick={() => this.setState({ showReceiverBulkInviteModal: true })}>
                {t('receiver:inviteReseller')}
              </Button>
            )}
          </div>
        </div>
        <div className="page-layout__content flex">
          {isLoadingFullList ? (
            <div className="loader" />
          ) : (
            <ReceiverTable
              isLoading={isLoading}
              receivers={
                canAccessReceiverProfile && filterKeywords
                  ? receivers.filter(
                      ({ name }) =>
                        name && name.toLowerCase().includes(filterKeywords.toLowerCase())
                    )
                  : receivers
              }
              requestReceiverAccess={this.requestReceiverAccess}
              approveReceivers={this.approveReceivers}
              editReceiverPrices={this.editReceiverPrices}
              editReceiverFilters={this.editReceiverFilters}
              declineReceivers={this.declineReceivers}
              cancelRequests={this.cancelRequests}
              priceSheets={this.props.priceSheets}
              priceTypes={this.props.priceTypes}
              handleReceiverFilter={this.handleReceiverFilter}
              handleShowReceiverDetails={this.handleShowReceiverDetails}
              fullReceiverList={fullReceiverList}
              reqStatuses={reqStatuses}
            />
          )}

          <ReceiverDetailsModal
            showModal={showReceiverDetailModal}
            receiverDetails={receiverDetails}
            fetchingReceiverDetails={fetchingReceiverDetails}
            status={showReceiverDetailModal ? this.getReceiverStatus() : {}}
            onCancel={() => this.setState({ showReceiverDetailModal: false })}
          />
          <ReceiverBulkInviteModal
            showModal={showReceiverBulkInviteModal}
            handleBulkInvite={this.handleBulkInvite}
            activeBrands={activeBrands}
            onClose={() => this.setState({ showReceiverBulkInviteModal: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.user.user,
    isLoading: state.receiver.receivers.isLoading,
    isLoadingFullList: state.receiver.receivers.isLoadingFullList,
    fetchingReceiverDetails: state.receiver.receivers.fetchingReceiverDetails,
    receivers: mapResourcesToReceivers(state),
    fullReceiverList: state.receiver.receivers.fullReceiverList,
    receiverDetails: state.receiver.receivers.receiverDetails,
    priceSheets: state.parent.priceSheets.priceSheets || [],
    priceTypes: (state.resources.data.price && state.resources.data.price.types) || [],
    reqStatuses: state.resources.data.receiver?.request_statuses || [],
    activeBrands: activeBrandsWithoutOe(state),
    canManageResellerInBulk: hasPermission(state.user.user, 'can_manage_reseller_in_bulk'),
  };
};

export default connect(mapStateToProps)(withContainerWrapper(ReceiverContainer));
