import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { Attribute } from '../../../../types/attributes';

export function updateAttributes(attributesUpdate: any[], attributes: Attribute[]) {
  return {
    meta: { attributes },
    type: 'UPDATE_ATTRIBUTES',
    payload: axios.put(`${getApiRoot()}/items/part_attributes`, attributesUpdate),
  };
}
