import React from 'react';
import { connect } from 'react-redux';
import {
  fetchValidatedAnalysisTypes,
  createAnalysisTypeForBrand,
  updateSelectedSegmentId,
  updateSelectedAlertTypeId,
  updateSelectedScorecardId,
  updateAnalysesProgress,
  deleteAnalysisTypeForBrand,
} from '../../actions/settings/scorecards';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { filterResourcesAndAddValidatingInfo } from '../../selectors/settings/settingsSelector';
import { getSelectedBrandCode } from '../../selectors/brand/brandSelector';
import SettingsScorecard from '../../components/body/settings/SettingsScorecard';

class SettingsScorecardContainer extends React.Component {
  componentDidMount() {
    this.fetchValidatedAnalysisTypes();

    intercomEvent('viewed-brand-settings', {
      location: 'settings-scorecard',
      brand_code: this.props.brandCode,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.brandId !== prevProps.brandId)
      this.fetchValidatedAnalysisTypes(this.props.brandId);
  }

  fetchValidatedAnalysisTypes = (brandId = this.props.brandId) => {
    this.props.dispatch(fetchValidatedAnalysisTypes(brandId));
  };

  createAnalysisTypeForBrand = analysisTypeId => {
    this.props.dispatch(createAnalysisTypeForBrand(analysisTypeId, this.props.brandId));
  };

  updateSelectedSegmentId = id => this.props.dispatch(updateSelectedSegmentId(id));

  updateSelectedAlertTypeId = id => this.props.dispatch(updateSelectedAlertTypeId(id));

  updateSelectedScorecardId = id => this.props.dispatch(updateSelectedScorecardId(id));

  updateAnalysesProgress = () => this.props.dispatch(updateAnalysesProgress(this.props.brandId));

  deleteAnalysisTypeForBrand = analysisTypeIds =>
    this.props.dispatch(deleteAnalysisTypeForBrand(analysisTypeIds, this.props.brandId));

  render() {
    const { user } = this.props;

    const canManageScorecard = hasPermission(user, 'can_manage_scorecards');

    return (
      <SettingsScorecard
        canManageScorecard={canManageScorecard}
        createAnalysisTypeForBrand={this.createAnalysisTypeForBrand}
        deleteAnalysisTypeForBrand={this.deleteAnalysisTypeForBrand}
        updateSelectedSegmentId={this.updateSelectedSegmentId}
        updateSelectedAlertTypeId={this.updateSelectedAlertTypeId}
        updateSelectedScorecardId={this.updateSelectedScorecardId}
        updateAnalysesProgress={this.updateAnalysesProgress}
        updateAnalysesProgressIsRunning={this.props.updateAnalysesProgressIsRunning}
        analysesResources={this.props.analysesResources}
        filterAnalysesResources={this.props.filterAnalysesResources}
      />
    );
  }
}

const mapStateToProps = state => ({
  filterAnalysesResources: state.settings.scorecards.filterAnalysesResources,
  updateAnalysesProgressIsRunning: state.settings.scorecards.updateAnalysesProgressIsRunning,
  analysesResources: filterResourcesAndAddValidatingInfo(state),
  brandId: state.parent.brands.selectedBrandId,
  brandCode: getSelectedBrandCode(state),
  user: state.user.user,
});

export default connect(mapStateToProps)(SettingsScorecardContainer);
