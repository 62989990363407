import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

const CancelToken = axios.CancelToken;

export const fetchOutOfSyncItems = (brandId: number, page = 1) => {
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_OUT_OF_SYNC_ITEMS',
    payload: axios.get(`${getApiRoot()}/items/out_of_sync/item_names${params}`),
    meta: {
      page,
    },
  };
};

export const fetchOutOfSyncItemsCount = (brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_OUT_OF_SYNC_ITEMS_COUNT',
    payload: axios.get(`${getApiRoot()}/items/out_of_sync/count${params}`),
  };
};

let fetchSyncLogCancelToken: any;

export const fetchSyncLog = (brandId: number) => {
  if (fetchSyncLogCancelToken) fetchSyncLogCancelToken();

  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);

  return {
    type: 'FETCH_SYNC_LOGS',
    payload: axios.get(`${getApiRoot()}/items/sync/log${params}`, {
      cancelToken: new CancelToken(c => {
        fetchSyncLogCancelToken = c;
      }),
    }),
  };
};

export const increaseOutOfSyncCount = (count: number) => ({
  type: 'INCREASE_OUT_OF_SYNC_COUNT',
  payload: count,
});

export const syncItems = ({
  itemIds,
  filterId,
  brandId,
  withoutItemIds,
}: {
  itemIds?: number[];
  filterId?: number;
  brandId?: number;
  withoutItemIds?: number[];
}) => {
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'filter_id', values: filterId },
  ]);
  const itemIdsBody = itemIds && !filterId ? { item_ids: itemIds } : undefined;
  const withoutItemIdsBody = withoutItemIds ? { without_item_ids: withoutItemIds } : undefined;

  return {
    type: 'SYNC_ITEMS',
    meta: { itemIds, withoutItemIds },
    payload: axios.post(`${getApiRoot()}/items/sync/${params}`, {
      ...itemIdsBody,
      ...withoutItemIdsBody,
    }),
  };
};

export const syncAtNight = (itemIds: number[], brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  const itemIdsString = itemIds.join(',');
  return {
    type: 'SYNC_BRAND_AT_NIGHT',
    payload: axios.post(`${getApiRoot()}/items/sync_at_night${params}`, {
      item_ids: itemIdsString,
    }),
  };
};

export const syncItemsLive = (itemIds: number[], brandId: number) => {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  const itemIdsString = itemIds.join(',');

  return {
    type: 'SYNC_ITEMS_LIVE',
    payload: axios.post(`${getApiRoot()}/items/sync_live${params}`, {
      item_ids: itemIdsString,
    }),
    meta: { itemIds },
  };
};
