import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { selectDigitalAsset } from './update';

export function fetchDigitalAssets(itemId: number) {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_DIGITAL_ASSETS',
      payload: axios.get(`${getApiRoot()}/items/${itemId}/digital_assets`),
    }).then((res: any) => {
      const digitalAssets = res.value.data;
      if (digitalAssets.length) {
        const firstDigitalAssetId = res.value.data[0].id;
        dispatch(selectDigitalAsset(firstDigitalAssetId));
      }
    });
  };
}
