import axios from 'axios';

import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

const CancelToken = axios.CancelToken;

export function setEmptyFilter() {
  return {
    type: 'SET_EMPTY_FILTER',
  };
}

let updateFilterCancelToken;

export function cancelUpdateFilterRequest() {
  if (updateFilterCancelToken) updateFilterCancelToken();
}

export function updateFilter(filterId, parameter) {
  if (updateFilterCancelToken) updateFilterCancelToken();

  const urlParams = utils.createURL([
    { name: 'keywords', values: parameter.keywords, allowEmpty: true },
    { name: 'name', values: parameter.name },
  ]);

  return {
    type: 'UPDATE_FILTER',
    meta: { nameChanged: !!parameter.name, filterId, name: parameter.name },
    payload: axios.patch(`${getApiRoot()}/filters/${filterId}${urlParams}`, {
      cancelToken: new CancelToken(c => {
        updateFilterCancelToken = c;
      }),
    }),
  };
}

export function updateFilterGo(filter) {
  return {
    type: 'UPDATE_FILTER_GO',
    payload: axios.patch(`${getApiRoot()}/filters`, filter),
    meta: { filter },
  };
}

export function updateTempFilterItems(filterId, itemIds = []) {
  const urlParams = utils.createURL([{ name: 'keywords', values: '', allowEmpty: true }]); // always reset keyword

  return {
    type: 'UPDATE_TEMP_FILTER',
    payload: axios.patch(`${getApiRoot()}/filters/${filterId}${urlParams}`, {
      item_ids: itemIds,
    }),
    meta: { itemIds },
  };
}

export function updateTempFilterPartNumbers(filterId, brandId, partNrs = []) {
  const urlParams = utils.createURL([
    { name: 'keywords', values: '', allowEmpty: true }, // always reset keyword
    { name: 'brand_id', values: brandId },
  ]);

  return {
    type: 'UPDATE_TEMP_FILTER',
    payload: axios.patch(`${getApiRoot()}/filters/${filterId}${urlParams}`, {
      part_numbers: partNrs,
    }),
    meta: { itemIds: [] },
  };
}

export function resetClientFilterParams() {
  return {
    type: 'RESET_CLIENT_FILTER_PARAMS',
  };
}

export function resetFilter(filterId) {
  return {
    type: 'RESET_FILTER',
    payload: axios.patch(`${getApiRoot()}/filters/${filterId}/reset`),
    meta: { filterId },
  };
}

export function updateCategoryFilterParameter(parameterId, optionId) {
  const urlParams = utils.createURL([{ name: 'option_id', values: optionId }]);

  return {
    type: 'UPDATE_CATEGORY_FILTER_PARAMETER',
    payload: axios.patch(
      `${getApiRoot()}/filters/categories/parameters/${parameterId}${urlParams}`
    ),
  };
}

export function addFilterCategoryParameterValue(parameterId, categoryId) {
  const urlParams = utils.createURL([
    { name: 'filter_category_parameter_id', values: parameterId },
    { name: 'category_ids', values: categoryId },
  ]);

  return {
    type: 'ADD_CATEGORY_FILTER_VALUE',
    payload: axios.post(`${getApiRoot()}/filters/categories/parameters/values${urlParams}`),
  };
}

export function removeFilterCategoryParameterValue(valueId) {
  return {
    type: 'REMOVE_CATEGORY_FILTER_VALUE',
    payload: axios.delete(`${getApiRoot()}/filters/categories/parameters/values/${valueId}`),
  };
}

export function updateApplicationFilterParameter(parameterId, optionId) {
  const urlParams = utils.createURL([{ name: 'option_id', values: optionId }]);

  return {
    type: 'UPDATE_APPLICATION_FILTER_PARAMETER',
    payload: axios.patch(
      `${getApiRoot()}/filters/applications/parameters/${parameterId}${urlParams}`
    ),
  };
}

export function addFilterApplicationParameterValue(parameterId, resourceId) {
  const urlParams = utils.createURL([
    { name: 'filter_application_parameter_id', values: parameterId },
    { name: 'resource_ids', values: resourceId },
  ]);

  return {
    type: 'ADD_APPLICATION_FILTER_VALUE',
    payload: axios.post(`${getApiRoot()}/filters/applications/parameters/values${urlParams}`),
  };
}

export function removeFilterApplicationParameterValues(valueIds) {
  return {
    type: 'REMOVE_APPLICATION_FILTER_VALUE',
    payload: axios.delete(`${getApiRoot()}/filters/applications/parameters/values/${valueIds}`),
  };
}

export function updateAnalysisFilterParameter(parameterId, optionId) {
  const urlParams = utils.createURL([{ name: 'option_id', values: optionId }]);

  return {
    type: 'UPDATE_ANALYSIS_FILTER_PARAMETER',
    payload: axios.patch(`${getApiRoot()}/filters/analyses/parameters/${parameterId}${urlParams}`),
  };
}

export function addFilterAnalysisParameterValue(parameterId, analysisId) {
  const urlParams = utils.createURL([
    { name: 'filter_analysis_parameter_id', values: parameterId },
    { name: 'analysis_id', values: analysisId },
  ]);

  return {
    type: 'ADD_ANALYSIS_FILTER_VALUE',
    payload: axios.post(`${getApiRoot()}/filters/analyses/parameters/values${urlParams}`),
  };
}

export function removeFilterAnalysisParameterValues(valueIds) {
  return {
    type: 'REMOVE_ANALYSIS_FILTER_VALUE',
    payload: axios.delete(`${getApiRoot()}/filters/analyses/parameters/values/${valueIds}`),
  };
}

export function updateCustomFilterParameter(parameterId, optionId) {
  const urlParams = utils.createURL([{ name: 'option_id', values: optionId }]);

  return {
    type: 'UPDATE_CUSTOM_FILTER_PARAMETER',
    payload: axios.patch(`${getApiRoot()}/filters/customs/parameters/${parameterId}${urlParams}`),
  };
}

export function addFilterCustomParameterValue(parameterId, value) {
  const urlParams = utils.createURL([
    { name: 'filter_custom_parameter_id', values: parameterId },
    { name: 'value', values: value },
  ]);

  return {
    type: 'ADD_CUSTOM_FILTER_VALUE',
    payload: axios.post(`${getApiRoot()}/filters/customs/parameters/values${urlParams}`),
  };
}

export function removeFilterCustomParameterValues(parameterId, valueId) {
  return {
    type: 'REMOVE_CUSTOM_FILTER_VALUE',
    payload: axios.delete(`${getApiRoot()}/filters/customs/parameters/values/${valueId}`),
    meta: { parameterId, valueId },
  };
}

export function setFilter(filter) {
  return {
    type: 'SET_FILTER',
    payload: filter,
  };
}

export function setFilterGo(filter) {
  return {
    type: 'SET_FILTER_GO',
    payload: filter,
  };
}
