import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

const CancelToken = axios.CancelToken;

let fetchCancelToken: any;

export function fetchExportOptions(exportSegment: string, brandIds?: number | number[]) {
  if (fetchCancelToken) fetchCancelToken();

  const params = utils.createURL([
    { name: 'brand_id', values: brandIds },
    { name: exportSegment, values: 1 },
  ]);

  return {
    type: 'FETCH_EXPORT_OPTIONS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/file_export_options${params}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
  };
}
