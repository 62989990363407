import { Checkbox } from 'antd';
import React from 'react';

import { sortAndUniqBy } from '../../utils/Array.ts';
import AntMultiSelect from './AntMultiSelect';
import AntSelect from './AntSelect.tsx';
import HelpCenter from '../../containers/HelpCenter';
import translationConstants from '../../constants/PriceSheetSelectTranslations.json';

export default class PriceSheetSelect extends React.Component {
  state = {
    selectedPriceSheetIds: this.props.defaultSelectedPriceSheetIds || [],
    selectedPriceTypeIds: this.props.defaultSelectedPriceTypeIds || [],
    withoutPrices: this.props.defaultWithoutPrices || false,
  };

  updateState = ({
    selectedPriceSheetIds = this.state.selectedPriceSheetIds,
    selectedPriceTypeIds = this.state.selectedPriceTypeIds,
    withoutPrices = this.state.withoutPrices,
  }) => {
    this.setState({
      selectedPriceSheetIds,
      selectedPriceTypeIds,
      withoutPrices,
    });
    this.props.updateSelection({ selectedPriceSheetIds, selectedPriceTypeIds, withoutPrices });
  };

  getPriceSheetTypes = key => {
    const { priceSheets } = this.props;
    return (priceSheets.find(({ id }) => id === key) || {}).parent_price_types || [];
  };

  selectPriceSheet = key => {
    const { multiSelectSheets } = this.props;
    const { selectedPriceSheetIds, selectedPriceTypeIds } = this.state;

    const sheets = multiSelectSheets ? [key, ...selectedPriceSheetIds] : [key];
    const types = multiSelectSheets
      ? selectedPriceTypeIds
      : this.getPriceSheetTypes(key)
          .map(priceType => priceType.type_id)
          .filter(id => selectedPriceTypeIds.includes(id));

    this.updateState({ selectedPriceSheetIds: sheets, selectedPriceTypeIds: types });
  };

  deselectPriceSheet = key => {
    const { selectedPriceSheetIds, selectedPriceTypeIds } = this.state;

    const newSelectedPriceSheetIds = selectedPriceSheetIds.filter(id => key !== id);
    const removedPriceTypes = this.getPriceSheetTypes(key).map(({ type_id: typeId }) => typeId);
    const newSelectedPriceTypeIds = selectedPriceTypeIds.filter(
      id => !removedPriceTypes.includes(id)
    );

    this.updateState({
      selectedPriceSheetIds: newSelectedPriceSheetIds,
      selectedPriceTypeIds: newSelectedPriceTypeIds,
    });
  };

  selectPriceType = key => {
    const { selectedPriceTypeIds } = this.state;
    const newSelectedPriceTypeIds = [key, ...selectedPriceTypeIds];
    this.updateState({
      selectedPriceTypeIds: newSelectedPriceTypeIds,
    });
  };

  deselectPriceType = key => {
    const { selectedPriceTypeIds } = this.state;
    const newSelectedPriceTypeIds = selectedPriceTypeIds.filter(typeId => key !== typeId);
    this.updateState({
      selectedPriceTypeIds: newSelectedPriceTypeIds,
    });
  };

  getAvailablePriceTypes = priceSheets => {
    if (this.props.unboundSheetAndType) {
      return sortAndUniqBy(
        priceSheets
          .map(({ parent_price_types: parentPriceTypes }) => parentPriceTypes)
          .reduce((a, b) => a.concat(b), [])
          .map(({ type_id: typeId }) => this.props.priceTypes.find(({ id }) => typeId === id)),
        'name'
      );
    }
    return sortAndUniqBy(
      priceSheets
        .filter(({ id }) => this.state.selectedPriceSheetIds.includes(id))
        .map(({ parent_price_types: parentPriceTypes }) => parentPriceTypes)
        .reduce((a, b) => a.concat(b), [])
        .map(({ type_id: typeId }) => this.props.priceTypes.find(({ id }) => typeId === id)),
      'name'
    );
  };

  showPriceSheetSelect = priceSheets => {
    if (this.props.multiSelectSheets) {
      return (
        <AntMultiSelect
          className="price-sheet__container__inner__select"
          placeholder={translationConstants.sheets_placeholder}
          elements={priceSheets.map(({ id, number }) => ({ id, name: number }))}
          selectedElements={this.state.selectedPriceSheetIds}
          onSelect={this.selectPriceSheet}
          onDeselect={this.deselectPriceSheet}
          disabled={this.props.disabled}
        />
      );
    }
    return (
      <AntSelect
        className="price-sheet__container__inner__select"
        placeholder={translationConstants.sheets_placeholder}
        elements={priceSheets.map(({ id, number }) => ({ id, name: number }))}
        value={
          this.state.selectedPriceSheetIds.length > 0 ? this.state.selectedPriceSheetIds[0] : null
        }
        onSelect={this.selectPriceSheet}
        onDeselect={this.deselectPriceSheet}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
      />
    );
  };

  showPriceTypeSelect = availablePriceTypes => {
    const { disabled } = this.props;
    if (!this.props.multiSelectTypes) {
      return (
        <AntSelect
          className="price-sheet__container__inner__select price-type-select"
          placeholder={translationConstants.types_placeholder}
          elements={availablePriceTypes.map(({ id, name }) => ({ id, name }))}
          value={this.state.selectedPriceTypeIds[0]}
          disabled={disabled || availablePriceTypes.length === 0}
          onSelect={this.selectPriceType}
          onDeselect={this.deselectPriceType}
        />
      );
    }

    return (
      <AntMultiSelect
        className="price-sheet__container__inner__select price-type-select"
        placeholder={translationConstants.types_placeholder}
        elements={availablePriceTypes.map(({ id, name }) => ({ id, name }))}
        selectedElements={this.state.selectedPriceTypeIds}
        disabled={disabled && availablePriceTypes.length === 0}
        onSelect={this.selectPriceType}
        onDeselect={this.deselectPriceType}
      />
    );
  };

  handleCheckboxChange = ({ target: { checked } }) => this.updateState({ withoutPrices: checked });

  render() {
    const { priceSheets, disabled } = this.props;
    const availablePriceTypes = this.getAvailablePriceTypes(
      priceSheets,
      this.state.selectedPriceSheetIds
    );
    return (
      <div className="price-sheet__container">
        {!this.props.hideWithoutPrices && (
          <React.Fragment>
            <span className="price-sheet__container__header__title">
              {this.props.checkboxLabel}
            </span>
            <Checkbox
              checked={this.state.withoutPrices}
              onChange={this.handleCheckboxChange}
              className="price-sheet__container__header__checkbox"
              disabled={disabled}
            />
          </React.Fragment>
        )}
        {!this.state.withoutPrices && (
          <React.Fragment>
            {this.showPriceSheetSelect(priceSheets)}
            {this.showPriceTypeSelect(availablePriceTypes)}
            <HelpCenter id="exclude_price_sheets" className="price-sheet__container__inner__help" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

PriceSheetSelect.defaultProps = {
  checkboxLabel: translationConstants.export_without_prices,
};
