import React from 'react';
import classNames from 'classnames';
import { Switch } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import constants from '../../../constants/SettingsTranslation.json';
import Button from '../../global/Button';

class SettingsScorecard extends React.Component {
  handleSelectSegment = id => {
    this.props.updateSelectedSegmentId(id);
  };

  handleSelectAlertType = id => {
    this.props.updateSelectedAlertTypeId(id);
  };

  handleSelectScorecard = id => {
    this.props.updateSelectedScorecardId(id);
  };

  handleAnalysisTypes = (checked, id) => {
    if (checked) {
      this.props.createAnalysisTypeForBrand(id);
    } else {
      this.props.deleteAnalysisTypeForBrand(id);
    }
  };

  handlelAllAnalysisTypes = prevCheckedState => {
    const analysisTypes = this.props.analysesResources.types;
    if (!prevCheckedState) {
      const analysisTypeIds = analysisTypes
        .filter(type => type.xml_format_validation !== 1)
        .filter(type => !type.isValidating)
        .map(type => type.id);
      this.props.createAnalysisTypeForBrand(analysisTypeIds);
    } else {
      const analysisTypeIds = analysisTypes
        .filter(type => type.xml_format_validation !== 1)
        .map(type => type.id);
      this.props.deleteAnalysisTypeForBrand(analysisTypeIds);
    }
  };

  getAllCheckedTypes = () =>
    this.props.analysesResources.types
      .filter(type => type.xml_format_validation !== 1)
      .map(type => type.isValidating)
      .every(type => type);

  segments = () => (
    <div className="settings-scorecard__table">
      {this.props.analysesResources.segments.map(segment => {
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected: segment.id === this.props.filterAnalysesResources.selectedSegmentId,
        });
        return (
          <span
            key={segment.id}
            className={rowCss}
            onClick={() => this.handleSelectSegment(segment.id)}
          >
            {segment.title}
          </span>
        );
      })}
    </div>
  );

  alertTypes = () => (
    <div className="settings-scorecard__table">
      {this.props.analysesResources.alert_types.map(alertType => {
        const selected = alertType.id === this.props.filterAnalysesResources.selectedAlertTypeId;
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected,
        });
        const iconCss = classNames('mr-2', {
          blue: alertType.id === 1,
          yellow: alertType.id === 2,
          red: alertType.id === 3,
          selected: alertType.id === 1 && selected,
        });
        return (
          <div
            key={alertType.id}
            className={rowCss}
            onClick={() => this.handleSelectAlertType(alertType.id)}
          >
            <WarningOutlined className={iconCss} />
            {alertType.name}
          </div>
        );
      })}
    </div>
  );

  scorecards = () => (
    <div className=" settings-scorecard__table">
      {this.props.analysesResources.scorecards.map(scorecard => {
        const selected = scorecard.id === this.props.filterAnalysesResources.selectedScorecardId;
        const rowCss = classNames('row', 'settings-scorecard__row', {
          selected,
        });
        const validatingInfoCss = classNames('settings-scorecard__validating-info', { selected });
        const iconCSS = classNames('ml-1', { selected });
        return (
          <div
            key={scorecard.id}
            className={rowCss}
            onClick={() => this.handleSelectScorecard(scorecard.id)}
          >
            {scorecard.name}
            {scorecard.validating_against_scorecard && (
              <span className={validatingInfoCss}>
                {constants.scorecards.validating}
                <CheckCircleOutlined className={iconCSS} />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );

  alertGroups = () => {
    const { analysesResources } = this.props;
    const typeIds = [...new Set(analysesResources.types.map(type => type.segment_id))];
    const filteredSegments = analysesResources.segments.filter(segment =>
      typeIds.includes(segment.id)
    );

    return (
      <div className=" settings-scorecard__table">
        {filteredSegments.map(segment => (
          <React.Fragment key={segment.id}>
            <div className="row settings-scorecard__row settings_scorecard__segment-row">
              {segment.title}
            </div>
            {this.analysisTypes(segment)}
          </React.Fragment>
        ))}
      </div>
    );
  };

  analysisTypes = segment => {
    const { canManageScorecard } = this.props;

    const segmentAlerts = this.props.analysesResources.types.filter(
      alert => alert.segment_id === segment.id
    );

    return segmentAlerts.map(analysis => {
      const iconCss = classNames('mr-2', {
        blue: analysis.alert_type_id === 1,
        yellow: analysis.alert_type_id === 2,
        red: analysis.alert_type_id === 3,
      });
      return (
        <span key={analysis.id} className="row settings-scorecard__row">
          <WarningOutlined className={iconCss} />
          {analysis.name}
          <span className="settings-scorecard__switch">
            <Switch
              disabled={!canManageScorecard}
              checked={!!analysis.isValidating}
              onClick={checked => this.handleAnalysisTypes(checked, analysis.id)}
            />
          </span>
        </span>
      );
    });
  };

  render() {
    const { canManageScorecard } = this.props;

    const allAnalysisChecked = this.getAllCheckedTypes();

    return (
      <div className="content settings-scorecard">
        <div className="settings-scorecard__info">
          <div className="settings-scorecard__title-container">
            <div className="setting-scorecard__title-left">
              <span className="title">{constants.scorecards.scorecards}</span>
            </div>
            <div className="setting-scorecard__title-right">
              <Button
                loading={this.props.updateAnalysesProgressIsRunning}
                className="settings-scorecard__update-button"
                name={constants.scorecards.update}
                color="green"
                hover
                onClick={this.props.updateAnalysesProgress}
              />
            </div>
          </div>
          <div>{constants.scorecards.info}</div>
        </div>
        <div className="settings-scorecard__selection">
          <div className="settings-scorecard_left">
            <div className="title">{constants.scorecards.segments}</div>
            {this.segments()}
          </div>
          <div className="settings-scorecard_middle">
            <div className="settings-scorecard__alert-types-container">
              <div className="title">{constants.scorecards.alert_types}</div>
              {this.alertTypes()}
            </div>
            <div className="settings-scorecard__scorecards-container">
              <div className="title">{constants.scorecards.scorecards}</div>
              {this.scorecards()}
            </div>
          </div>
          <div className="settings-scorecard_right">
            <div className="settings-scorecard__title-container flex">
              <div className="title settings-scorecard__title">{constants.scorecards.alerts}</div>
              <div className="settings-scorecard__select-all-alerts">
                <span className="select-all-alerts__title">
                  {constants.scorecards.validate_all}
                </span>
                <span className="settings-scorecard__switch">
                  <Switch
                    checked={allAnalysisChecked}
                    onClick={() => this.handlelAllAnalysisTypes(allAnalysisChecked)}
                    disabled={!canManageScorecard}
                  />
                </span>
              </div>
            </div>
            {this.alertGroups()}
          </div>
        </div>
      </div>
    );
  }
}

SettingsScorecard.defaultProps = {
  analysesResources: {
    alert_types: [],
    analysis_types: [],
    filter_analysis_reference: [],
    scorecards: [],
    segments: [],
  },
};

export default SettingsScorecard;
