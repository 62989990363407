import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

export function updateCustomFields(values) {
  // const params = utils.createURL([{ name: 'value', values: value }]);

  return {
    type: 'UPDATE_CUSTOM_FIELD',
    // meta: {
    //   customFieldsId,
    //   value,
    // },
    payload: axios.patch(`${getApiRoot()}/items/custom_fields/`, { custom_fields: values }),
  };
}
