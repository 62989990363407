import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { getSelectedBrandId } from '../../../utils/ReduxUtils';

const CancelToken = axios.CancelToken;

let fetchDefaultValuesCancelToken;

export function fetchDefaultValues(brandId) {
  if (fetchDefaultValuesCancelToken) fetchDefaultValuesCancelToken();

  return {
    type: 'FETCH_DEFAULT_VALUES',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/${brandId}/default_values`, {
      cancelToken: new CancelToken(c => {
        fetchDefaultValuesCancelToken = c;
      }),
    }),
  };
}

export function createDefaultPriceTypeDescription(priceSheetId, priceTypeId, description) {
  return (dispatch, getState) => {
    const brandId = getSelectedBrandId(getState());
    const params = utils.createURL([
      { name: 'brand_id', values: brandId },
      { name: 'price_sheet_id', values: priceSheetId },
      { name: 'price_type_id', values: priceTypeId },
      { name: 'type_description', values: description },
    ]);

    dispatch({
      type: 'CREATE_DEFAULT_PRICE_TYPE_DESCRIPTION',
      payload: axios.patch(
        `${getApiRoot()}/parents/owners/brands/price_types/descriptions${params}`
      ),
    });
  };
}

export function updateDefaultValue(defaultValueId, value, brandId) {
  value = typeof value === 'boolean' ? utils.booleanToStringInt(value) : value;
  const params = utils.createURL([
    { name: 'default_value_id', values: defaultValueId },
    { name: 'value', values: value },
    { name: 'brand_id', values: brandId },
  ]);

  return {
    type: 'UPDATE_DEFAULT_VALUE',
    meta: { defaultValueId, value },
    payload: axios.patch(`${getApiRoot()}/parents/owners/brands/default_values${params}`),
  };
}

export function updateDefaultPriceTypeDescription(defaultValueId, value, priceTypeId) {
  return (dispatch, getState) => {
    const brandId = getSelectedBrandId(getState());
    const params = utils.createURL([
      { name: 'brand_id', values: brandId },
      { name: 'default_value_id', values: defaultValueId },
      { name: 'value', values: value },
      { name: 'sub_value', values: priceTypeId },
    ]);

    dispatch({
      type: 'UPDATE_DEFAULT_VALUE',
      meta: { defaultValueId, value },
      payload: axios.patch(`${getApiRoot()}/parents/owners/brands/default_values${params}`),
    });
  };
}
