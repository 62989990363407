import axios from 'axios';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';
import { downloadFileFromUrl } from '../shared/fetch';

const CancelToken = axios.CancelToken;

let fetchLogCanceltoken: any;

export enum DistributionLogAction {
  FETCH_DELIVERY_FILE_URL = 'FETCH_DELIVERY_FILE_URL',
}

export function fetchDeliveryLogs({
  receiverId,
  parentId,
  keywords,
  page = 1,
  limit,
}: {
  receiverId?: number | null;
  parentId?: number | null;
  keywords?: string;
  page?: number;
  limit?: number;
}) {
  if (fetchLogCanceltoken) {
    fetchLogCanceltoken();
  }

  const params = utils.createURL([
    { name: 'receiver_id', values: receiverId },
    { name: 'parent_id', values: parentId },
    { name: 'keywords', values: keywords },
    { name: 'page', values: page },
    { name: 'limit', values: limit },
  ]);

  return {
    type: 'FETCH_DELIVERY_LOGS',
    payload: axios.get(`${getApiRoot()}/channels/delivery_logs${params}`, {
      cancelToken: new CancelToken(c => {
        fetchLogCanceltoken = c;
      }),
    }),
    meta: { page },
  };
}

export function fetchDeliveryErrorLogs(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'json' }]);

  return {
    type: 'FETCH_DELIVERY_ERROR_LOGS',
    payload: axios.get(`${getApiRoot()}/channels/delivery_logs/${logId}/errors${params}`),
  };
}

export function fetchDeliveryErrorLogsExcel(logId: number) {
  const params = utils.createURL([{ name: 'type', values: 'excel' }]);

  return {
    type: 'FETCH_DELIVERY_ERROR_LOGS_EXCEL',
    payload: axios({
      method: 'get',
      url: `${getApiRoot()}/channels/delivery_logs/${logId}/errors${params}`,
      responseType: 'arraybuffer',
    }),
  };
}

export function fetchDeliveryFileUrl(logId: number) {
  return {
    type: DistributionLogAction.FETCH_DELIVERY_FILE_URL,
    payload: axios
      .get(`${getApiRoot()}/channels/delivered_files/${logId}`)
      .then(({ data: { file_link: fileUrl } }) => downloadFileFromUrl(fileUrl)),
  };
}
