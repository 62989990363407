import axios from 'axios';
import { AsyncAction } from 'redux-promise-middleware';
import { ThunkAsyncDispatch, ThunkAsyncResult, ThunkAxiosResult } from '../../../types/global';
import { getApiRoot } from '../../constants/ParamountReactConstants';
import * as utils from '../../utils/Utils';

export const fetchReceiverRequestCount = (): AsyncAction => {
  return {
    type: 'FETCH_RECEIVER_REQUEST_COUNT',
    payload: axios.get(
      `${getApiRoot()}/parents/receivers/authorized_brands/pending_receiver_request_count`
    ),
  };
};

export const fetchBrandRequestCount = (): AsyncAction => {
  return {
    type: 'FETCH_BRAND_REQUEST_COUNT',
    payload: axios.get(`${getApiRoot()}/receivers/authorized_brands/pending_brand_request_count`),
  };
};

export const fetchFullReceiverList = (): AsyncAction => {
  return {
    type: 'FETCH_FULL_RECEIVER_LIST',
    payload: axios.get(`${getApiRoot()}/parents/receivers`),
  };
};

export const fetchReceiver = ({
  filterReceiverIds,
  statusIds,
  orderCondition,
  descending,
}: {
  filterReceiverIds?: number[];
  statusIds?: number[];
  orderCondition?: string;
  descending?: number;
}): AsyncAction => {
  const urlParams = utils.createURL([
    { name: 'filter_receiver_ids', values: filterReceiverIds },
    { name: 'status_ids', values: statusIds },
    { name: 'order_condition', values: orderCondition },
    { name: 'descending', values: descending },
  ]);
  return {
    type: 'FETCH_RECEIVER',
    payload: axios.get(`${getApiRoot()}/parents/receivers${urlParams}`),
  };
};

export const fetchReceiverDetails = (receiverId: number): AsyncAction => {
  const params = utils.createURL([{ name: 'receiver_id', values: receiverId }]);

  return {
    type: 'FETCH_RECEIVER_DETAILS',
    payload: axios.get(`${getApiRoot()}/accounts/receivers${params}`),
  };
};

export const fetchReceiverWarehouseList = (): AsyncAction => {
  // id 6 = warehouse_distributor
  const params = utils.createURL([{ name: 'type_id', values: 6 }]);

  return {
    type: 'FETCH_RECEIVER_WAREHOUSE_LIST',
    payload: axios.get(`${getApiRoot()}/accounts/receivers/list${params}`),
  };
};

export const downloadBulkReceiverExcel = (): ThunkAsyncResult => {
  return (dispatch: ThunkAsyncDispatch) => {
    const downloadReceiverExcel = (): ThunkAxiosResult =>
      ({
        type: 'DOWNLOAD_BULK_RECEIVER_EXCEL',
        payload: axios.get(`${getApiRoot()}/receivers/bulk_template`, {
          responseType: 'arraybuffer',
        }),
      } as any);
    return dispatch(downloadReceiverExcel()).then(({ value }: any) => {
      const fileName = `receiver_bulk_import_template.xlsx`;
      return utils.downloadArrayBuffer(value.data, fileName);
    });
  };
};
