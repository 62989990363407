import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { BrandCustomField } from '../../../types/custom_fields';

export type CustomFieldsBrandState = {
  customFields: BrandCustomField[];
};

const initalState: CustomFieldsBrandState = {
  customFields: [],
};

const reducer: Reducer<CustomFieldsBrandState, FluxStandardAction> = (
  state = initalState,
  action
) => {
  switch (action.type) {
    case 'FETCH_SETTINGS_CUSTOM_FIELDS_FULFILLED': {
      return {
        ...state,
        customFields: action.payload.data,
      };
    }
    case 'UPDATE_BRAND_CUSTOM_FIELD_FULFILLED': {
      const {
        customFieldId,
        name,
        customFieldTypeId,
        recordNumber,
        options,
        scriptId,
        ignoreOnSync,
      } = action.meta;
      const customFieldIds = state.customFields.map(({ id }) => id);
      const newCustomField = !customFieldIds.includes(customFieldId);
      return {
        ...state,
        customFields: newCustomField
          ? [
              ...state.customFields,
              {
                id: action.payload.data.custom_field_id,
                name,
                custom_field_type_id: customFieldTypeId,
                record_number: recordNumber,
                ignore_on_sync: ignoreOnSync,
                options,
                script_id: scriptId,
              },
            ]
          : state.customFields.map(customField => {
              if (customField.id === customFieldId) {
                return {
                  ...customField,
                  name,
                  custom_field_type_id: customFieldTypeId,
                  record_number: recordNumber,
                  ignore_on_sync: ignoreOnSync,
                  options,
                  script_id: scriptId,
                };
              }
              return customField;
            }),
      };
    }
    case 'DELETE_BRAND_CUSTOM_FIELD_FULFILLED': {
      return {
        ...state,
        customFields: state.customFields.filter(field => field.id !== action.meta.customFieldId),
      };
    }
    case 'UPDATE_BRAND_CUSTOM_FIELD_ORDER_FULFILLED': {
      return {
        ...state,
        customFields: action.meta.sortedCustomFields,
      };
    }
  }
  return state;
};

export default reducer;
