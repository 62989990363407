import { Empty, Spin, Input, Button } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps,
} from 'react-virtualized';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { Attribute, AttributeRankedValue } from '../../../../types/attributes';
import { AsyncDispatch } from '../../../../types/global';
import { fetchUnits, fetchValues } from '../../../actions/items/attributes/fetch';
import { ApplicationState } from '../../../reducers';
import { getPageLimit, typingDone } from '../../../utils/Utils';
import AttributeValuePopover from './AttributeValuePopover';

type AttributeValuesDrawerProps = {
  selectedAttributeId?: number | string;
  attributes: Attribute[];
  selectedRows: (number | string)[];
  selectedCells: { rowIndex: number; columnIndex: number }[];
  selectedItemCount: number;
  handleAddValue: (data: {
    attributeId: number | string;
    uomId: number | null;
    uomlabel: string;
    itemIds: number[];
    valueId: number | null;
    valueName: string;
  }) => void;
};
const cache: CellMeasurerCache = new CellMeasurerCache({ defaultHeight: 40, fixedWidth: true });

const AttributeValuesDrawer: React.FC<AttributeValuesDrawerProps> = ({
  selectedAttributeId,
  attributes,
  selectedRows,
  selectedCells,
  selectedItemCount,
  handleAddValue,
}) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<any>();

  const { selectedItemIds, fetchingValues, rValues } = useSelector((state: ApplicationState) => {
    return {
      selectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
      fetchingValues: state.items.attributes.fetchingValues,
      fetchingMoreValues: state.items.attributes.fetchingMoreValues,
      rValues: selectedAttributeId ? state.items.attributes.rankedValues : [],
    };
  });

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [addButtonVisible, setAddButtonVisible] = React.useState<boolean>(false);

  const pageSize = getPageLimit();

  const itemIds = useMemo(() => {
    if (selectedAttributeId && (selectedRows.length === 1 || selectedCells.length > 0)) {
      if (selectedRows.length > 0) {
        return selectedItemIds;
      }
      if (selectedCells.length > 0) {
        const selectedColumnIndices = [...new Set(selectedCells.map(c => c.columnIndex))].sort();
        return selectedColumnIndices.map(i => selectedItemIds[i - 1]);
      }
      return [];
    }
    return [];
  }, [selectedAttributeId, selectedCells, selectedItemIds, selectedRows.length]);

  const valueList = useMemo(() => {
    if (selectedAttributeId) {
      const attribute = attributes.find(av =>
        av.id ? av.id === selectedAttributeId : av.uniqueId === selectedAttributeId
      );
      const attributeValues = attribute?.ranked_values || [];
      const filteredValues = rValues.filter(
        v =>
          !attribute?.ranked_values.find(rv =>
            rv.id ? rv.id === v.id : rv.uniqueId === v.uniqueId
          )
      );
      return [...attributeValues, ...filteredValues];
    }
    return [];
  }, [attributes, rValues, selectedAttributeId]);

  React.useEffect(() => {
    if (selectedAttributeId) {
      const attributeIds = typeof selectedAttributeId === 'number' ? [selectedAttributeId] : [];

      // @ts-ignore
      dispatch(fetchValues({ itemIds, attributeIds, page: 1 }));
      dispatch(fetchUnits({ itemIds, attributeIds, page: 1 }));
    }
  }, [dispatch, itemIds, selectedAttributeId]);

  const handleFetchNext = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }) => {
    const nextPage = Math.ceil(stopIndex / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize);
    const lastPage = currentPage > startIndex / pageSize;
    if (!lastPage)
      return dispatch(
        // @ts-ignore
        fetchValues({
          itemIds,
          attributeIds: typeof selectedAttributeId === 'number' ? [selectedAttributeId] : [],
          page: nextPage,
        })
      );
    return Promise.resolve();
  };

  const handleDrawerValueSelection = (value: any) => {
    if (value) {
      const rowAttribute = attributes.find(
        attr => attr.id === selectedAttributeId || attr.uniqueId === selectedAttributeId
      );

      const existingValue = rowAttribute?.ranked_values.find(rv => rv.id === value.id);
      const valueIsSelected = existingValue?.item_ids.find(id => itemIds.includes(id));

      let rankedValues = rowAttribute!.ranked_values;

      if (existingValue && valueIsSelected) {
        rankedValues = rankedValues.map(rv =>
          (rv.id ? rv.id === value.id : rv.uniqueId === value.uniqueId)
            ? {
                ...value,
                item_ids: rv.item_ids.filter(id => !itemIds.includes(id)),
                part_attribute_meta_uoms: [],
              }
            : rv
        );
      } else if (existingValue && !valueIsSelected) {
        rankedValues = rankedValues.map(rv =>
          (rv.id ? rv.id === value.id : rv.uniqueId === value.uniqueId)
            ? { ...value, item_ids: [...rv.item_ids, ...itemIds] }
            : rv
        );
      } else if (!existingValue) {
        rankedValues = [...rankedValues, { ...value, item_ids: itemIds }];
      }

      const newAttribute = {
        ...rowAttribute!,
        ranked_values: rankedValues,
        temp: true,
      };

      const updatedAttributes = attributes.map((a: Attribute) =>
        a.id === newAttribute.id || a.uniqueId === newAttribute.uniqueId ? newAttribute : a
      );

      setFieldValue('attributes', updatedAttributes);
    }
  };

  const handleAddNewValue = () => {
    handleAddValue({
      attributeId: selectedAttributeId!,
      uomId: null,
      uomlabel: '',
      itemIds,
      valueId: null,
      valueName: searchValue,
    });
  };

  const handleSearch = (keyword: string) => {
    typingDone(() =>
      dispatch(
        // @ts-ignore
        fetchValues({
          itemIds,
          attributeIds: typeof selectedAttributeId === 'number' ? [selectedAttributeId] : [],
          filter: keyword,
        })
      ).then(response => {
        const searchValueIncluded = response.action.payload.data.find(
          (v: AttributeRankedValue) => v.name === keyword
        );
        setAddButtonVisible(!searchValueIncluded);
      })
    );
  };

  const handleValueChange = ({
    valueId,
    uomlabel,
    uomId,
    valueName,
    prevValueId,
  }: {
    uomlabel: string;
    valueId: number | null;
    prevValueId?: number;
    uomId: number | null;
    valueName: string;
  }) => {
    const attribute = attributes.find(
      attribute =>
        attribute.id === selectedAttributeId || attribute.uniqueId === selectedAttributeId
    );

    const updatedValues = attribute!.ranked_values.map(value => {
      if (value.id === valueId || value.id === prevValueId) {
        const uoms = value.part_attribute_meta_uoms.find(uom => uom.id === uomId)
          ? value.part_attribute_meta_uoms.map(uom => {
              const newItemIds =
                uom.id === uomId
                  ? [...new Set([...uom.item_ids, ...itemIds])]
                  : uom.item_ids.filter(id => !itemIds.includes(id));
              return { ...uom, item_ids: newItemIds };
            })
          : [
              ...value.part_attribute_meta_uoms.map(uom => ({
                ...uom,
                item_ids: uom.item_ids.filter(id => !itemIds.includes(id)),
              })),
              ...(uomId ? [{ id: uomId, label: uomlabel, item_ids: itemIds }] : []),
            ];
        return {
          ...value,
          id: valueId,
          name: valueName,
          item_ids: [...new Set([...value.item_ids, ...itemIds])],
          part_attribute_meta_uoms: uoms,
        };
      }
      return value;
    });

    let newValues = updatedValues;
    // add value to ranked_values list if necessary
    if (!updatedValues?.find(value => value.name === valueName)) {
      const value = rValues.find(v => v.id === valueId);

      newValues = [
        ...updatedValues,
        {
          ...value!,
          item_ids: itemIds,
          part_attribute_meta_uoms: [{ id: uomId, label: uomlabel, item_ids: itemIds }],
        },
      ];
    }

    const newAttribute = {
      ...attribute!,
      ranked_values: newValues || [],
      temp: true,
    };

    const updatedAttributes = attributes.map((a: Attribute) =>
      a.id === newAttribute.id || a.uniqueId === newAttribute.uniqueId ? newAttribute : a
    );

    setFieldValue('attributes', updatedAttributes);
  };

  const getUnitlabel = (value?: AttributeRankedValue) => {
    const uomLength = value?.part_attribute_meta_uoms && value?.part_attribute_meta_uoms.length;
    const uom = uomLength && uomLength === 1 ? value?.part_attribute_meta_uoms[0].label : '';
    return uom;
  };

  const noData = () => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  const renderSearch = () => (
    <div className="flex flex-row items-center w-full">
      <Input.Search
        placeholder={t('common:search')}
        allowClear
        value={searchValue || undefined}
        onChange={e => {
          const keyword = e.target.value;
          setSearchValue(keyword);
          handleSearch(keyword);
        }}
        size="small"
        data-testid="attribute-value-search"
      />
      {addButtonVisible && (
        <Button onClick={() => handleAddNewValue()} size="small">
          <PlusCircleOutlined />
        </Button>
      )}
    </div>
  );

  const renderEditButton = (value?: AttributeRankedValue) => {
    const uomTranslation =
      (value &&
        value.part_attribute_meta_uoms.length > 0 &&
        value.part_attribute_meta_uoms[0].translations) ||
      [];

    return (
      <AttributeValuePopover
        type="drawerEditValue"
        valueName={value?.name}
        valueId={value?.id || undefined}
        metaUomId={
          (value &&
            value.part_attribute_meta_uoms.length > 0 &&
            value.part_attribute_meta_uoms[0].id) ||
          null
        }
        handleChange={handleValueChange}
        translations={value?.translations}
        unitTranslations={uomTranslation}
      />
    );
  };

  const rowRenderer = ({ key, index, parent, style }: ListRowProps) => {
    const value = valueList.length > 0 ? valueList[index] : undefined;
    const usedValueCount = value?.item_ids.filter(id => itemIds.includes(id));
    const isSelected = value?.item_ids.find(id => itemIds.includes(id));

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div
          key={key}
          style={style}
          className={classNames('attributes-table___border attributes__drawer-value-cell', {
            mapped_value_blue: isSelected,
            mapped_value_green: isSelected && value?.rank === 1,
          })}
          onClick={e => {
            handleDrawerValueSelection(value);
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                style={{ width: '5px' }}
                className={classNames('mr-2 attributes-table__recommendation', {
                  green: value?.rank === 1 && value?.id,
                  'green-color': value?.rank === 1 && value.id,
                  'blue-color': value?.rank === 2 && value.id,
                })}
              />
              <div className="flex flex-row items-center">
                <div className="whitespace-normal">{value?.name}</div>
                <div className="px-2">{getUnitlabel(value)}</div>
                {renderEditButton(value)}
              </div>
            </div>
            <div className="count">{`${usedValueCount?.length || 0}/${selectedItemCount}`}</div>
          </div>
        </div>
      </CellMeasurer>
    );
  };

  return (
    <React.Fragment>
      {selectedAttributeId && (
        <div
          className={classNames('attributes-values-search', {
            'bottom-border': fetchingValues,
          })}
        >
          {renderSearch()}
        </div>
      )}

      {fetchingValues && (
        <div className="flex pt-8 justify-center h-full">
          <Spin />
        </div>
      )}

      <AutoSizer>
        {({ width, height }) => {
          return (
            <InfiniteLoader
              isRowLoaded={({ index }) => !!valueList[index]}
              loadMoreRows={handleFetchNext}
              rowCount={valueList.length}
            >
              {({ registerChild }) => (
                <List
                  className="attributes-values-list"
                  width={width - 22}
                  ref={registerChild}
                  height={selectedAttributeId ? height - 72 : height - 32}
                  rowCount={valueList.length}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  noRowsRenderer={noData}
                />
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

export default AttributeValuesDrawer;
