import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { showModal, hideModal } from '../../app/modal';
import { MODAL_TYPES } from '../../../constants/ModalConstants';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { Content } from '../../../../types/marketing_copy';
import { DigitalAsset } from '../../../../types/digital_asset';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import { showParentDigitalAssets } from '../digital_asset';

const LOGO_CODE = 'LGO';
function getLogoTypeId(digitalAssetTypes: any) {
  return digitalAssetTypes.find((type: any) => type.code === LOGO_CODE).id;
}

export function createMarketingCopy({
  codeId,
  referenceId,
  subCodeId,
  subReferenceId,
  languageId,
  contents,
  assets,
}: {
  codeId?: number | null;
  referenceId: number | undefined;
  subCodeId?: number | null | undefined;
  subReferenceId?: number | null | undefined;
  contents: Content[];
  assets: DigitalAsset[];
  languageId?: number;
}) {
  const urlParams = utils.createURL([
    { name: 'code_id', values: codeId },
    { name: 'reference_id', values: referenceId },
    { name: 'sub_code_id', values: subCodeId },
    { name: 'sub_reference_id', values: subReferenceId },
    { name: 'language_id', values: languageId },
  ]);
  return {
    type: 'CREATE_MARKETING_COPY',
    payload: axios.post(`${getApiRoot()}/parents/marketing_copies${urlParams}`, {
      assets,
      contents,
    }),
  };
}

export function createMarketingCopyDigitalAsset(
  file: File,
  fileName: string,
  overwrite: number,
  downloadUri: string,
  uri: string
) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  let body: { [key: string]: any };
  if (file) {
    body = file;
  } else if (downloadUri) {
    body = { download_uri: downloadUri };
  } else if (uri) {
    body = { uri };
  }

  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    const state = getState();
    const logoTypeId = getLogoTypeId(state.resources.data.digital_asset.types);
    const params = utils.createURL([
      { name: 'reference_type', values: 'marketing_copies' },
      { name: 'type_id', values: logoTypeId },
      { name: 'file_name', values: fileName },
      { name: 'override', values: overwrite },
    ]);
    dispatch({
      type: 'CREATE_MARKETING_COPY_DIGITAL_ASSET',
      payload: axios.post(`${getApiRoot()}/parents/digital_assets${params}`, body, config),
    })
      .then(() => dispatch(hideModal()))
      .catch(err => {
        const errData = err && err.response.data;
        if (errData.type === 'file_name') {
          dispatch(
            showModal(MODAL_TYPES.DIGITAL_ASSET_EXISTING, {
              error: errData,
            })
          );
        }
      });
  };
}

export function linkMarketingCopyDigitalAsset(
  marketingCopyId: number,
  assetId: number,
  representationTypeId: number,
  assetName: string
) {
  return (dispatch: AsyncDispatch, getState: () => ApplicationState) => {
    const state = getState();
    const logoTypeId = getLogoTypeId(state.resources.data.digital_asset.types);

    const params = utils.createURL([
      { name: 'marketing_copy_id', values: marketingCopyId },
      { name: 'digital_asset_id', values: assetId },
      { name: 'type_id', values: logoTypeId },
      { name: 'asset_name', values: assetName },
      { name: 'representation_id', values: representationTypeId },
    ]);
    dispatch({
      type: 'LINK_MARKETING_COPY_DIGITAL_ASSET',
      payload: axios.post(`${getApiRoot()}/parents/marketing_copies/digital_assets${params}`),
    }).then(() => {
      dispatch(hideModal());
      dispatch(showParentDigitalAssets(false));
    });
  };
}
