import axios from 'axios';
import { message } from 'antd';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import catalogueActions from '../catalogue/index.ts';

export function createItem(partNumber, brandId, subBrandId) {
  const params = utils.createURL([
    { name: 'part_number', values: partNumber },
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'parent_owner_brand_sub_brand_id', values: subBrandId },
  ]);

  return dispatch =>
    dispatch({
      type: 'CREATE_ITEM',
      payload: axios.post(`${getApiRoot()}/items${params}`),
    })
      .then(() => dispatch(catalogueActions.fetchItemsSummary(brandId)))
      .catch(error => {
        message.error(error.response.data.message);
        return Promise.reject();
      });
}

export function createItemError(message) {
  return {
    type: 'CREATE_ITEM_REJECTED',
    payload: { response: { data: message } },
  };
}

export function cloneItem(itemId, partNumber, brandId, subBrandId, segments) {
  const selectedSegments = {};
  Object.keys(segments).forEach(key => {
    selectedSegments[key] = utils.booleanToInt(segments[key]);
  });
  const params = {
    ...selectedSegments,
    item_id: itemId,
    part_number: partNumber,
    parent_owner_brand_id: brandId,
    parent_owner_brand_sub_brand_id: subBrandId,
  };

  return dispatch =>
    dispatch({
      type: 'CLONE_ITEM',
      payload: axios.post(`${getApiRoot()}/items/clone`, params),
    })
      .then(() => dispatch(catalogueActions.fetchItemsSummary(brandId)))
      .catch(error => {
        message.error(error.response.data.message);
        return Promise.reject();
      });
}
