import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../reducers';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';
import { mapItemsToCustomFields } from '../../selectors/custom_fields/customFieldsSelector';
import { fetchCustomFields } from '../../actions/items/custom_fields/fetch';
import ItemInfo from '../../components/global/ItemInfo';
import CustomFieldsItemTable from '../../components/body/custom_fields/CustomFieldsItemTable';
import { AsyncDispatch } from '../../../types/global';
import { updateCustomFields } from '../../actions/items/custom_fields/update';
import { intercomEvent } from '../../utils/IntercomUtils';
import { runScripts } from '../../actions/distribution_apisettings';

const CustomFieldsItemContainer: React.FC = () => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const selectedItemIds = useSelector(
    (state: ApplicationState) => state.catalogue.catalogue.selectedItemIds
  );
  const customFields = useSelector((state: ApplicationState) => mapItemsToCustomFields(state));
  const fetchingData = useSelector(
    (state: ApplicationState) => state.items.customFields.isFetching
  );
  const brandId = useSelector((state: ApplicationState) => state.parent.brands.selectedBrandId);
  const selectedItem = useSelector((state: ApplicationState) => getSelectedItems(state)[0]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'custom-fields-item',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItemIds.length) dispatch(fetchCustomFields(selectedItemIds));
  }, [dispatch, selectedItemIds]);

  const handleRunScript = async (scriptId: number) => {
    const runPromise = dispatch(runScripts({ scriptId, itemIds: selectedItemIds }));
    message.info(t('api:runningScript'));
    // timeout to show spinner in button
    await new Promise(r => {
      setTimeout(r, 500);
    });
    return runPromise;
  };

  if (!selectedItemIds.length || selectedItemIds.length > 1) {
    return <ItemInfo items={selectedItemIds} />;
  }

  const props = {
    fetchingData,
    customFields,
    brandId,
    updateCustomFields: (values: any) => {
      const itemValues = values.customFields.map((customField: any) => {
        return {
          id: customField.id,
          values: [
            {
              item_id: selectedItem?.id,
              value: customField.value,
            },
          ],
        };
      });
      return dispatch(updateCustomFields(itemValues));
    },
  };

  return <CustomFieldsItemTable {...props} runScript={handleRunScript} />;
};

export default CustomFieldsItemContainer;
